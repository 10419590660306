<div class="iu-specific-window iu-specific-window-container" style="min-height: 25vh;">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <a *ngIf="vcrOptional.length > 0" class="settings" (click)="isSidebarOpen = !isSidebarOpen"><i class="fas fa-cog"></i></a>
  <div class="ui-g-12">
    <label class="specific-title"
      ><p class="help">
        {{ headerTitle | translate }}
      </p></label
    >
    <div class="fields" style="padding-bottom: 240px;">
      <ng-template iuDynamicContainer></ng-template>
      <div [hidden]="true">
        <ng-template #vcrTmp></ng-template>
      </div>

      <div [hidden]="isSinglePartner">
        <ng-template #firstGrid></ng-template>
        <div class="inscrireButtonContainer">
          <button
            class="ui-button primary"
            [ngClass]="{
              disabledButton: registerEnabled === false
            }"
            (click)="registerEnabled && register()"
          >
            <i class="fa fa-check"></i>{{ 'specificWindow.inscribe.inscribe' | translate }}
          </button>
        </div>
        <ng-template #secondGrid></ng-template>
      </div>
      <div [hidden]="!isSinglePartner" class="iu-specific-btn-container">
        <ng-template #vcrButtons></ng-template>
      </div>
    </div>
  </div>
  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="6000" position="right" styleClass="ui-sidebar-md optionalFilterPanel">
    <h1 class="iu-notification-center-title">
      Optional filters
    </h1>
    <div class="optionalFields">
      <ng-template #optional></ng-template>
    </div>
  </p-sidebar>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="closeModalSearch()"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div class="specificModal" [ngClass]="{ hidden: !displayFormUI }">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
<div class="specificCustoModal" [ngClass]="{ hidden: !displayCustoModal }">
  <iu-modal-ui
    [angularStyle]="{
      'iu-modal': { overflow: 'inherit' },
      'iu-modal-background': { 'z-index': 1000 }
    }"
    class="modal-overflow-inherit"
    title="{{ 'specificWindow.inscribe.subtitle2' | translate }}"
    [isModalDisplay]="displayCustoModal"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayCustoModal', value: false })"
    [isLoading]="isLoading"
  >
    <label class="specific-title"
      ><p class="help">
        {{ 'specificWindow.inscribe.roleSelectionTitle' | translate }}
      </p></label
    >
    <div *ngFor="let fonction of fonctionLines.length + 1 | fakeArray; let i = index">
      <span class="line" [ngStyle]="{ display: i < fonctionLines.length + 1 ? ' ' : 'none' }">
        <span *ngIf="i < fonctionLines.length">{{ fonctionLines[i].nb }} {{ fonctionLines[i].name }} </span
        ><span class="fill"></span><ng-template #vcrRole></ng-template
      ></span>
      <!-- {{ fonctionLines[i].name | pluralize }} à utiliser quand tu a ajouté le pipe que j'ai ajouté dans le custo je ne sais pas comment l'ajouter dans le iupicsUtilsmodule -->
    </div>
    <div class="botButtonContainer">
      <a
        class="iu-btn btn-cancel"
        [ngClass]="{
          disabledButton: registerEnabled === false
        }"
        (click)="registerEnabled && updateModalDisplay({ key: 'displayCustoModal', value: false })"
      >
        <i class="fa fa-ban"></i>{{ 'generic.cancel' | translate }}
      </a>
      <ng-template #vcrButtonInscrire></ng-template>
    </div>
  </iu-modal-ui>
</div>
