import { Injectable } from '@angular/core';
import {
  CalloutStatus,
  CompiereDataFieldType,
  CompiereDataGridFilterType,
  CompiereDataGridResponseJSON,
  CompiereDataGridType,
  CompiereDataJSON2,
  DataStore,
  DataStoreKey,
  DataStoreName,
  DataStoreRequest,
  DataStoreSet,
  DataStoreState,
  DataStoreStatus
} from '@compiere-ws/models/compiere-data-json';
import { CalloutDataJSON, TreeCompiereJSON } from '@compiere-ws/models/compiere-tree-json';
import { InfoWindowDV } from '@compiere-ws/models/info-window-default-value-json';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DataConflict } from '@iupics-manager/models/data-conflict';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { IupicsColumnInfo } from '@iupics-manager/models/iupics_column_info';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { MessageManagerService } from '../message/message-manager.service';
import { SecurityManagerService } from '../security-manager/security-manager.service';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  private dataStore = {
    windowStore: {
      old: {},
      current: {},
      remote: {}
    },
    specificWindowStore: {
      old: {},
      current: {},
      remote: {}
    },
    processStore: {
      old: {},
      current: {},
      remote: {}
    },
    dataUUIDs: {}
  };
  private newDataStructure = {
    window: {},
    specificWindow: {},
    process: {}
  };

  // private windowCtx: { [windowId: string]: { [domWinId: string]: DataStoreKey[] } } = {};
  private calloutNb = 0;
  private calloutCallBack: any;
  private dateColumnCache: Map<string, boolean> = new Map();
  private iupics_column_info: Map<number, IupicsColumnInfo[]> = new Map();
  constructor(
    private dataService: CompiereDataService,
    private messageManager: MessageManagerService,
    private translateService: TranslateService,
    private poService: PoService,
    private connectorService: SecurityManagerService
  ) {}

  /**
   * Génération d'une clé unique pour la donnée stockée dans le DataStore
   * @param window_id ID de la fenêtre où se trouve la donnée
   * @param tab_id ID de l'onglet où se trouve la donnée
   * @param record_id ID de la donnée
   * @param filter whereClause afin de filtré les données qui sont liées à un parent
   */
  public generateDataStoreKey(window_id: number, tab_id: number, record_id?: string, parent_constraint?: string): DataStoreKey {
    let parentId = '';
    if (parent_constraint) {
      parentId = parent_constraint.replace(/=/g, ',');
    }
    record_id = record_id ? String(record_id) : '';
    return {
      windowId: window_id,
      tabId: tab_id,
      parentId: parentId,
      recordId: record_id
    };
  }

  /*
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************* FOLLOWING METHOD USE CACHE IN STORE **********************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   */

  public getDataGrid(request: DataStoreRequest, forceCallWs = false): Observable<CompiereDataGridResponseJSON> {
    let dataRequest = cloneDeep(request);
    const dataStorekey = this.generateDataStoreKey(
      dataRequest.windowId,
      dataRequest.compiereRequest.entityId,
      dataRequest.record_id,
      dataRequest.parent_constraint
    );

    dataRequest = this.prepareDataRequest(dataRequest);
    let datas;
    // FROM DataStore si pas d'action particulière (filtre,groupe,pivot,tri)
    if (dataRequest.compiereRequest.windowType === CompiereDataGridType.WINDOW) {
      datas = this.searchDataInStore(dataRequest, dataStorekey, true);
      if (forceCallWs) {
        datas = undefined;
      }
      if (datas) {
        return of(datas);
      }
    }
    // Si pas présent dans le DataStore, on appel le WS
    if (!datas) {
      // console.log('FROM WS (Grid format)');
      return this.dataService.getDataGrid(dataRequest.compiereRequest).pipe(
        map((dataResponse) => {
          // console.log('getDataGrid', dataResponse);
          if (dataResponse.data_UUID) {
            if (dataResponse.data_UUID.length > 0) {
              this.dataStore.dataUUIDs[dataRequest.compiereRequest.entityId] = dataResponse.data_UUID;
            } else if (dataResponse.data.length > 0) {
              const dataUUID = [];
              Object.keys(dataResponse.data[0])
                .filter((firstData) => firstData.includes('_ID'))
                .forEach((filteredKey) => {
                  dataUUID.push(filteredKey);
                });
              this.dataStore.dataUUIDs[dataRequest.compiereRequest.entityId] = dataUUID;
              dataResponse.data_UUID = dataUUID;
            }
          }
          if (dataResponse && dataResponse.data.length > 0) {
            this.replaceComplexType(dataResponse);
            if (
              dataRequest.compiereRequest.windowType === CompiereDataGridType.WINDOW &&
              (dataRequest.compiereRequest.rowGroupCols.length <= 0 ||
                dataRequest.compiereRequest.groupKeys.length === dataRequest.compiereRequest.rowGroupCols.length)
            ) {
              this.transformNewDataWSAndStore(dataStorekey, dataResponse, this.dataStore.windowStore.current);
              datas = this.findInStore(dataStorekey, this.dataStore.windowStore.current);
              this.copyToOldStore(datas, this.dataStore.windowStore);
            }
          }
          dataResponse.compiereRequest = dataRequest.compiereRequest;
          return dataResponse;
        })
      );
    }
  }

  public getWindowSingleData(
    request: DataStoreRequest,
    forceCallWs: boolean = false,
    fromZoom: boolean = false
  ): Observable<any> {
    let dataRequest = cloneDeep(request);
    const dataStorekey = this.generateDataStoreKey(
      dataRequest.windowId,
      dataRequest.compiereRequest.entityId,
      dataRequest.record_id,
      dataRequest.parent_constraint
    );
    dataRequest = this.prepareDataRequest(dataRequest);
    // FROM DataStore si pas d'action particulière (filtre,groupe,pivot,tri)
    let datas = this.searchDataInStore(dataRequest, dataStorekey);
    if (forceCallWs) {
      datas = undefined;
    }
    // const hasAllKeys = datas ? this.hasAllKeys(!(datas instanceof DataStore) ? datas[Object.keys(datas)[0]] : datas) : true;
    if (datas) {
      datas.initContextField.emit();
      datas.currentContext = undefined;
      if (!(datas instanceof DataStore)) {
        return of(datas[Object.keys(datas)[0]]);
      } else {
        return of(datas);
      }
    }
    // Si pas présent dans le DataStore, on appel le WS
    if (!datas) {
      // console.log('FROM WS (Single format)');
      return this.dataService.getDataGrid(dataRequest.compiereRequest).pipe(
        map((dataResponse) => {
          if (fromZoom) {
            dataResponse.data_UUID = dataResponse.data_UUID.map((data_uuid) => {
              return data_uuid === 'C_DocBaseType_ID' ? 'DocBaseType' : data_uuid;
            });
          }
          this.dataStore.dataUUIDs[dataRequest.compiereRequest.entityId] = dataResponse.data_UUID;
          if (dataResponse && dataResponse.data.length > 0) {
            this.replaceComplexType(dataResponse);
            this.transformNewDataWSAndStore(dataStorekey, dataResponse, this.dataStore.windowStore.current);
            datas = this.findInStore(dataStorekey, this.dataStore.windowStore.current);
            this.copyToOldStore(datas, this.dataStore.windowStore);
            if (datas instanceof DataStoreSet) {
              datas = datas.data[datas.orderKeys[0]];
            }
            datas.initContextField.emit();
            datas.currentContext = undefined;
            return datas;
          }
        })
      );
    }
  }

  public setStateVisibleOnWindowData(dataStorekey: DataStoreKey): void {
    const datas = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
    datas.state = DataStoreState.VISIBLE;
  }

  public setStateHiddenOnWindowData(dataStorekey: DataStoreKey): void {
    const datas = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
    datas.state = DataStoreState.HIDDEN;
    this.removeFromStore(dataStorekey, this.dataStore.windowStore.old);
  }

  public syncSingleDataWithRemote(
    dataStorekey: DataStoreKey,
    checkConflict = false,
    component?: any,
    userContext?: any,
    callback?: Function,
    callbackParam?: any
  ): Observable<any> {
    let dataRequest = this.initDataRequest(dataStorekey, component, userContext);
    dataRequest = this.prepareDataRequest(dataRequest);
    return this.dataService.getDataGrid(dataRequest.compiereRequest).pipe(
      switchMap((dataResponse) => {
        if (dataResponse) {
          if (dataResponse.data.length > 0) {
            return of(dataResponse);
          }
          return throwError({ message: 'No Data Found!' });
        } else {
          return throwError({ message: 'No response from server' });
        }
      }),
      map((dataResponse) => {
        if (dataResponse) {
          return this.handleSyncResponse(dataResponse, dataStorekey, checkConflict, component, callback, callbackParam);
        }
      })
    );
  }

  public syncWithRemoteWindowData(
    dataStorekey: DataStoreKey,
    checkConflict = false,
    component?: any,
    userContext?: any,
    callback?: Function,
    callbackParam?: any
  ): Observable<any> {
    let dataRequest = this.initDataRequest(dataStorekey, component, userContext);
    dataRequest = this.prepareDataRequest(dataRequest);
    return this.dataService.getDataGrid(dataRequest.compiereRequest).pipe(
      map((dataResponse) => {
        return this.handleSyncResponse(dataResponse, dataStorekey, checkConflict, component, callback, callbackParam);
      })
    );
  }

  public syncDataChanges(
    dataStored: DataStore,
    newData: any,
    isForced = false,
    bypassValidation = false,
    calloutStack: string[] = [],
    shouldSimulateKeys = false
  ) {
    const dataModified = {};
    let keys = null;
    if (!shouldSimulateKeys && dataStored && dataStored.key && dataStored.key.tabId) {
      keys = Object.keys(this.newDataStructure.window[dataStored.key.tabId]);
    } else {
      keys = Object.keys(newData);
    }
    Object.keys(newData)
      .filter((key) => !keys || keys.includes(key))
      .forEach((columnName) => {
        if (isForced) {
          dataModified[columnName] = newData[columnName];
          dataStored.data[columnName] = newData[columnName];
        } else {
          if (typeof newData[columnName] === 'boolean') {
            newData[columnName] = newData[columnName] ? 'Y' : 'N';
          }
          if (dataStored.data[columnName] && dataStored.data[columnName].hasOwnProperty('id')) {
            if (newData[columnName] && newData[columnName].hasOwnProperty('id')) {
              if (JSON.stringify(dataStored.data[columnName]) !== JSON.stringify(newData[columnName])) {
                dataModified[columnName] = newData[columnName];
                dataStored.data[columnName] = newData[columnName];
              }
            } else {
              if (dataStored.data[columnName].id != newData[columnName]) {
                dataModified[columnName] = newData[columnName];
                dataStored.data[columnName] = newData[columnName];
              }
            }
          } else {
            if (dataStored.data[columnName] != newData[columnName]) {
              dataModified[columnName] = newData[columnName];
              dataStored.data[columnName] = newData[columnName];
            }
          }
        }
        // mise à jour du context réutilisable
        if (dataStored && dataStored.currentContext) {
          dataStored.currentContext[columnName] = newData[columnName]
            ? newData[columnName].id
              ? newData[columnName].id
              : newData[columnName]
            : newData[columnName];
        }
      });
    /*On vérifie si le store a changé */
    this.updateStoreStatus(dataStored);
    // if (dataStored.status === DataStoreStatus.SYNC) {
    //   dataStored.status = DataStoreStatus.NOTSYNC;
    // }
    if (dataStored.dataChange) {
      dataStored.dataChange.emit({
        dataModified: dataModified,
        bypassValidation: bypassValidation,
        calloutStack: calloutStack
      });
    }
  }
  public updateStoreWithoutFields(dataStorekey: DataStoreKey, dataStored: DataStore, newData: any) {
    const dataStoredTmp = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
    this.syncDataChanges(dataStoredTmp, newData, true);
  }
  public updateStoreStatus(currentStore: DataStore) {
    if (currentStore.status !== DataStoreStatus.NEWRECORD) {
      const oldStore = this.findInStore(currentStore.key, this.dataStore.windowStore.old);
      let hasChanges = false;
      let keys = null;
      if (currentStore && currentStore.key && currentStore.key.tabId) {
        keys = Object.keys(this.newDataStructure.window[currentStore.key.tabId]);
      } else {
        keys = Object.keys(currentStore.data);
      }
      keys.forEach((columnName) => {
        if (
          currentStore.data[columnName] != oldStore.data[columnName] &&
          (!(currentStore.data[columnName] instanceof Object) ||
            !(oldStore.data[columnName] instanceof Object) ||
            currentStore.data[columnName].id != oldStore.data[columnName].id)
        ) {
          // prise en compte du cas de l'ad_org 0
          if (
            columnName === 'AD_Org_ID' ||
            oldStore.data[columnName] !== 0 ||
            (currentStore.data[columnName] && currentStore.data[columnName] !== '')
          ) {
            hasChanges = true;
            return;
          }
        }
      });
      if (hasChanges) {
        currentStore.status = DataStoreStatus.NOTSYNC;
      } else {
        currentStore.status = DataStoreStatus.SYNC;
      }
    }
  }

  /**
   * Renvoie null si il y a des erreurs
   * @param { DataStoreKey[] } dataStorekeys accepte une ou plusieurs DataStoreKey(s). Si un tableau est passé dans la méthode, celui-ci doit être spreadé => exemple: saveWindowData(...array);
   */
  public saveWindowData(dataStorekeys: DataStoreKey[], windowCtx?: DataStore): Observable<any> {
    const dataStoreds = new Map<DataStoreKey, DataStore>();
    const dataUUID = this.dataStore.dataUUIDs[dataStorekeys[0].tabId];
    const tabId = dataStorekeys[0].tabId;
    const dataWs: CompiereDataJSON2 = {
      data: [],
      data_UUID: dataUUID,
      displayData: {},
      secondaryColumnFields: [],
      lastRow: 0,
      tab_id: tabId
    };
    dataStorekeys.forEach((dataStorekey) => {
      const dataStored = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
      if (dataStored) {
        let dataReformat = Object.assign({}, dataStored.data);
        Object.keys(dataReformat).forEach((key) => {
          if (dataReformat[key] instanceof Object) {
            dataReformat[key] = dataReformat[key].id;
          } else if (typeof dataReformat[key] === 'string' && String(dataReformat[key]).startsWith('~@')) {
            dataReformat[key] = String(dataReformat[key]).replace(/~/g, '');
            dataReformat[key] = LogicEvaluator.replaceVariables(
              dataReformat[key],
              this.connectorService.getIupicsUserContext(),
              windowCtx
            );
          }
        });
        dataReformat['newRecord'] = dataStored.status === DataStoreStatus.NEWRECORD ? 'Y' : 'N';
        if (windowCtx) {
          dataReformat = Object.assign(windowCtx, dataReformat);
        }
        dataWs.data.push(dataReformat);
        dataStoreds.set(dataStorekey, dataStored);
      }
    });
    return this.dataService.saveData(dataWs).pipe(
      map((datasWS) => {
        const errorMessages: string[] = [];
        const warningMessages: string[] = [];
        const successMessages: string[] = [];
        if (datasWS && datasWS.data.length > 0) {
          datasWS.data.forEach((data) => {
            if (data['apiz_dataResult']) {
              if (data['apiz_dataResult']['responseError']) {
                data['apiz_dataResult']['responseError'].forEach((error) => {
                  errorMessages.push(error.message);
                });
              }
              if (data['apiz_dataResult']['responseWarning']) {
                data['apiz_dataResult']['responseWarning'].forEach((warning) => {
                  warningMessages.push(warning.message);
                });
              }
              if (data['apiz_dataResult']['responseSuccess']) {
                data['apiz_dataResult']['responseSuccess'].forEach((success) => {
                  const index = successMessages.findIndex(
                    (s: string) => s.trim().toLowerCase().indexOf(success.message.trim().toLowerCase()) >= 0
                  );
                  if (index >= 0) {
                    const opt_nb = successMessages[index].replace(new RegExp(success.message, 'g'), '').trim();
                    if (isNaN(parseInt(opt_nb, 10))) {
                      successMessages[index] = `2 ${success.message}`;
                    } else {
                      const nb = parseInt(opt_nb, 10) + 1;
                      successMessages[index] = `${nb} ${success.message}`;
                    }
                  } else {
                    successMessages.push(success.message);
                  }
                });
              }
            }
          });
          this.replaceComplexType(datasWS);
        }
        let i = 0;
        dataStorekeys.forEach((dataStorekey) => {
          const linkedDatastored = dataStoreds.get(dataStorekey);
          const oldDatastoredKey = Object.assign({}, dataStorekey);
          if (datasWS && datasWS.data.length > 0) {
            const data = datasWS.data[i++];
            if (!data['apiz_dataResult'] || !data['apiz_dataResult']['responseError']) {
              this.copyToOldStore(linkedDatastored, this.dataStore.windowStore);
              dataStorekey = this.transformOneNewDataWsAndStore(dataStorekey, data, this.dataStore.windowStore.remote, datasWS);
              const dataRemote = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.remote);
              linkedDatastored.data = { ...dataRemote.data };
              this.syncDataChanges(linkedDatastored, dataRemote.data);
              this.removeFromStore(oldDatastoredKey, this.dataStore.windowStore.current);
              this.store(dataStorekey, linkedDatastored, this.dataStore.windowStore.current);
              this.store(dataStorekey, dataRemote, this.dataStore.windowStore.old);
              this.removeFromStore(dataStorekey, this.dataStore.windowStore.remote);
              this.store(dataStorekey, dataRemote, this.dataStore.windowStore.remote);
              linkedDatastored.status = DataStoreStatus.SYNC;
            }
          }
        });
        if (errorMessages.length > 0) {
          this.messageManager.newMessage(
            new IupicsMessage(this.translateService.instant('editView.saveMessageTitle'), errorMessages.join('\n'), 'error')
          );
          return null;
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('editView.saveMessageTitle'),
              warningMessages.length > 0 && successMessages.length > 0
                ? [...warningMessages, ...successMessages].join('\n')
                : warningMessages.length > 0 && successMessages.length === 0
                ? warningMessages.join('\n')
                : warningMessages.length === 0 && successMessages.length > 0
                ? successMessages.join('\n')
                : this.translateService.instant('editView.saveMessage'),
              warningMessages.length > 0 ? 'warning' : 'success'
            )
          );
          return dataStoreds;
        }
      })
    );
  }

  public saveWindowSpecificData(tableName: string, datastore: DataStore, id?: number) {
    const dataReformat = Object.assign({}, datastore.data);
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    this.copyToOldStore(datastore, this.dataStore.specificWindowStore);
    return this.poService
      .save(tableName, dataReformat, id)
      .pipe(tap((result) => this.syncDataChanges(datastore, result, false, true)));
  }

  public deleteWindowSpecificData(tableName: string, datastore: DataStore, id?: number): Observable<any> {
    return this.poService.delete(tableName, id).pipe(
      map((responses) => {
        const errors = {};
        let success = 0;
        if (responses) {
          responses.forEach((response) => {
            if (response.messages[0].type === 'ERROR') {
              if (response.messages[0] !== undefined) {
                if (!errors[response.messages[0].message]) {
                  errors[response.messages[0].message] = 0;
                }
                errors[response.messages[0].message]++;
              }
            } else {
              success++;
              this.removeFromStore(datastore.key, this.dataStore.windowStore.old);
              this.removeFromStore(datastore.key, this.dataStore.windowStore.remote);
              this.removeFromStore(datastore.key, this.dataStore.windowStore.current);
            }
          });
          return { errors, success };
        } else {
          return of({});
        }
      })
    );
  }

  // TODO A utiliser quand ils sera correctement implementé coté back https://gitlab.audaxis.com/iupics/iupics/issues/238 idem que delete
  // public saveWindowMultiData(dataStorekeys: DataStoreKey[]): Observable<any> {
  //   const dataStoredFirst = <DataStore>this.findInStore(dataStorekeys[0], this.dataStore.windowStore.current);
  //   if (dataStoredFirst) {
  //     // set des columnNames dans Fields et du tabId
  //     let columnsName = ['Data_UUID'];
  //     columnsName = [
  //       ...columnsName,
  //       ...Object.keys(dataStoredFirst.data).filter(
  //         key => key !== 'Created' && key !== 'CreatedBy' && key !== 'Updated' && key !== 'UpdatedBy' && key !== 'Data_UUID'
  //       )
  //     ];
  //     const localData: CompiereDataJSON = {
  //       data: [],
  //       fields: columnsName,
  //       tabId: dataStorekeys[0].tabId
  //     };

  //     dataStorekeys.forEach((dataStorekey, index) => {
  //       // const oldDatastoredKey = Object.assign({}, dataStorekey);
  //       const dataStored = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
  //       const isNewRecord = dataStored.status === DataStoreStatus.NEWRECORD;

  //       const dataTmp = [];
  //       columnsName.forEach(key => {
  //         dataTmp.push(dataStored.data[key]);
  //       });
  //       if (isNewRecord) {
  //         dataTmp[0] = null;
  //       }
  //       localData.data.push(dataTmp);
  //       this.copyToOldStore(dataStored, this.dataStore.windowStore);
  //     });
  //     if (localData.data.length > 0) {
  //       return this.dataService.saveData(localData).pipe(
  //         map(datasWS => {
  //           // datasWS.fields = datasWS.fields.slice(0, datasWS.data[0].length);
  //           // data from ws
  //           // this.transformDataWSAndStore(dataStorekey, datasWS, this.dataStore.windowStore.remote);
  //           // if (datasWS && datasWS.data && datasWS.data[0].length > 0) {
  //           //   dataStorekey.recordId = this.getRecordIdString(datasWS.data[0][0]);
  //           // }
  //           // const dataRemote = this.findInStore(dataStorekey, this.dataStore.windowStore.remote);
  //           // this.syncDataChanges(dataStored, dataRemote.data);
  //           // if (dataStored.status === DataStoreStatus.NEWRECORD) {
  //           //   this.removeFromStore(oldDatastoredKey, this.dataStore.windowStore.current);
  //           //   this.store(dataStorekey, dataStored, this.dataStore.windowStore.current);
  //           //   this.store(dataStorekey, dataStored, this.dataStore.windowStore.old);
  //           //   this.removeFromStore(dataStorekey, this.dataStore.windowStore.remote);
  //           //   this.store(dataStorekey, dataStored, this.dataStore.windowStore.remote);
  //           // }
  //           // dataStored.status = DataStoreStatus.SYNC;
  //           // return dataStored;
  //           return true;
  //         })
  //       );
  //     } else {
  //       return of(false);
  //     }
  //   }
  // }

  public deleteWindowData(dataStorekey: DataStoreKey, recordIds: any[]): Observable<any> {
    return this.dataService.deleteData(dataStorekey.tabId, recordIds).pipe(
      map((responses) => {
        const errors = {};
        let success = 0;
        if (responses) {
          responses.forEach((response) => {
            if (response.messages[0].type === 'ERROR') {
              if (response.messages[0] !== undefined) {
                if (!errors[response.messages[0].message]) {
                  errors[response.messages[0].message] = 0;
                }
                errors[response.messages[0].message]++;
              }
            } else {
              success++;
              const dataUUIDs = this.getDataUUIDFromTabID(dataStorekey.tabId);
              let recordID = '';
              dataUUIDs.forEach((dataUUID) => {
                recordID += dataUUID + ',' + response[dataUUID];
              });
              const currentDataStoreKey = Object.assign(dataStorekey);
              currentDataStoreKey.recordId = recordID;
              this.removeFromStore(currentDataStoreKey, this.dataStore.windowStore.old);
              this.removeFromStore(currentDataStoreKey, this.dataStore.windowStore.remote);
              this.removeFromStore(currentDataStoreKey, this.dataStore.windowStore.current);
            }
          });
          return { errors, success };
        } else {
          return of({});
        }
      })
    );
  }

  public deleteDataFromStoreOnly(dataStorekey: DataStoreKey) {
    this.removeFromStore(dataStorekey, this.dataStore.windowStore.current);
  }

  public isWindowNewData(dataStorekey: DataStoreKey): boolean {
    const result = this.findInStore(dataStorekey, this.dataStore.windowStore.current);
    if (result) {
      return (<DataStore>result).status === DataStoreStatus.NEWRECORD;
    } else {
      return false;
    }
  }

  public isWindowDataSYNC(dataStorekey: DataStoreKey): boolean {
    return (<DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current)).status === DataStoreStatus.SYNC;
  }

  public addWindowDataStructure(tab_id: number, obj: {}) {
    this.newDataStructure.window[tab_id] = obj;
  }

  public newWindowData(window_id: number, tab_id: number, filter?: string, parentDatastoreKey?: DataStoreKey): DataStore {
    const dataStorekey = this.generateDataStoreKey(window_id, tab_id, uuid(), filter);
    if (parentDatastoreKey) {
      dataStorekey.parentId = parentDatastoreKey.recordId;
    }
    const dataTransformed: DataStore = new DataStore();
    Object.assign(dataTransformed.data, this.newDataStructure.window[tab_id]);
    dataTransformed.data['Data_UUID'] = dataStorekey.recordId;

    dataTransformed.status = DataStoreStatus.NEWRECORD;
    this.store(dataStorekey, dataTransformed, this.dataStore.windowStore.current);
    return dataTransformed;
  }

  public cleanDataStore() {
    this.dataStore = {
      windowStore: {
        current: {},
        remote: {},
        old: {}
      },
      specificWindowStore: {
        old: {},
        current: {},
        remote: {}
      },
      processStore: {
        old: {},
        current: {},
        remote: {}
      },
      dataUUIDs: {}
    };
  }

  public removeWindowData(dataStoreKey: DataStoreKey) {
    this.removeFromStore(dataStoreKey, this.dataStore.windowStore.current);
    this.removeFromStore(dataStoreKey, this.dataStore.windowStore.old);
    this.removeFromStore(dataStoreKey, this.dataStore.windowStore.remote);
  }

  public copyWindowDataToOldStore(dataStored: DataStore) {
    this.copyToOldStore(dataStored, this.dataStore.windowStore);
  }

  public copyRemoteWindowDataToOldStore(dataStoreKey: DataStoreKey, columnName: string) {
    const dataStoreRemote = <DataStore>this.findInStore(dataStoreKey, this.dataStore.windowStore.remote);
    const dataStoreOld = <DataStore>this.findInStore(dataStoreKey, this.dataStore.windowStore.old);
    if (dataStoreRemote.data[columnName] instanceof Object) {
      dataStoreOld.data[columnName] = Object.assign({}, dataStoreRemote.data[columnName]);
    } else {
      dataStoreOld.data[columnName] = dataStoreRemote.data[columnName];
    }
  }

  public addProcessDataStructure(process_id: number, obj: {}) {
    this.newDataStructure.process[process_id] = obj;
  }

  public newProcessData(process_id: number, parentDatastore?: DataStore): DataStore {
    const dataStorekey = this.generateDataStoreKey(process_id, 0, uuid());
    const dataTransformed: DataStore = new DataStore();
    Object.assign(dataTransformed.data, this.newDataStructure.process[process_id]);
    dataTransformed.data['Data_UUID'] = dataStorekey.recordId;
    dataTransformed.status = DataStoreStatus.NEWRECORD;
    if (parentDatastore) {
      // context du parent
      Object.assign(dataTransformed.data, parentDatastore.data);
    }
    this.store(dataStorekey, dataTransformed, this.dataStore.processStore.current);
    return dataTransformed;
  }

  public addSpecificWindowDataStructure(form_id: number, obj: {}) {
    this.newDataStructure.specificWindow[form_id] = obj;
  }

  public newSpecificWindowData(form_id: number, parentDatastore?: DataStore): DataStore {
    const dataStorekey = this.generateDataStoreKey(form_id, 0, uuid());
    const dataTransformed: DataStore = new DataStore();
    dataTransformed.data = cloneDeep(this.newDataStructure.specificWindow[form_id]) || {};
    dataTransformed.data['Data_UUID'] = dataStorekey.recordId;
    dataTransformed.status = DataStoreStatus.NEWRECORD;
    if (parentDatastore) {
      // context du parent
      Object.assign(dataTransformed.data, parentDatastore.data);
    }
    this.store(dataStorekey, dataTransformed, this.dataStore.specificWindowStore.current);
    return dataTransformed;
  }

  public saveDateColumn(columnName: string, refID: number) {
    this.dateColumnCache.set(columnName, refID === 16);
  }

  /*
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************* FOLLOWING METHOD NOT USE CACHE IN STORE ******************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   */
  public getDataTree(table_id: number): Observable<TreeCompiereJSON> {
    return this.dataService.getDataTree(table_id);
  }

  public calloutData(
    url: string,
    data: CalloutDataJSON,
    dataStore: DataStore,
    dataContainer: AbstractDataContainer,
    callBack?: any
  ) {
    data.oldValue = null; // always trigger callout
    this.calloutNb++;
    if (dataContainer) {
      Object.assign(data.windowCtx, dataContainer.getCurrentContext());
    }
    if (!data) {
      console.log('error using callout without data');
    }
    let shouldCall = true;
    const columnName = data ? data.columnName : null;
    const calloutStack = data ? (data.calloutStack ? data.calloutStack : []) : [];
    delete data.columnName;
    delete data.calloutStack;
    if (
      calloutStack.includes(columnName) ||
      (dataStore.calloutStates.get(columnName) && dataStore.calloutStates.get(columnName) === CalloutStatus.LOADING)
    ) {
      if (dataStore.calloutStates.get(columnName) && dataStore.calloutStates.get(columnName) === CalloutStatus.WAITING) {
        dataStore.startCallout(columnName);
      } else {
        shouldCall = false;
      }
    } else {
      dataStore.startCallout(columnName);
      calloutStack.push(columnName);
    }
    if (shouldCall) {
      const sub$ = this.dataService.calloutData(url, data).subscribe((newData) => {
        newData['dataChanged'] = Object.assign(newData['dataChanged'], newData['ctxChanged']);
        this.syncDataChanges(dataStore, newData['dataChanged'], false, true, calloutStack, true);
        if (dataContainer && dataContainer.editViewParent) {
          dataContainer.editViewParent.updateEditTabsVisibility(dataStore);
        }
        dataStore.endCallout(columnName);
        this.calloutNb--;
        if (callBack) {
          this.calloutCallBack = callBack;
        }
        if (this.calloutCallBack && this.calloutNb === 0) {
          this.calloutCallBack();
          this.calloutCallBack = undefined;
        }
        if (newData && newData['dataChanged'].hasOwnProperty('apiz_dataResult')) {
          const responseError = [];
          const responseWarning = [];
          if (newData['dataChanged']['apiz_dataResult']['responseError']) {
            responseError.push(...newData['dataChanged']['apiz_dataResult']['responseError'].map((e) => e.message));
          }
          if (newData['dataChanged']['apiz_dataResult']['responseWarning']) {
            responseWarning.push(...newData['dataChanged']['apiz_dataResult']['responseWarning'].map((e) => e.message));
          }

          const type = responseError.length > 0 ? 'error' : responseWarning.length > 0 ? 'warning' : 'message';
          const message = [...responseError, ...responseWarning].join('\n');
          const title = this.translateService.instant('generic.warning');
          if (type !== 'message') {
            this.messageManager.newMessage(new IupicsMessage(title, message, type));
          }
        }
        sub$.unsubscribe();
      });
    }
  }

  public saveDataTree(data: TreeCompiereJSON): Observable<TreeCompiereJSON> {
    return this.dataService.saveDataTree(data);
  }

  public getAutocompleteData(
    fieldType: CompiereDataFieldType,
    entityId: number,
    isSearch: boolean = false,
    query?: string,
    validation?: string
  ): Observable<any> {
    return this.dataService.getDataForAutocomplete(fieldType, entityId, isSearch, query, validation);
  }

  public getAutocompleteDataById(
    fieldType: CompiereDataFieldType,
    entityId: number,
    id: any,
    validation?: string
  ): Observable<any> {
    return this.dataService.getDataForAutocompleteById(fieldType, entityId, id, validation);
  }

  public saveLocation(dataWS: CompiereDataJSON2): Observable<any> {
    return this.dataService.saveData(dataWS);
  }

  // TODO REPLACE BY GenerateRecordIDString
  public getRecordIdString(recordIdObj: {}): string {
    if (!recordIdObj) {
      return undefined;
    }
    if (typeof recordIdObj !== 'object') {
      return String(recordIdObj);
    }
    let recordId = '';
    Object.keys(recordIdObj).forEach((key) => {
      if (recordId) {
        recordId += ',';
      }
      recordId += key + ',' + recordIdObj[key];
    });
    return recordId;
  }

  public generateRecordIdString(columnsID: string[], data: {}): string {
    if (!data) {
      return undefined;
    }
    if (typeof data !== 'object') {
      return String(data);
    }
    let recordId = '';
    columnsID.forEach((columnName) => {
      // Ce test car pour les id qui vaut 0 (AD_Org) ça renvoi faux
      const value =
        data[columnName] !== undefined && data[columnName] !== null ? data[columnName] : data[columnName.toUpperCase()];
      if (data[columnName] === undefined || data[columnName] === null) {
        return undefined;
      }
      if (recordId) {
        recordId += ',';
      }
      recordId += columnName + ',' + value;
    });
    if (recordId === '') {
      recordId = data['Data_UUID'] ? data['Data_UUID'] : uuid();
    }
    return recordId;
  }

  public transformDataStoredToAray(dataStore: any): any[] {
    const dataArray = [];
    Object.keys(dataStore).forEach((key) => {
      dataArray.push(dataStore[key]);
    });

    return dataArray;
  }

  public getChangeLog(table_id: number, objectMap: any): Observable<any> {
    return this.dataService.getChangeLog(table_id, objectMap);
  }

  // public addToWindowCtx(windowId: number, domWinId: string, dsKey: DataStoreKey) {
  //   if (this.windowCtx[windowId] === undefined || this.windowCtx[windowId] === null) {
  //     this.windowCtx[windowId] = {};
  //   }
  //   if (this.windowCtx[windowId][domWinId] === undefined || this.windowCtx[windowId][domWinId] === null) {
  //     this.windowCtx[windowId][domWinId] = [];
  //   }
  //   if (
  //     this.windowCtx[windowId][domWinId].findIndex(
  //       key => dsKey.windowId === key.windowId && dsKey.tabId === key.tabId && dsKey.recordId === key.recordId
  //     ) === -1
  //   ) {
  //     this.windowCtx[windowId][domWinId].push(dsKey);
  //   }
  // }

  // public hasWindowCtx(windowId: number, domWinId: string) {
  //   return (
  //     this.windowCtx &&
  //     this.windowCtx[windowId] &&
  //     this.windowCtx[windowId][domWinId] &&
  //     this.windowCtx[windowId][domWinId].length > 0
  //   );
  // }

  /**
   *
   * @param windowId L'AD_Window_ID de la fenêtre
   * @param domWinId Le domWinId de la fenêtre (souvent stocké dans le tabUI [example: this.container.activeTab.domWinId])
   */
  // public getWindowCtx(windowId: number, domWinId: string) {
  //   if (this.windowCtx[windowId] && this.windowCtx[windowId][domWinId] && this.windowCtx[windowId][domWinId].length > 0) {
  //     let windowCtx = {};

  //     for (let i = 0; i < this.windowCtx[windowId][domWinId].length; i++) {
  //       const key = this.windowCtx[windowId][domWinId][i];
  //       const dataStore = this.findInStore(key, this.dataStore.windowStore.current) as DataStore;
  //       windowCtx = Object.assign(windowCtx, cloneDeep(dataStore.data));
  //     }

  //     return windowCtx;
  //   }
  //   return {};
  // }

  // public removeFromWindowCtx(windowId: number, domWinId: string, dsKey?: DataStoreKey) {
  //   if (this.windowCtx[windowId] && this.windowCtx[windowId][domWinId] && this.windowCtx[windowId][domWinId].length > 0) {
  //     if (dsKey) {
  //       const index = this.windowCtx[windowId][domWinId].findIndex(
  //         key => dsKey.windowId === key.windowId && dsKey.tabId === key.tabId && dsKey.recordId === key.recordId
  //       );
  //       this.windowCtx[windowId][domWinId].splice(index, 1);
  //     } else {
  //       this.windowCtx[windowId][domWinId] = [];
  //     }
  //   }
  // }

  public checkDataBeforeNewLine(dataStoreKey): boolean {
    let result = false;
    const dataStoreCurrent = <DataStore>this.findInStore(dataStoreKey, this.dataStore.windowStore.current);
    if (dataStoreCurrent) {
      result = dataStoreCurrent.status === DataStoreStatus.SYNC;
    }
    return result;
  }

  public getDataUUIDFromTabID(tabID: number) {
    return this.dataStore.dataUUIDs[tabID];
  }
  public getStore(dataStorekey: DataStoreKey, storeName: DataStoreName): DataStoreSet | DataStore {
    if (
      !this.dataStore.windowStore[storeName][dataStorekey.windowId] ||
      !this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId] ||
      !this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId]
    ) {
      return undefined;
    }
    if (dataStorekey.recordId) {
      if (
        !this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[
          dataStorekey.recordId
        ]
      ) {
        return undefined;
      } else {
        return this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[
          dataStorekey.recordId
        ];
      }
    } else {
      return this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId];
    }
  }

  public extractRecordInfoFromDsKey(dataStoreKey: DataStoreKey): Map<string, number> {
    const keyMap: Map<string, number> = new Map<string, number>();
    const filtersArray = dataStoreKey.recordId.split(',');
    if (filtersArray.length > 1) {
      for (let i = 0; i < filtersArray.length; i = i + 2) {
        const value = parseInt(filtersArray[i + 1], 10);
        if (!isNaN(value)) {
          keyMap.set(filtersArray[i], value);
        }
      }
    }
    return keyMap;
  }
  public getInfoWindowDefaultValues(infoWindowID: number, ctx: any): Observable<InfoWindowDV[]> {
    return this.dataService.getInfoWindowDefaultValues(infoWindowID, ctx);
  }

  public findInCurrentStore(dataStorekey: DataStoreKey): DataStoreSet | DataStore {
    return <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
  }
  public setColumnInfo(tabId: number, columnInfo: IupicsColumnInfo[]) {
    this.iupics_column_info.set(tabId, columnInfo);
  }
  public getColumnCompiereDataGridFilterType(tabId: number, columnName: string): CompiereDataGridFilterType {
    let columnInfo = null;
    if (this.iupics_column_info.has(tabId)) {
      columnInfo = this.iupics_column_info.get(tabId).find((col) => {
        if (col.fieldEntity && col.fieldEntity.field && col.fieldEntity.field.ColumnName === columnName) {
          return col;
        }
      });
    }
    return columnInfo ? columnInfo.filterType : null;
  }
  /*
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************* FOLLOWING METHOD MUST BE PRIVATE *************************************
   * *************************(only internal algorithms of store)************************************
   * ************************************************************************************************
   * ************************************************************************************************
   * ************************************************************************************************
   */
  private findInStore(dataStorekey: DataStoreKey, store: {}): DataStoreSet | DataStore {
    if (
      !store[dataStorekey.windowId] ||
      !store[dataStorekey.windowId][dataStorekey.tabId] ||
      !store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId]
    ) {
      return undefined;
    }
    if (dataStorekey.recordId) {
      if (!store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId]) {
        return undefined;
      } else {
        return store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId];
      }
    } else {
      return store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId];
    }
  }

  private removeFromStore(dataStorekey: DataStoreKey, store: {}) {
    if (store && Object.keys(store).length > 0) {
      if (
        dataStorekey &&
        dataStorekey.windowId !== undefined &&
        store[dataStorekey.windowId] !== undefined &&
        dataStorekey.tabId !== undefined &&
        store[dataStorekey.windowId][dataStorekey.tabId] !== undefined &&
        dataStorekey.parentId !== undefined &&
        store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId] !== undefined &&
        dataStorekey.recordId !== undefined &&
        store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId] !== undefined
      ) {
        delete store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId];
        store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].orderKeys = store[dataStorekey.windowId][
          dataStorekey.tabId
        ][dataStorekey.parentId].orderKeys.filter((key) => key !== dataStorekey.recordId);
      } else if (
        dataStorekey &&
        dataStorekey.windowId !== undefined &&
        store[dataStorekey.windowId] !== undefined &&
        dataStorekey.tabId !== undefined &&
        store[dataStorekey.windowId][dataStorekey.tabId] !== undefined &&
        dataStorekey.parentId !== undefined &&
        store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId] !== undefined &&
        dataStorekey.recordId === undefined
      ) {
        delete store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId];
      } else if (
        dataStorekey &&
        dataStorekey.windowId !== undefined &&
        store[dataStorekey.windowId] !== undefined &&
        dataStorekey.tabId !== undefined &&
        store[dataStorekey.windowId][dataStorekey.tabId] !== undefined &&
        dataStorekey.parentId === undefined &&
        dataStorekey.recordId === undefined
      ) {
        delete store[dataStorekey.windowId][dataStorekey.tabId];
      } else if (
        dataStorekey &&
        dataStorekey.windowId !== undefined &&
        store[dataStorekey.windowId] !== undefined &&
        dataStorekey.tabId === undefined &&
        dataStorekey.parentId === undefined &&
        dataStorekey.recordId === undefined
      ) {
        delete store[dataStorekey.windowId];
      }
    }
    return true;
  }

  private filterData(datas: DataStoreSet, startRow: number, endrow: number): {} {
    const dataFiltered = {};
    for (let i = startRow; i < datas.orderKeys.length && i <= endrow; i++) {
      dataFiltered[datas.orderKeys[i]] = datas.data[datas.orderKeys[i]];
    }

    return dataFiltered;
  }

  private copyToOldStore(datas: DataStore | DataStoreSet, store: { old: {} }): void {
    if (datas instanceof DataStoreSet) {
      datas.orderKeys.forEach((orderKey) => {
        const dataForOld = new DataStore();
        if (datas.data[orderKey]) {
          dataForOld.key = Object.assign({}, datas.data[orderKey].key);
          dataForOld.data = Object.assign({}, datas.data[orderKey].data);
          this.store(dataForOld.key, dataForOld, store.old);
        }
      });
    } else {
      const dataForOld = new DataStore();
      dataForOld.key = Object.assign({}, datas.key);
      dataForOld.data = Object.assign({}, datas.data);
      this.store(dataForOld.key, dataForOld, store.old);
    }
  }
  private replaceComplexType(dataWSResponse: CompiereDataGridResponseJSON) {
    if (dataWSResponse.data_UUID !== undefined) {
      // Si il n'y a pas d'array displayData dans la response on la créé
      const madeDisplayData = [];
      dataWSResponse.data_UUID.forEach((individualUUID) => {
        const individualUUIDSplitted = individualUUID.split('.');
        const uuidLength = individualUUIDSplitted.length;
        if (uuidLength > 0 && individualUUIDSplitted[uuidLength - 1]) {
          madeDisplayData.push(individualUUIDSplitted[uuidLength - 1]);
          if (uuidLength > 1) {
            dataWSResponse.data.map((line) => {
              line[individualUUIDSplitted[uuidLength - 1]] = line[individualUUID];
              delete line[individualUUID];
              return line;
            });
          }
        }
      });
      dataWSResponse.data_UUID = madeDisplayData;
      dataWSResponse.data.forEach((responseData) => {
        const data_uuid = this.generateRecordIdString(dataWSResponse.data_UUID, responseData);
        responseData['Data_UUID'] = data_uuid;
        if (dataWSResponse.displayData) {
          Object.keys(responseData).forEach((columnName) => {
            if (dataWSResponse.displayData[columnName + '$' + responseData[columnName]]) {
              responseData[columnName] = {
                id: responseData[columnName],
                displayValue: dataWSResponse.displayData[columnName + '$' + responseData[columnName]]
              };
            }
          });
        } else {
          Object.keys(responseData).forEach((columnName) => {
            if (madeDisplayData.includes(columnName)) {
              responseData[columnName] = {
                id: responseData[columnName],
                displayValue: responseData[columnName]
              };
            }
          });
        }
      });
    }
  }

  private transformNewDataWSAndStore(
    dataStorekey: DataStoreKey,
    dataWSResponse: CompiereDataGridResponseJSON,
    store: {}
  ): DataStoreKey {
    dataWSResponse.data.forEach((responseData) => {
      dataStorekey = this.transformOneNewDataWsAndStore(dataStorekey, responseData, store, dataWSResponse);
    });
    return dataStorekey;
  }

  private transformOneNewDataWsAndStore(
    dataStorekey: DataStoreKey,
    responseData: {},
    store: {},
    dataWs: CompiereDataGridResponseJSON
  ) {
    if (!responseData['apiz_dataResult'] || !responseData['apiz_dataResult']['responseError']) {
      const dataStorekey2 = Object.assign({}, dataStorekey);
      const dataStore: DataStore = new DataStore();
      dataStore.data = Object.assign({}, responseData);
      dataStorekey2.recordId = '';
      dataWs.data_UUID.forEach((data_uuid, index) => {
        if (responseData[data_uuid] instanceof Object) {
          dataStorekey2.recordId += data_uuid + ',' + responseData[data_uuid].id;
        } else {
          dataStorekey2.recordId += data_uuid + ',' + responseData[data_uuid];
        }
        dataStorekey2.recordId += index < dataWs.data_UUID.length - 1 ? ',' : '';
      });
      if (dataStorekey2.recordId === '') {
        dataStorekey2.recordId = dataStorekey.recordId;
      }
      this.store(dataStorekey2, dataStore, store, dataWs.lastRow);
      dataStorekey = dataStorekey2;
    }
    return dataStorekey;
  }

  private store(dataStorekey: DataStoreKey, data: DataStore, store: {}, lastRowRemote?: number) {
    if (!store[dataStorekey.windowId]) {
      store[dataStorekey.windowId] = {};
    }

    if (!store[dataStorekey.windowId][dataStorekey.tabId]) {
      store[dataStorekey.windowId][dataStorekey.tabId] = {};
    }

    if (!store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId]) {
      store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId] = new DataStoreSet();
    }
    if (!store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId]) {
      data.seqNo = store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].nextSeqNo++;
      store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].orderKeys.push(dataStorekey.recordId);
      data.key = dataStorekey;
      store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId] = data;
    }
    // Always replace Data
    store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId].data = data.data;
    if (lastRowRemote) {
      store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].lastRowRemote = lastRowRemote;
    }
  }

  private compareDataStoreChanges(old: DataStore, local: DataStore, remote: DataStore): DataConflict {
    let fields = null;
    if (old && old.key && old.key.tabId) {
      fields = Object.keys(this.newDataStructure.window[old.key.tabId]);
    } else {
      fields = Object.keys(old.data);
    }
    const dataResult: DataConflict = { mustRefresh: false, hasConflicts: false, dataChanged: {}, dataConflict: {} };
    // verif remote <> old
    moment.locale(this.connectorService.getIupicsDefaultLanguage().iso_code);
    for (let i = 0; i < fields.length; i++) {
      const diff = this.dateColumnCache.has(fields[i])
        ? (this.dateColumnCache.get(fields[i])
            ? moment(old.data[fields[i]]).format('YYYY-MM-DDTHH:mm:ss.SSS')
            : moment(old.data[fields[i]]).format('L')) !==
          (this.dateColumnCache.get(fields[i])
            ? moment(remote.data[fields[i]]).format('YYYY-MM-DDTHH:mm:ss.SSS')
            : moment(remote.data[fields[i]]).format('L'))
        : old.data[fields[i]] instanceof Object && remote.data[fields[i]] instanceof Object
        ? old.data[fields[i]].id !== remote.data[fields[i]].id
        : remote.data[fields[i]] === null && old.data[fields[i]] === ''
        ? false
        : old.data[fields[i]] != remote.data[fields[i]];
      if (diff) {
        dataResult.dataChanged[fields[i]] = remote.data[fields[i]];
      }
    }
    // si remote <> old, verif old <> local
    if (Object.keys(dataResult.dataChanged).length > 0) {
      for (let i = 0; i < fields.length; i++) {
        let diff = this.dateColumnCache.has(fields[i])
          ? (this.dateColumnCache.get(fields[i])
              ? moment(local.data[fields[i]]).format('YYYY-MM-DDTHH:mm:ss.SSS')
              : moment(local.data[fields[i]]).format('L')) !==
            (this.dateColumnCache.get(fields[i])
              ? moment(old.data[fields[i]]).format('YYYY-MM-DDTHH:mm:ss.SSS')
              : moment(old.data[fields[i]]).format('L'))
          : local.data[fields[i]] instanceof Object && old.data[fields[i]] instanceof Object
          ? local.data[fields[i]].id != old.data[fields[i]].id
          : local.data[fields[i]] != old.data[fields[i]];
        if (diff) {
          // remote <> old et old <> local, si local <> remote
          diff = this.dateColumnCache.has(fields[i])
            ? (this.dateColumnCache.get(fields[i])
                ? moment(old.data[fields[i]]).format('YYYY-MM-DDTHH:mm:ss.SSS')
                : moment(old.data[fields[i]]).format('L')) !==
              (this.dateColumnCache.get(fields[i])
                ? moment(remote.data[fields[i]]).format('YYYY-MM-DDTHH:mm:ss.SSS')
                : moment(remote.data[fields[i]]).format('L'))
            : old.data[fields[i]] instanceof Object && remote.data[fields[i]] instanceof Object
            ? old.data[fields[i]].id != remote.data[fields[i]].id
            : old.data[fields[i]] != remote.data[fields[i]];
          if (diff) {
            dataResult.dataConflict[fields[i]] = remote.data[fields[i]];
          } else {
            this.copyRemoteWindowDataToOldStore(remote.key, fields[i]);
          }
          delete dataResult.dataChanged[fields[i]];
        }
      }
    }
    dataResult.mustRefresh = Object.keys(dataResult.dataChanged).length > 0 && Object.keys(dataResult.dataConflict).length <= 0;
    dataResult.hasConflicts = Object.keys(dataResult.dataConflict).length > 0;
    if (Global.mergeLevel === 1 && dataResult.mustRefresh === true) {
      dataResult.refreshAuto = true;
    }
    if (Global.mergeLevel !== 2 && dataResult.hasConflicts === true) {
      dataResult.hasConflicts = false;
      dataResult.mustRefresh = true;
    }
    return dataResult;
  }

  private prepareDataRequest(dataRequest: DataStoreRequest): DataStoreRequest {
    if (!dataRequest.compiereRequest.rowGroupCols) {
      dataRequest.compiereRequest.rowGroupCols = [];
    }

    if (!dataRequest.compiereRequest.pivotCols) {
      dataRequest.compiereRequest.pivotCols = [];
    }

    if (!dataRequest.compiereRequest.valueCols) {
      dataRequest.compiereRequest.valueCols = [];
    }
    if (!dataRequest.compiereRequest.groupKeys) {
      dataRequest.compiereRequest.groupKeys = [];
    }
    if (!dataRequest.compiereRequest.sortModel) {
      dataRequest.compiereRequest.sortModel = [];
    }
    if (!dataRequest.compiereRequest.filterModel) {
      dataRequest.compiereRequest.filterModel = {};
    }

    // Si l'utilisateur a renseigné des filtres, on ne recherche pas dans le DataStore sinon oui
    dataRequest.isUserFilter = Object.keys(dataRequest.compiereRequest.filterModel).length > 0;

    // Si on a une contrainte avec un parent, on ajoute dans le filterModel
    if (dataRequest.parent_constraint) {
      if (!dataRequest.compiereRequest.filterModel) {
        dataRequest.compiereRequest.filterModel = {};
      }
      let filtersArray = dataRequest.parent_constraint.split('=');
      if (filtersArray.length <= 1) {
        const splittedRecord = dataRequest.parent_constraint.split(',');
        filtersArray = splittedRecord.length > 1 ? splittedRecord : [];
      }
      for (let i = 0; i < filtersArray.length; i = i + 2) {
        if (!dataRequest.compiereRequest.filterModel[filtersArray[i]]) {
          let value;
          let filterType = null;
          if (filtersArray[i + 1].startsWith('"')) {
            value = filtersArray[i + 1].substring(1, filtersArray[i + 1].length - 1);
          } else {
            if (!filtersArray[i].includes('_ID') && dataRequest.compiereRequest && dataRequest.compiereRequest.entityId) {
              filterType = this.getColumnCompiereDataGridFilterType(dataRequest.compiereRequest.entityId, filtersArray[i]);
              if (filterType && filterType === CompiereDataGridFilterType.TEXT) {
                value = filtersArray[i + 1];
              }
            }
            if (value === undefined) {
              if (filtersArray[i + 1] && isNaN(parseInt(filtersArray[i + 1], 10))) {
                value = filtersArray[i + 1];
              } else {
                value = parseInt(filtersArray[i + 1], 10);
              }
            }
          }
          dataRequest.compiereRequest.filterModel[filtersArray[i]] = {
            filterType: filterType ? filterType : CompiereDataGridFilterType.SET,
            values: [value],
            operators: [OperatorFilterType.EQUALS]
          };
        }
      }
    }
    // Si on a un recordId, on ajoute dans le filterModel
    // DataUUID="Entitype,N482"
    // DataUUID="AD_Entitype_ID,102482"
    if (dataRequest.record_id) {
      if (!dataRequest.compiereRequest.filterModel) {
        dataRequest.compiereRequest.filterModel = {};
      }
      const filtersArray = dataRequest.record_id.split(',');
      if (filtersArray.length > 1) {
        for (let i = 0; i < filtersArray.length; i = i + 2) {
          if (!dataRequest.compiereRequest.filterModel[filtersArray[i]]) {
            let filterType = null;
            let value;
            if (filtersArray[i + 1].startsWith('"')) {
              value = filtersArray[i + 1].substring(1, filtersArray[i + 1].length - 1);
            } else {
              if (!filtersArray[i].includes('_ID') && dataRequest.compiereRequest && dataRequest.compiereRequest.entityId) {
                filterType = this.getColumnCompiereDataGridFilterType(dataRequest.compiereRequest.entityId, filtersArray[i]);
                if (filterType && filterType === CompiereDataGridFilterType.TEXT) {
                  value = filtersArray[i + 1];
                }
              }
              if (value === undefined) {
                if (filtersArray[i + 1] && isNaN(parseInt(filtersArray[i + 1], 10))) {
                  value = filtersArray[i + 1];
                } else {
                  value = parseInt(filtersArray[i + 1], 10);
                }
              }
            }
            dataRequest.compiereRequest.filterModel[filtersArray[i]] = {
              filterType: filterType ? filterType : CompiereDataGridFilterType.SET,
              values: [value],
              operators: [OperatorFilterType.EQUALS]
            };
          }
        }
      }
    }

    dataRequest.compiereRequest.groupKeys = dataRequest.compiereRequest.groupKeys.map((groupKey) => {
      if (groupKey instanceof Object) {
        return groupKey.id;
      } else {
        return groupKey;
      }
    });

    return dataRequest;
  }

  private searchDataInStore(dataRequest: DataStoreRequest, dataStorekey: DataStoreKey, gridOutputFormat = false): any {
    let datas: any = this.findInStore(dataStorekey, this.dataStore.windowStore.current);
    if (
      datas &&
      dataRequest.compiereRequest.groupKeys.length <= 0 &&
      !dataRequest.compiereRequest.pivotMode &&
      dataRequest.compiereRequest.rowGroupCols.length <= 0 &&
      dataRequest.compiereRequest.sortModel.length <= 0 &&
      !dataRequest.isUserFilter
    ) {
      if (datas instanceof DataStoreSet) {
        const dataLength = (<DataStoreSet>datas).orderKeys.length;
        const lastRowRemote = (<DataStoreSet>datas).lastRowRemote;
        if (dataLength >= dataRequest.compiereRequest.startRow + dataRequest.compiereRequest.endRow || lastRowRemote > -1) {
          datas = this.filterData(<DataStoreSet>datas, dataRequest.compiereRequest.startRow, dataRequest.compiereRequest.endRow);
          if (gridOutputFormat) {
            // console.log('FROM STORE (grid format)');
            const response: CompiereDataGridResponseJSON = {
              data: [],
              data_UUID: [],
              displayData: {},
              secondaryColumnFields: [],
              lastRow: lastRowRemote
            };
            Object.keys(datas).forEach((key) => {
              response.data.push(datas[key].data);
            });
            datas = response;
          } else {
            // console.log('FROM STORE (datastore format)');
          }
        } else {
          datas = undefined;
        }
      } else {
        // console.log('FROM STORE (datastore format)');
      }
    } else {
      datas = undefined;
    }
    // TODO comprendre pq recordid non setté dans la datastorekey ce qui fait qu'on a un datas de ce format là
    if (datas) {
      const keys = Object.keys(datas);
      if (keys.length === 1) {
        datas = datas[keys[0]];
      }
    }
    return datas;
  }

  private initDataRequest(dataStorekey: DataStoreKey, component?: any, userContext?: any): DataStoreRequest {
    const dataRequest = {
      windowId: dataStorekey.windowId,
      record_id: dataStorekey.recordId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: dataStorekey.tabId,
        startRow: 0,
        endRow: 50
      }
    } as DataStoreRequest;

    if (component && component.editTabs) {
      let validation;
      if (
        component.linkedComponents &&
        component.linkedComponents[0] &&
        component.linkedComponents[0].data &&
        component.linkedComponents[0].data.validationCode
      ) {
        validation = component.linkedComponents[0].data.validationCode;
      }
      if (component.editTabs[0].gridTabValidator[0]) {
        validation = validation
          ? validation + component.editTabs[0].gridTabValidator[0]
          : component.editTabs[0].gridTabValidator[0];
      }
      dataRequest.compiereRequest.validation = LogicEvaluator.parseLogic(
        component.editTabs[0].dataStored.data,
        validation,
        userContext
      );
    }
    if (dataStorekey.recordId) {
      dataRequest.compiereRequest.endRow = 1;
    }

    return dataRequest;
  }

  private handleSyncResponse(
    dataResponse: CompiereDataGridResponseJSON,
    dataStorekey: DataStoreKey,
    checkConflict = false,
    component?: any,
    callback?: Function,
    callbackParam?: any
  ) {
    if (dataResponse && dataResponse.data.length > 0) {
      this.replaceComplexType(dataResponse);
      this.transformNewDataWSAndStore(dataStorekey, dataResponse, this.dataStore.windowStore.remote);
      const dataStoreCurrent = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
      const dataStoreRemote = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.remote);
      if (checkConflict) {
        const dataStoreOld = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.old);
        if (dataStoreCurrent.status !== DataStoreStatus.NEWRECORD) {
          const result = this.compareDataStoreChanges(dataStoreOld, dataStoreCurrent, dataStoreRemote);
          if (Global.mergeLevel === 2 && result.hasConflicts) {
            dataStoreCurrent.dataConflict.emit(result);
            if (Object.keys(result.dataChanged).length > 0) {
              this.syncDataChanges(dataStoreCurrent, result.dataChanged, true, true);
              this.copyToOldStore(dataStoreCurrent, this.dataStore.windowStore);
            }
            dataStoreCurrent.status = DataStoreStatus.NOTSYNC;
          }
          if (component) {
            component.conflictsResult = result;
          }
        }
        if (callback) {
          callback(callbackParam);
        }
      } else {
        this.syncDataChanges(dataStoreCurrent, dataStoreRemote.data, false, true);
        this.copyToOldStore(dataStoreCurrent, this.dataStore.windowStore);
        dataStoreCurrent.status = DataStoreStatus.SYNC;
      }
      return dataStoreCurrent;
    }
  }

  // Only for debugging
  private printData(dataStorekey: DataStoreKey, columnName: string, traceName?: string) {
    const dataStoreCurrent = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
    const dataStoreRemote = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.remote);
    const dataStoreOld = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.old);
    let str = '';
    if (traceName) {
      str = `-----------${traceName} (${columnName})-------------------`;
    }
    if (traceName) {
      let strClose = '';
      for (let i = 0; i < str.length; i++) {
        strClose += '-';
      }
    }
  }
}
