import { CompiereProcessInfo } from '@compiere-ws/models/compiere-process-json';
import { TreeNodeCompiere } from '@compiere-ws/models/compiere-tree-json';
import { CompiereWorkFlow } from '@compiere-ws/models/compiere-workflow-json';
import { SpecificWindowDetailItemFieldCompiereWS } from '@compiere-ws/models/specific-window-json';

export interface IupicsData {
  AD_ColumnSortOrder_ID?: number;
  AD_ColumnSortYesNo_ID?: number;
  AD_window_ID?: number;
  ADTabID?: number;
  ADTableID?: number;
  calloutLabel?: string;
  callouts?: IupicsCallout;
  colspan?: number;
  columnId?: number;
  columnName?: string;
  columnsTableHeader?: IupicsTableDataHeader[];
  columnsDisplayLogicMap?: Map<string, string>;
  columnsDetails?: Map<string, SpecificWindowDetailItemFieldCompiereWS>;
  componentName?: string;
  ctxArea?: {};
  data?: any;
  defaultValue?: string;
  description?: string;
  detailZoom?: IupicsZoomInfo;
  details?: IupicsDetails;
  DisplayLogic?: string;
  fieldId?: number;
  fieldType?: string;
  hasTree?: boolean;
  hasUniversalFilter?: boolean;
  hasExtendedLogic?: boolean;
  hasCheckbox?: boolean;
  id?: number;
  isAccount?: boolean;
  isAlwaysUpdatable?: boolean;
  isAdditionalInfo?: boolean;
  isCollapsable?: boolean;
  isCollapsedDefault?: boolean;
  isCopy?: boolean;
  isDocumentTab?: boolean;
  IsDeleteable?: boolean;
  isFilterSearch?: boolean;
  isLaunchSearchGrid?: boolean;
  IsOptional?: boolean;
  isMandatory?: boolean;
  IsReadOnly?: boolean;
  IsInsertRecord?: boolean;
  IsDisplayed?: boolean;
  IsParam?: boolean;
  IsRange?: boolean;
  isSameLine?: boolean;
  TableName?: string;
  isSearch?: boolean;
  isSelected?: boolean;
  isSingleRow?: boolean;
  isTabTopLevel?: boolean; // pour les keybind des grid view
  isView?: boolean;
  IsDeletableLogic?: string;
  DocFilters?: string;
  TaggedColumns?: string;
  items?: any[];
  label?: string;
  marginTop?: number;
  nbCol?: number;
  nbrAddressSlot?: number;
  numberType?: NumberType;
  positionEditTab?: number;
  query?: {};
  ReadOnlyLogic?: string;
  RecordID?: any;
  searchColumns?: any[];
  tabId?: number;
  tableName?: string;
  tabLevel?: number;
  urlCallouts?: string;
  urlList?: string;
  urlSearch?: string;
  urlTree?: string;
  validationCode?: string;
  autoCompletionFromClause?: string;
  workflowStatus?: CompiereWorkFlow;
  columns_display_AD?: IupicsColumnKanban[];
  kanbanImageColumn?: string;
  columnsReduceGrid?: string[];
  shouldSelectFirst?: boolean;
  hiddenColumns?: string[];
  editorColumns?: string[];
  frameworkComponents?: any;
  isFitResize?: boolean;
  needTime?: boolean;
  isLabelDisplay?: boolean;
  mandatoryLogic?: string;
  fieldTypeForm?: boolean;
  min?;
  max?;
  stepperData?: any;
  // #region custo
  URL_Externe?: string;
  isExpandedLogic?: string;
  GoogleRestrictedLogic?: string;
  IsGoogleRestricted?: boolean;
  // #endregion custo
}

export enum NumberType {
  MONTANT = '0.0001',
  INTEGER = '1',
  FLOAT = '0.01'
}

export interface IupicsZoomInfo {
  tableName?: string;
  parentId?: string;
  columnKey?: string;
  dataUUID?: string;
  record_id?: number;
  children?: any;
  windowId?: number;
}

export interface IupicsZoomTarget {
  record_id?: string;
}

export interface IupicsCallout {
  callouts: any[];
  tabId: number;
  windowId: number;
  fieldId: number;
}

export interface InfoComponent {
  name: string;
  windowId: number;
  domWinId?: string;
}

export interface IupicsWindow {
  windowId: number;
  children: IupicsTab[];
}

export interface IupicsTree {
  label: string;
  data: TreeNodeCompiere;
  expandedIcon?: string;
  collapsedIcon?: string;
  children?: IupicsTree[];
  icon?: string;
}

export interface IupicsTab {
  tabId: number;
  tabLevel: number;
  isSingleRow: boolean;
  editView: IupicsTabView;
  gridView: IupicsTabView;
}

export function instanceOfIupicsTab(object: any): object is IupicsTab {
  return 'editView' in object;
}

export interface IupicsTabView {
  children: IupicsField[];
  component: string;
  data: IupicsDataTab;
  tabId?: number;
  gridPaginator?: boolean;
  gridTabFilter?: string[];
  gridTabValidator?: string[];
}

export class IupicsDataTab implements IupicsData {
  AD_ColumnSortOrder_ID?: number;
  AD_ColumnSortYesNo_ID?: number;
  AD_window_ID?: number;
  ADTabID?: number;
  ADTableID?: number;
  colspan?: number;
  columnsTableHeader?: IupicsTableDataHeader[];
  ctxArea?: any;
  DisplayLogic?: string;
  hasTree?: boolean;
  isCollapsable?: boolean;
  isCollapsedDefault?: boolean;
  IsDeleteable?: boolean;
  TableName?: string;
  isDocumentTab?: boolean;
  isSingleRow?: boolean;
  items?: any[];
  label?: string;
  nbCol?: number;
  positionEditTab?: number;
  RecordID?: any;
  tabLevel?: number;
  urlTree?: string;
  workflowStatus?: CompiereWorkFlow;
  IsReadOnly?: boolean;
  IsInsertRecord?: boolean;
  ReadOnlyLogic?: string;
  IsDeletableLogic?: string | null;
  DocFilters?: string | null;
  TaggedColumns?: string | null;
  isView?: boolean;
  columns_display_AD?: IupicsColumnKanban[];
  kanbanImageColumn?: string;
  columnsReduceGrid?: string[];
  stepperData?: any;
  // #region custo
  URL_Externe?: string;
  isExpandedLogic?: string;
  // #endregion custo
}

export interface IupicsColumnKanban {
  dataAlignLeft: string[];
  dataAlignright: string[];
}

export interface IupicsField {
  children?: IupicsField[];
  columnId?: number;
  component: string;
  cssClass?: string;
  data: IupicsDataField;
  displayLength?: number;
  fieldGroup?: string;
  fieldId?: number;
  formDetailId?: number;
  formDetailItemId?: number;
  formId?: number;
  isCallout?: boolean;
  isRange?: boolean;
  isReadOnly?: boolean;
  processId: number;
  referenceId: number;
  seqNo: number;
  tableId?: number;
}

export class IupicsDataField implements IupicsData {
  formId?: number;
  FieldGroupExpanded?: boolean;
  //#region custo
  FieldGroupDisplayLogic?: any;
  FieldGroupExpandedLogic?: string;
  FieldGroupReadOnlyLogic?: string;
  IsGoogleRestricted?: boolean;
  GoogleRestrictedLogic?: string;
  //#endregion custo
  columnId?: number;
  fieldId?: number;
  calloutLabel?: string;
  callouts?: IupicsCallout;
  colspan?: number;
  columnName?: string;
  componentName?: string;
  defaultValue?: string;
  description?: string;
  detailZoom?: IupicsZoomInfo;
  DisplayLogic?: string;
  IsDisplayed?: boolean;
  fieldType?: string;
  hasExtendedLogic?: boolean;
  isAccount?: boolean;
  isAlwaysUpdatable?: boolean;
  isCopy?: boolean;
  isFilterSearch?: boolean;
  isLaunchSearchGrid?: boolean;
  IsOptional?: boolean;
  isMandatory?: boolean;
  IsParam?: boolean;
  IsRange?: boolean;
  isSameLine?: boolean;
  isSearch?: boolean;
  isSelected?: boolean;
  items?: any[];
  label?: string;
  marginTop?: number;
  nbCol?: number;
  nbrAddressSlot?: number;
  numberType?: NumberType;
  ReadOnlyLogic?: string;
  searchColumns?: any[];
  tabId?: number;
  tableName?: string;
  urlCallouts?: string;
  urlList?: string;
  urlSearch?: string;
  validationCode?: string;
  details?: IupicsDetails;
  displayKanban?: string;
  needTime?: boolean;
  autoCompletionFromClause?: string;
  mandatoryLogic?: string;
}

export interface IupicsDetails {
  keyColumn: string;
  tableName: string;
  autoCompletionFromClause?: string;
  sqlDisplayData?: string;
  entityID?: number;
  entityType?: number;
}
export interface DetailZoom {
  tableName: string;
  columnKey: string;
}

export interface IupicsProcess {
  name: string;
  description: string;
  help: string;
  fields: IupicsField[];
  process: CompiereProcessInfo;
  fileFormats: any;
  printFormats: CompierePrintFormat[];
}
export interface CompierePrintFormat {
  id: string;
  key: number;
  sortByName: boolean;
  name: string;
}
export interface IupicsTableDataHeader {
  headerName: string;
  field: string;
  rowGroup?: boolean;
  enableRowGroup?: boolean;
  enablePivot?: boolean;
  enableValue?: boolean;
  filter?: string;
  filterParams?: any;
  cellRenderer?: any;
  valueFormatter?: any;
  hide?: boolean;
  editable?: boolean;
  enableCellChangeFlash?: boolean;
  cellRendererParams?: any;
  cellEditor?: any;
  cellEditorParams?: any;
}

export interface IupicsSpecificWindow {
  AD_FormDetail_ID: number;
  AD_Form_ID: number;
  AD_InfoWindow_ID?: number;
  angularClass: string;
  description: string;
  entityType: string;
  help: string;
  name: string;
  title?: string;
  items?: any[];
}

export enum CellType {
  Numeric = 'Numeric',
  List = 'List'
}
