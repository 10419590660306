import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { ButtonUiComponent } from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-create-affiliation',
  templateUrl: './create-affiliation.component.html',
  styleUrls: ['./create-affiliation.component.scss']
})
export class CreateAffiliationComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('vcrChoix', { read: ViewContainerRef, static: true })
  vcrChoix: ViewContainerRef;
  @ViewChild('vcrButtonCreer', { read: ViewContainerRef, static: true })
  vcrButtonCreer: ViewContainerRef;
  helpReplaced = '';
  displayCustoModal = false;
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = true;
    this.customDesignArray.push(
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Fonction_Org_ID',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_Warehouse_ID',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Z_Fonction_ID',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Debut_Date',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsPrinc',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Org_ID',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrChoix',
        type: CustomDesignItemType.FIELD,
        columnName: 'closeAffOptions',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrButtonCreer',
        type: CustomDesignItemType.FIELD,
        columnName: 'CreateNewAff',
        cssClass: 'ui-g-12'
      }
    );
  }
  ngOnInit() {
    super.ngOnInit();
    const currentCtx = this.getCurrentContext();
    this.helpReplaced = LogicEvaluator.replaceVariables(this.help, currentCtx, currentCtx);
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'ui-g-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }
  showModal() {
    if (!this.isLoading) {
      const nbFieldMissing = this.getMissingMantoryField().length;
      if (nbFieldMissing === 0) {
        if (
          this.connectorService.getIupicsUserContext()['#AD_Role_ID'] === 1000033 ||
          this.dataStore.data['closeAffOptions'] === null ||
          this.dataStore.data['closeAffOptions'] === undefined
        ) {
          if (<ButtonUiComponent>this.getDatacontainer('CreateNewAff')) {
            (<ButtonUiComponent>this.getDatacontainer('CreateNewAff')).btnElt.nativeElement.click();
          }
        } else {
          this.displayCustoModal = !this.displayCustoModal;
        }
      }
    }
  }
  onTriggerProcess() {
    this.updateModalDisplay({ key: 'displayCustoModal', value: false });
  }
  onEndProcess() {
    this.updateLoading(false);
    if (this.parentComponent) {
      setTimeout(() => {
        this.closeModalEmitter.emit();
      }, 2000);
    }
  }
}
