import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  CompiereDataGridFilterType,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { LocationService } from '@compiere-ws/services/compiere-location/location.service';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import { InfoDialogType } from './../info-dialog/info-dialog.component';

@Component({
  selector: 'iu-preinscription-camp',
  templateUrl: './preinscription-camp.component.html',
  styleUrls: ['./preinscription-camp.component.scss']
})
export class PreinscriptionCampComponent extends SpecificWindowUiComponent
  implements OnInit {
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  isChanged = false;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private locationService: LocationService,
    pp: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      pp,
      translateService
    );
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'NameAssociation',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'PersonneContact',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_Location_ID',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Phone',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'GSM',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'EMail',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'SiteWeb',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'PreinscriptionValidate',
        cssClass: 'ui-g-12'
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'cancel',
        cssClass: 'ui-g-12'
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'ui-g-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
    if (this.sourceComponentData.data) {
      if (
        this.sourceComponentData.data.tableName === 'C_Project' &&
        this.getCurrentContext()['Z_Descriptif_camp'] === undefined
      ) {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('generic.warning'),
            'le champ descriptif du camp est obligatoire pour les camps pionniers',
            'error'
          )
        );
        this.parentComponent.updateModalDisplay({
          key: 'displayFormUI',
          value: false
        });
        return;
      }

      if (this.sourceComponentData.data.columnName === 'EditPart') {
        let request: DataStoreRequest = {
          windowId: null,
          parent_constraint: '',
          compiereRequest: {
            startRow: 0,
            endRow: 0,
            tableName: 'C_BPartner',
            filterModel: {
              C_BPartner_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [this.getCurrentContext()['C_BPartner_ID'].id],
                operators: [OperatorFilterType.EQUALS]
              }
            }
          }
        };

        this.store.getDataGrid(request).subscribe(response => {
          this.dataStore.data['NameAssociation'] = response.data[0]['NAME'];
          this.dataStore.data['PersonneContact'] = response.data[0]['NAME2'];
          this.dataStore.data['EMail'] = response.data[0]['EMAIL_TIERS'];
          this.dataStore.data['Phone'] = response.data[0]['TEL_TIERS'];
          this.dataStore.data['GSM'] = response.data[0]['GSM_TIERS'];
          this.dataStore.data['SiteWeb'] = response.data[0]['URL'];
          this.setDatacontainerValue(
            'NameAssociation',
            response.data[0]['NAME']
          );
          this.setDatacontainerValue(
            'PersonneContact',
            response.data[0]['NAME2']
          );
          this.setDatacontainerValue('EMail', response.data[0]['EMAIL_TIERS']);
          this.setDatacontainerValue('Phone', response.data[0]['TEL_TIERS']);
          this.setDatacontainerValue('GSM', response.data[0]['GSM_TIERS']);
          this.setDatacontainerValue('SiteWeb', response.data[0]['URL']);
          request = {
            windowId: null,
            parent_constraint: '',
            compiereRequest: {
              startRow: 0,
              endRow: 0,
              tableName: 'C_BPartner_Location',
              filterModel: {
                C_BPartner_ID: {
                  filterType: CompiereDataGridFilterType.SET,
                  values: [this.getCurrentContext()['C_BPartner_ID'].id],
                  operators: [OperatorFilterType.EQUALS]
                }
              }
            }
          };

          this.store.getDataGrid(request).subscribe(response2 => {
            if (response2.data[0]) {
              this.locationService
                .getLocation(response2.data[0]['C_LOCATION_ID'])
                .subscribe(response => {
                  const addressData = [
                    response['Address1'],
                    response['Address2'],
                    response['Address3'],
                    response['Address4'],
                    response['Postal'],
                    response['RegionName'],
                    response['City']
                  ];
                  const addressStr = addressData
                    .filter(
                      item => item !== undefined && item !== null && item !== ''
                    )
                    .join(',');
                  this.setDatacontainerValue('C_Location_ID', {
                    id: response['C_Location_ID'],
                    displayValue: addressStr
                  });
                });
            } else {
              this.setDataContainersValueWithChangedStore();
            }
          });
        });
      }
    }
  }

  onEndProcess() {
    try {
      this.parentComponent.updateModalDisplay({
        key: 'displayFormUI',
        value: false
      });
      if (this.sourceComponent.data) {
        if (this.sourceComponent.data['tableName'] === 'C_Project') {
          this.parentComponent.updateAll(false);
        }
        if (this.sourceComponent.data['tableName'] === 'Y_LOCAL_PARTNER') {
          if (
            this.parentComponent.DOMParentComponent
              .DOMChildrenComponent[1] instanceof EditViewUiComponent
          ) {
            this.parentComponent.DOMParentComponent.DOMChildrenComponent[1].updateAll(
              false
            );
          }
        }
      } else {
        if (
          this.parentComponent.DOMParentComponent
            .DOMChildrenComponent[1] instanceof EditViewUiComponent
        ) {
          this.parentComponent.DOMParentComponent.DOMChildrenComponent[1].updateAll(
            false
          );
        }
      }
    } catch (error) {
      console.log('Not refreshed');
    }
  }

  processPreinscriptionValidate(item) {
    if (this.validationForm()) {
      if (this.sourceComponent.data) {
        this.dataStore.data['PreinscriptionValidate'] =
          this.sourceComponent.data['columnName'] === 'EditPart' ? false : true;
      } else {
        this.dataStore.data['PreinscriptionValidate'] = true;
      }
      this.executeProcess(item.processId);
    }
  }

  processcancel(item) {
    if (this.isChanged) {
      Global.infoDialog.message = {
        summary: this.translateService.instant(
          'infodialog.dialogs.checkBefore.close.title'
        ),
        detail: this.translateService.instant(
          'infodialog.dialogs.checkBefore.close.message'
        )
      };

      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();
      const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
        this.dataStore.data['PreinscriptionValidate'] =
          this.sourceComponentData.columnName === 'EditPart' ? false : true;

        this.fields.forEach(field => {
          if (field.data['columnName'] === 'PreinscriptionValidate') {
            if (this.validationForm()) {
              this.executeProcess(field.processId);
              this.parentComponent.updateModalDisplay({
                key: 'displayFormUI',
                value: false
              });
            }
            confirm.unsubscribe();
            cancel.unsubscribe();
          }
        });
      });
      const cancel = Global.infoDialog.cancel.subscribe(e => {
        // ANNULATION
        confirm.unsubscribe();
        cancel.unsubscribe();
        this.parentComponent.updateModalDisplay({
          key: 'displayFormUI',
          value: false
        });
      });
    } else {
      this.parentComponent.updateModalDisplay({
        key: 'displayFormUI',
        value: false
      });
    }
  }

  notifyFromDataChange(item) {
    super.notifyFromDataChange(item);
    this.isChanged = true;
  }

  validationForm(): boolean {
    const warning: string[] = [];

    if (this.dataStore.data['NameAssociation'] === undefined) {
      warning.push(
        this.translateService.instant('editView.fillMandatoryField') +
          ' : [ NameAssociation ]'
      );
    }

    const regex = new RegExp(
      '^[\\w-\\+]+(\\.[\\w-]+)*@[\\w-]+(\\.[w]+)*(\\.[a-z]{2,})$'
    );

    if (
      this.dataStore.data['EMail'] &&
      !regex.test(this.dataStore.data['EMail'])
    ) {
      warning.push('l\'adresse email est invalide');
    }

    if (this.dataStore.data['C_Location_ID'] === undefined) {
      warning.push('Adresse vide');
    }

    if (warning.length > 0) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          warning.join(', '),
          'error'
        )
      );
    }

    return warning.length === 0;
  }
}
