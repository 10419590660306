import { EventEmitter, Injectable, ViewChild } from '@angular/core';
import { CompiereNotification } from '@compiere-ws/models/compiere-notification-json';
import { CompiereNotificationService } from '@compiere-ws/services/compiere-notification/compiere-notification.service';
import { PrintReportService } from '@compiere-ws/services/compiere-print-report/print-report.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { InfoDialogComponent, InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { Global } from '@iupics-manager/models/global-var';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
@Injectable()
export class NotificationManagerService {
  onChangeRoleChannel = new EventEmitter<any>();
  onRoleChanged = new EventEmitter<any>();
  private syncWaitingNotifications: {
    [key: string]: Subject<CompiereNotification>;
  } = {};
 //strat redmin 146735
 @ViewChild(InfoDialogComponent, { static: true })
 infoDialog: InfoDialogComponent;
 source: CompiereNotification;
 //end redmin 146735 

  constructor(
    private printReportService: PrintReportService,
    private messageService: MessageService,
    private compiereNotificationService: CompiereNotificationService,
    private socketService: SocketService
  ) {}

   //start redmin 146735 
   openInfoDialog(summary: any, detail: any) {
    Global.infoDialog.message = {
      summary: summary,
      detail: detail
    };
    Global.infoDialog.dialogType = InfoDialogType.INFO;
    Global.infoDialog.showInfoDialog();
  }
  //end redmin 146735 

  getNotifications(): Observable<CompiereNotification[]> {
    return this.compiereNotificationService.getNotifications();
  }

  handleNotification(notif: CompiereNotification): Observable<CompiereNotification> {
    return this.compiereNotificationService.handleNotification(notif);
  }

  handleAllNotification(): Observable<CompiereNotification> {
    return this.compiereNotificationService.handleAllNotification();
  }

  closeNotification(notif: CompiereNotification): Observable<CompiereNotification> {
    return this.compiereNotificationService.closeNotification(notif);
  }

  closeAllNotification(): Observable<CompiereNotification> {
    return this.compiereNotificationService.closeAllNotification();
  }

  changeRoleChannel(previous: number, next: number): void {
    this.onChangeRoleChannel.emit({ previousRole: previous, nextRole: next });
  }

  roleChanged(): void {
    this.onRoleChanged.emit();
  }

  popNotification(...notifications: CompiereNotification[]) {
    notifications.forEach((notification) => {
        // redmin 146735 
let passage_masse_process=notification.sourceTypeId===1000053?true:false; 
let createAff_process=notification.sourceTypeId===1000111 && notification.title==='CreateAffProcess'?true:false; //test on windowID && process value
let passage_carte_process=notification.sourceTypeId===1009226?true:false;
        if(passage_masse_process ||  passage_carte_process || createAff_process ){
    let constraint_value: String = notification.summary;
     let constraint_message = notification.summary.split("--").pop();
     notification.summary = constraint_message;

     let match = constraint_value.match(/--(.*?)--/g);
     let summary = null;
     if (match && match.toString()) {
        summary = match.toString().replace(new RegExp('--', 'g'), '');
       }
        this.openInfoDialog(notification.title,notification.summary);
      }
     else {  //end redmin 146735 

      this.messageService.add({
        summary: notification.title,
        detail: notification.summary,
        data: { notification: notification, type: 'Notification' }
      });
    }
    });
  }

  syncWithNotification(channelId: string): Subject<CompiereNotification> {
    this.syncWaitingNotifications[channelId] = new Subject();
    (async () => {
      for await (const notification of this.socketService.enableRoleNotifications()) {
        if ((<CompiereNotification>notification).processChannelId === channelId) {
          this.syncWaitingNotifications[channelId].next(notification);
          delete this.syncWaitingNotifications[channelId];
        }
      }
    })();
    return this.syncWaitingNotifications[channelId];
  }

  downloadReport(url: string): void {
    const unSubObj = this.printReportService.downloadReport(url).subscribe((response) =>
      setTimeout(() => {
        Global.downloadFile(response);
        unSubObj.unsubscribe();
      }, 100)
    );
  }
}
