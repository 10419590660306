import { Component, ComponentFactoryResolver, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { InscriptionParticipantInfo } from '@compiere-ws/models/waiting-list-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { WaitingListService } from '@compiere-ws/services/waiting-list/waiting-list.service';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { zip } from 'rxjs';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import { ProcessParams } from '../process-ui/process-ui.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import { debounce } from 'lodash';
@Component({
  selector: 'iu-inscribe-ui',
  templateUrl: './inscribe-ui.component.html',
  styleUrls: ['./inscribe-ui.component.scss']
})
export class InscribeUiComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('firstGrid', { read: ViewContainerRef, static: true })
  vcrFirstGrid: ViewContainerRef;
  @ViewChild('secondGrid', { read: ViewContainerRef, static: true })
  vcrSecondGrid: ViewContainerRef;
  @ViewChildren('vcrRole', { read: ViewContainerRef })
  vcrRole: QueryList<ViewContainerRef>;
  @ViewChild('vcrButtonInscrire', { read: ViewContainerRef, static: true })
  vcrButtonInscrire: ViewContainerRef;
  @ViewChild('vcrTmp', { read: ViewContainerRef, static: true })
  vcrTmp: ViewContainerRef;
  registerEnabled = false;
  fonctionLines: { name: string; nb: number; values?: String[] }[] = [];
  displayCustoModal = false;
  isLoading = false;
  datacontainersRoleEvenement: {
    datacontainer: AbstractDataContainer;
    dataUUIDs: String[];
  }[] = [];
  notInscribeGridId = null;
  mapRole: Map<string, number> = new Map<string, number>();
  isSinglePartner = false;
  headerTitle = 'specificWindow.inscribe.subtitle1';
  forceInscribeRoles = [];
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private ppService: ProcessInProgressService,
    private po: PoService,
    private waitingListService: WaitingListService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      ppService,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    const request: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_MESSAGE',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['SCOUTS_INSCRIBE_FORM'],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };
    const request2: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: 'AD_MESSAGE',
        filterModel: {
          VALUE: {
            filterType: CompiereDataGridFilterType.SET,
            values: ['SCOUTS_FORCE_INSCRIBE_ROLES'],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };
    zip(this.store.getDataGrid(request), this.store.getDataGrid(request2)).subscribe((responses) => {
      if (responses[0] && responses[0].data.length > 0) {
        if (responses[1] && responses[1].data.length > 0) {
          const values = responses[1].data[0]['MSGTEXT'];
          this.forceInscribeRoles = values.split(',');
        }
        const msg = responses[0].data[0]['MSGTEXT'];
        const singleLineWindowIds = msg.split(',');
        let windowId = -1;
        if (this.parentComponent && this.parentComponent.currentDataStoreKey) {
          windowId = this.parentComponent.currentDataStoreKey.windowId;
        }
        this.notInscribeGridId = parseInt(singleLineWindowIds[0], 10);
        if (!singleLineWindowIds.includes('' + windowId)) {
          this.customDesignArray = [
            {
              vcr: 'vcr',
              type: CustomDesignItemType.FIELD,
              columnName: 'AD_Org_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-2'
            },
            {
              vcr: 'vcr',
              type: CustomDesignItemType.FIELD,
              columnName: 'M_Warehouse_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-2'
            },
            {
              vcr: 'vcr',
              type: CustomDesignItemType.FIELD,
              columnName: 'Z_Fonction_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-2'
            },
            {
              vcr: 'vcr',
              type: CustomDesignItemType.FIELD,
              columnName: 'AD_Client_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-2'
            },
            {
              vcr: 'vcr',
              type: CustomDesignItemType.FIELD,
              columnName: 'C_Project_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-offset-2 ui-lg-4'
            },
            {
              vcr: 'vcrFirstGrid',
              type: CustomDesignItemType.GRID,
              tableName: 'Inscrire - Table',
              cssClass: 'ui-g-12'
            },
            {
              vcr: 'vcrSecondGrid',
              type: CustomDesignItemType.GRID,
              tableName: 'Inscrire - Table Liste Inscrits',
              cssClass: 'ui-g-12'
            },
            {
              vcr: 'vcrButtonInscrire',
              type: CustomDesignItemType.FIELD,
              columnName: 'Inscrire',
              cssClass: 'ui-g-12 ui-md-3 ui-lg-3',
              events: { click: this.inscrire.bind(this) }
            }
          ];
        } else {
          this.isSinglePartner = true;
          this.headerTitle = 'specificWindow.inscribe.subtitle1Single';
          this.customDesignArray = [
            {
              vcr: 'vcrTmp',
              type: CustomDesignItemType.FIELD,
              columnName: 'AD_Org_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-2'
            },
            {
              vcr: 'vcrTmp',
              type: CustomDesignItemType.FIELD,
              columnName: 'M_Warehouse_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-2'
            },
            {
              vcr: 'vcrTmp',
              type: CustomDesignItemType.FIELD,
              columnName: 'Z_Fonction_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-2'
            },
            {
              vcr: 'vcrTmp',
              type: CustomDesignItemType.FIELD,
              columnName: 'AD_Client_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-2'
            },
            {
              vcr: 'vcr',
              type: CustomDesignItemType.FIELD,
              columnName: 'C_BPartner_ID',
              cssClass: 'ui-g-12 ui-md-6 ui-lg-2'
            },
            {
              vcr: 'vcr',
              type: CustomDesignItemType.FIELD,
              columnName: 'C_Project_ID',
              cssClass: 'ui-g-8 ui-md-6 ui-lg-3'
            },
            {
              vcr: 'vcr',
              type: CustomDesignItemType.FIELD,
              columnName: 'Z_Role_Evenement_ID',
              cssClass: 'ui-g-8 ui-md-6 ui-lg-3'
            },

            {
              vcr: 'vcrButtons',
              type: CustomDesignItemType.FIELD,
              columnName: 'Inscrire',
              cssClass: 'ui-g-12 ui-md-3 ui-lg-3',
              events: { click: this.inscrire.bind(this) }
            }
          ];
        }
        if (!this.isModal) {
          Global.workspace.linkedComponentToTabMap[this.activeTab.id] = this;
        }
        if (this.parentComponent instanceof EditViewUiComponent) {
          if (!this.dataStore) {
            this.dataStore = this.store.newSpecificWindowData(
              this.formId,
              (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
            );
          }
        }
        const item: DynamicComponent = {
          container: this,
          DOMParentComponent: this,
          component: 'SpecificWindowUiComponent',
          cssClass: 'ui-g-12',
          isCssOnComponent: false,
          tabId: this.formId,
          gridPaginator: false
        };
        this.windowFactory.newEventHandler({
          type: IupicsTypeEvent.showSpecificWindow,
          item: item
        });
      }
    });
   
  }
  register() {
    if (this.getFormStoreData('C_Project_ID')) {
      this.datacontainersRoleEvenement = [];
      if (this.vcrRole) {
        this.vcrRole.forEach((vcr) => vcr.clear());
      }
      this.fonctionLines = [];
      const fonctions = {};
      const values = {};
      const infoGrille = this.dataStore.data['selections'].find((grid) => grid['AD_FormDetail_ID'] === this.notInscribeGridId);
      infoGrille.selection
        .filter((v, i) => infoGrille.selection.findIndex((selectedRow) => selectedRow['Data_UUID'] === v['Data_UUID']) === i)
        .forEach((node) => {
          const name = node['FONCTION'];
          if (fonctions[name] === undefined) {
            fonctions[name] = 1;
            values[name] = [node['Data_UUID']];
          } else {
            const nbRow = fonctions[name];
            fonctions[name] = nbRow + 1;
            values[name].push(node['Data_UUID']);
          }
        });
      Object.keys(fonctions).forEach((fonctionName) => {
        this.newLine({
          name: fonctionName,
          nb: fonctions[fonctionName],
          values: values[fonctionName]
        });
      });
      this.displayCustoModal = !this.displayCustoModal;
    } else {
      throw new Error(this.translateService.instant('specificWindow.inscribe.eventNotSelected'));
    }
  }
  notifyFromRowSelected() {
    const infoGrille = this.dataStore.data['selections'].find((grid) => grid['AD_FormDetail_ID'] === this.notInscribeGridId);
    this.registerEnabled = infoGrille.selection.length > 0;
  }
  newLine(key: { name: string; nb: number; values?: String[] }) {
    const metaData: CustomDesignItem = {
      vcr: 'vcrRole',
      type: CustomDesignItemType.FIELD,
      columnName: 'Z_Role_Evenement_ID',
      cssClass: 'ui-g-8 ui-md-6 ui-lg-3',
      isStandalone: true,
      isLabelDisplay: false
    };
    this.fonctionLines.push(key);
    const index = this.fonctionLines.length - 1;
    setTimeout(() => {
      const compRef = this.createCustomDesignItem(metaData, true, index);
      (<AbstractDataContainer>compRef.instance).data['FONCTION'] = this.fonctionLines[index].name;
      const newDataStore = this.store.newSpecificWindowData(this.formId, this.dataStore);
      (<AbstractDataContainer>compRef.instance).setNewData(newDataStore);
      this.datacontainersRoleEvenement.push({
        datacontainer: <AbstractDataContainer>compRef.instance,
        dataUUIDs: key.values
      });
    }, 10);
  }
  checkBeforeProcessing() {
    let success = true;
    if (this.mandatoryFields.length > 0) {
      this.mandatoryFields.forEach((column) => {
        if (this.dataStore.data[column] === null || this.dataStore.data[column] === undefined) {
          success = false;
          return;
        }
      });
    }
    if (success) {
      if (!this.isSinglePartner) {
        const selectedRows = this.dataStore.data['selections'].find((grid) => grid['AD_FormDetail_ID'] === this.notInscribeGridId)
          .selection;
        this.datacontainersRoleEvenement.forEach((datacontainerRoleEvenement) => {
          selectedRows
            .filter((rowFiltered) => datacontainerRoleEvenement.dataUUIDs.includes(rowFiltered['Data_UUID']))
            .forEach((row) => {
              if (datacontainerRoleEvenement.dataUUIDs.includes(row['Data_UUID'])) {
                if (
                  datacontainerRoleEvenement.datacontainer.fieldValue &&
                  datacontainerRoleEvenement.datacontainer.fieldValue.id
                ) {
                  row['Z_Role_Evenement_ID'] = datacontainerRoleEvenement.datacontainer.fieldValue.id;
                  row['Z_Role_Evenement'] = datacontainerRoleEvenement.datacontainer.fieldValue.displayValue;
                } else {
                  success = false;
                  throw new Error(this.translateService.instant('specificWindow.inscribe.roleNotSelected') + row['FONCTION']);
                }
              }
            });
        });
      } else {
        if (!this.getFormStoreData('Z_Role_Evenement_ID')) {
          success = false;
          throw new Error(this.translateService.instant('specificWindow.inscribe.roleNotSelected'));
        }
      }
    }
    return success;
  }
  inscrire(item: any) {
    const button = this.getDatacontainer('Inscrire');
    if (button) {
      button.isReadOnly = true;
    }
    const participantInfos: InscriptionParticipantInfo[] = [];
    if (!this.isSinglePartner) {
      const selectedRows = this.dataStore.data['selections'].find((grid) => grid['AD_FormDetail_ID'] === this.notInscribeGridId)
        .selection;
      const mapRole: Map<string, number> = new Map<string, number>();
      this.datacontainersRoleEvenement.forEach((datacontainerRoleEvenement) => {
        selectedRows
          .filter((v, i) => selectedRows.findIndex((selectedRow) => selectedRow['Data_UUID'] === v['Data_UUID']) === i)
          .filter((rowFiltered) => datacontainerRoleEvenement.dataUUIDs.includes(rowFiltered['Data_UUID']))
          .forEach((row) => {
            if (datacontainerRoleEvenement.dataUUIDs.includes(row['Data_UUID'])) {
              if (datacontainerRoleEvenement.datacontainer.fieldValue && datacontainerRoleEvenement.datacontainer.fieldValue.id) {
                if (mapRole.get(datacontainerRoleEvenement.datacontainer.fieldValue.displayValue)) {
                  mapRole.set(
                    datacontainerRoleEvenement.datacontainer.fieldValue.displayValue,
                    mapRole.get(datacontainerRoleEvenement.datacontainer.fieldValue.displayValue) + 1
                  );
                } else {
                  mapRole.set(datacontainerRoleEvenement.datacontainer.fieldValue.displayValue, 1);
                }
                row['Z_Role_Evenement_ID'] = datacontainerRoleEvenement.datacontainer.fieldValue.id;
                row['Z_Role_Evenement'] = datacontainerRoleEvenement.datacontainer.fieldValue.displayValue;
              } else {
                throw new Error(`Pas de role défini avant l'inscription : ${row['FONCTION']}`);
              }
            }
          });
      });
      this.mapRole = mapRole;
      selectedRows.forEach((row) => {
        participantInfos.push({
          adOrgId: row['AD_Org_ID'],
          cBPartnerID: row['C_BPartner_ID'],
          zRoleEvenementId: row['Z_Role_Evenement_ID']
        });
      });
    } else {
      participantInfos.push({
        adOrgId: this.getFormStoreData('AD_Org_ID') ? this.getFormStoreData('AD_Org_ID').id : -1,
        cBPartnerID: this.getFormStoreData('C_BPartner_ID') ? this.getFormStoreData('C_BPartner_ID').id : -1,
        zRoleEvenementId: this.getFormStoreData('Z_Role_Evenement_ID') ? this.getFormStoreData('Z_Role_Evenement_ID').id : -1
      });
    }
    this.po.get<POEvenement>('C_Project', this.getFormStoreData('C_Project_ID').id).subscribe((evenement) => {
      if (evenement && Object.keys(evenement).length > 0) {
        const condition = this.getCondition(evenement);
        if (condition === undefined) {
          this.forceInscribe(participantInfos, item);
        } else {
          if (this.forceInscribeRoles.includes(this.connectorService.getIupicsUserContext()['#AD_Role_ID'].toString())) {
            Global.infoDialog.message = {
              summary: this.translateService.instant(condition.title),
              detail:
                this.translateService.instant(condition.text) +
                this.translateService.instant('specificWindow.inscribe.wantToContinue')
            };
            Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
            Global.infoDialog.showInfoDialog();
            const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
              this.forceInscribe(participantInfos, item);
              confirm.unsubscribe();
              cancel.unsubscribe();
            });
            const cancel = Global.infoDialog.cancel.subscribe((e) => {
              if (button) {
                button.isReadOnly = false;
              }
              this.displayCustoModal = false;
              confirm.unsubscribe();
              cancel.unsubscribe();
            });
          } else {
            Global.infoDialog.message = {
              summary: this.translateService.instant(condition.title),
              detail: this.translateService.instant(condition.text)
            };
            Global.infoDialog.dialogType = InfoDialogType.INFO;
            Global.infoDialog.showInfoDialog();
            const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
              if (button) {
                button.isReadOnly = false;
              }
              this.displayCustoModal = false;
              confirm.unsubscribe();
            });
          }
        }
      }
    });
  }
  getCondition(evenement: POEvenement): ConditionMessage {
    if (evenement.Processed === 'Y') {
      return {
        title: 'specificWindow.inscribe.projectProcessed',
        text: 'specificWindow.inscribe.projectProcessedMessage'
      };
    }
    if (evenement.LockProject === 'D') {
      return {
        title: 'specificWindow.inscribe.projectLocked',
        text: 'specificWindow.inscribe.projectLockedMessage'
      };
    }
    if (evenement.DateFinish) {
      const timeVal = moment(evenement.DateFinish);
      const time = timeVal.format('YYYY-MM-DDTHH:mm:ss.SSS');
      const finalTime = time.substring(0, 26) + time.substring(27, time.length);
      const today = moment();
      const date = moment(finalTime);
      if (today.isAfter(date, 'day')) {
        return {
          title: 'specificWindow.inscribe.projectInscribeConfirm',
          text: 'specificWindow.inscribe.projectInscribeConfirmMessage'
        };
      }
    }
    if (evenement.Session_Status !== 'O') {
      return {
        title: 'specificWindow.inscribe.projectInscribeConfirm',
        text: 'specificWindow.inscribe.projectInscribeConfirmMessage'
      };
    }
  }
  forceInscribe(participantInfos: InscriptionParticipantInfo[], item: any) {
    this.subscriptions.push(
      this.waitingListService
        .getInscriptionConstraintList({
          cProjectId: this.getFormStoreData('C_Project_ID').id ? this.getFormStoreData('C_Project_ID').id + '' : '-1',
          participantInfos: participantInfos
        })
        .subscribe(
          (response) => {
            let message = '';
            if (response) {
              const keyList = Object.keys(response);
              if (keyList.length > 0) {
                keyList.forEach((key) => {
                  const inscriptionConstraint = response[key];
                  if (
                    inscriptionConstraint.stopInscription ||
                    inscriptionConstraint.hasError ||
                    inscriptionConstraint.hasGeneralError
                  ) {
                    message += '<center><h2>' + inscriptionConstraint.cBPartnerName + '</h2></center>';
                    inscriptionConstraint.constraintChecks = inscriptionConstraint.constraintChecks
                      .filter((constraint) => constraint.isConfirm)
                      .sort(function (a, b) {
                        return a.order - b.order;
                      });
                    inscriptionConstraint.constraintChecks.forEach((constraint) => {
                      if (constraint.popupMessage && constraint.popupMessage.length > 0) {
                        message += constraint.popupMessage + '<br><hr>';
                      }
                    });
                    const button = this.getDatacontainer('Inscrire');
                    if (button) {
                      button.isReadOnly = false;
                    }
                  }
                });
              }
            }
            this.sendMessage(item, message);
          },
          (error) => {
            Global.infoDialog.message = {
              summary: this.translateService.instant('specificWindow.inscribe.constraintError'),
              detail: this.translateService.instant('specificWindow.inscribe.constraintErrorMessage')
            };
            Global.infoDialog.dialogType = InfoDialogType.INFO;
            Global.infoDialog.showInfoDialog();
            const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
            const button = this.getDatacontainer('Inscrire');
              if (button) {
                button.isReadOnly = false;
              }
              confirm.unsubscribe();
            });
          }
        )
    );
  }
  sendMessage(item, message) {
    if (message.length > 0) {
      this.subscriptions.push(
        this.po.get<any>('AD_Role', this.connectorService.getIupicsUserContext()['#AD_Role_ID']).subscribe(
          (role) => {
            const forceInscr = role['Force_Inscr'] == 'Y';
            Global.infoDialog.message = {
              summary: forceInscr
                ? this.translateService.instant('specificWindow.inscribe.askForced')
                : this.translateService.instant('specificWindow.inscribe.cantForce'),
              detail: message
            };
            if (forceInscr) {
              Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
              Global.infoDialog.showInfoDialog();
              const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
                this.inscrireProcess(item);
                confirm.unsubscribe();
                cancel.unsubscribe();
              });
              const cancel = Global.infoDialog.cancel.subscribe((e) => {
                confirm.unsubscribe();
                cancel.unsubscribe();
              });
            } else {
              Global.infoDialog.dialogType = InfoDialogType.INFO;
              Global.infoDialog.showInfoDialog();
              const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
                confirm.unsubscribe();
              });
            }
          },
          (error) => console.log
        )
      );
    } else {
      this.inscrireProcess(item);
    }
  }
  inscrireProcess(item) {
    this.processService.getProcess(item.processId).subscribe((success) => {
      if (success) {
        let paramsMap: ProcessParams = {
          ad_process_id: null,
          className: null,
          record_id: null,
          tableName: null,
          tables: null,
          params: null,
          windowCtx: null,
          ad_tab_id: null
        };
        const classname = success.process.ClassName;
        const AD_Process_ID = success.process.AD_Process_ID;
        let rowSelected = false;
        if (this.dataStore.data.selections) {
          this.dataStore.data.selections.forEach((row) => {
            if (row.selection.length > 0) {
              rowSelected = true;
              return;
            }
          });
        }
        if (!rowSelected) {
          const tableName = this.isModal
            ? this.parentComponent.editTabs
              ? this.parentComponent.editTabs[0].data.TableName
              : null
            : null;
          if (this.isModal) {
            paramsMap = {
              ad_process_id: AD_Process_ID,
              params: {},
              className: classname,
              record_id: this.dataStore.data['Data_UUID'].split(',')[1],
              tableName: tableName, // added in aim to test the process , it wrong
              tables: [],
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };
          } else {
            paramsMap = {
              record_id: null,
              ad_process_id: AD_Process_ID,
              params: {},
              className: classname,
              tableName: tableName,
              tables: [],
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };
          }
          this.filters.forEach((filter) => {
            Object.keys(this.dataStore.data).forEach((key) => {
              if (filter === key) {
                if (this.dataStore.data[filter] !== undefined && this.dataStore.data[filter] !== null) {
                  paramsMap.params[filter] = this.dataStore.data[filter].id;
                } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                  paramsMap.params[filter] = null;
                }
              }
            });
          });
          this.otherParams.forEach((filter) => {
            Object.keys(this.dataStore.data).forEach((key) => {
              if (filter === key) {
                if (
                  this.dataStore.data[filter] !== undefined &&
                  this.dataStore.data[filter] !== null &&
                  this.dataStore.data[filter].id !== undefined
                ) {
                  paramsMap.params[filter] = this.dataStore.data[filter].id;
                } else if (
                  this.dataStore.data !== undefined &&
                  this.dataStore.data[filter] !== null &&
                  this.dataStore.data[filter] !== undefined
                ) {
                  paramsMap.params[filter] = this.dataStore.data[filter];
                } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                  paramsMap.params[filter] = null;
                }
              }
            });
          });
          const button = this.getDatacontainer('Inscrire');
          if (button) {
            button.isReadOnly = true;
          }
          // ajout des paramètres non affiché
          paramsMap.tables.push({
            ad_FormDetail_ID: parseInt(this.notInscribeGridId, 10),
            rows: [
              {
                columns: {
                  C_BPartner_ID: this.getFormStoreData('C_BPartner_ID')
                    ? parseInt(this.getFormStoreData('C_BPartner_ID').id, 10)
                    : -1,
                  Z_Role_Evenement_ID: this.getFormStoreData('Z_Role_Evenement_ID')
                    ? parseInt(this.getFormStoreData('Z_Role_Evenement_ID').id, 10)
                    : -1,
                  Z_Role_Evenement: this.getFormStoreData('Z_Role_Evenement_ID')
                    ? this.getFormStoreData('Z_Role_Evenement_ID').displayValue
                    : 'unknown'
                }
              }
            ]
          });
          const obs = this.uiCreator.executeProcess(paramsMap);
          this.subscriptions.push(
            obs.subscribe((instance) => {
              if (this.parentComponent !== undefined) {
                (this.parentComponent as EditViewUiComponent).isProcessLaunch = true;
              }
              this.updateLoading(true);
              this.displayCustoModal = false;
              const pg = this.ppService.watchProcessInProgress().subscribe((pings) => {
                const me = this.connectorService.getIupicsUserAccount();
                const ping = pings.find((p) => {
                  return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === AD_Process_ID;
                });
                if (ping && ping.Status === 'finish') {
                  const button = this.getDatacontainer('Inscrire');
                  if (button) {
                    button.isReadOnly = false;
                  }
                  this.updateLoading(false);
                  this.refreshGrids(this.dataStore, false);
                  this.clearGridSelection('Inscrire - Table Liste Inscrits');
                  pg.unsubscribe();
                  this.onTriggerProcess();
                }
              });
            })
          );
        } else {
          const tableName = this.isModal ? this.parentComponent.editTabs[0].data.TableName : null;
          this.tablesForm = [];
          const selections = this.dataStore.data.selections.filter(
            (gridInfo) => gridInfo.AD_FormDetail_ID === this.notInscribeGridId
          );
          for (let j = 0; j < selections.length; j++) {
            this.rows[j] = new Array(selections[j].selection.length);
            for (let i = 0; i < selections[j].selection.length; i++) {
              const splittedDataUUID = selections[j].selection[i].Data_UUID.split(',');
              const row = {
                record_ID: splittedDataUUID.length > 1 ? splittedDataUUID[1] : splittedDataUUID[0],
                columns: selections[j].selection[i]
              };
              // if (row.columns['Data_UUID']) {
              //   row.columns['Data_UUID'] = row.record_ID;
              // }
              this.rows[j][i] = row;
            }
            this.tablesForm.push({
              ad_FormDetail_ID: selections[j].AD_FormDetail_ID,
              rows: this.rows[j]
            });
          }
          if (this.isModal) {
            paramsMap = {
              ad_process_id: AD_Process_ID,
              params: {},
              className: classname,
              record_id: this.dataStore.data['Data_UUID'].split(',')[1],
              tableName: tableName, // added in aim to test the process , it wrong
              tables: this.tablesForm,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };
          } else {
            paramsMap = {
              record_id: null,
              ad_process_id: AD_Process_ID,
              params: {},
              className: classname,
              tableName: tableName,
              tables: this.tablesForm,
              windowCtx: this.getCurrentContext(),
              ad_tab_id: null
            };
          }
          this.filters.forEach((filter) => {
            Object.keys(this.dataStore.data).forEach((key) => {
              if (filter === key) {
                if (
                  this.dataStore.data[filter] !== undefined &&
                  this.dataStore.data[filter] !== null &&
                  this.dataStore.data[filter].id !== undefined
                ) {
                  paramsMap.params[filter] = this.dataStore.data[filter].id;
                } else if (
                  this.dataStore.data !== undefined &&
                  this.dataStore.data[filter] !== null &&
                  this.dataStore.data[filter] !== undefined
                ) {
                  paramsMap.params[filter] = this.dataStore.data[filter];
                } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                  paramsMap.params[filter] = null;
                }
              }
            });
          });
          this.otherParams.forEach((filter) => {
            Object.keys(this.dataStore.data).forEach((key) => {
              if (filter === key) {
                if (
                  this.dataStore.data[filter] !== undefined &&
                  this.dataStore.data[filter] !== null &&
                  this.dataStore.data[filter].id !== undefined
                ) {
                  paramsMap.params[filter] = this.dataStore.data[filter].id;
                } else if (
                  this.dataStore.data !== undefined &&
                  this.dataStore.data[filter] !== null &&
                  this.dataStore.data[filter] !== undefined
                ) {
                  paramsMap.params[filter] = this.dataStore.data[filter];
                } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                  paramsMap.params[filter] = null;
                }
              }
            });
          });
          const button = this.getDatacontainer('Inscrire');
          if (button) {
            button.isReadOnly = true;
          }
          const obs = this.uiCreator.executeProcess(paramsMap);
          this.subscriptions.push(
            obs.subscribe((instance) => {
              if (this.parentComponent !== undefined) {
                (this.parentComponent as EditViewUiComponent).isProcessLaunch = true;
              }
              this.updateLoading(true);
              this.displayCustoModal = false;
              const pg = this.ppService.watchProcessInProgress().subscribe((pings) => {
                const me = this.connectorService.getIupicsUserAccount();
                const ping = pings.find((p) => {
                  return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === AD_Process_ID;
                });
                if (ping && ping.Status === 'finish') {
                  const button = this.getDatacontainer('Inscrire');
                  if (button) {
                    button.isReadOnly = false;
                  }
                  this.updateLoading(false);
                  this.refreshGrids(this.dataStore, false);
                  this.clearGridSelection('Inscrire - Table Liste Inscrits');
                  pg.unsubscribe();
                  this.onTriggerProcess();
                }
              });
            })
          );

          this.tablesForm = [];
        }
      }
    });
  }
  onTriggerProcess() {
    let messageInfo = '';
    if ((this.mapRole, this.mapRole.size > 0)) {
      messageInfo += '<br><b>( & )</b>';
    }
    let fonctionMessageLines = '';
    let first = true;
    this.mapRole.forEach((value, key) => {
      if (!first) {
        fonctionMessageLines += ' - ';
      } else {
        first = false;
      }
      fonctionMessageLines += key + ': ' + value;
    });
    messageInfo = messageInfo.replace('&', ' ' + fonctionMessageLines);
    Global.infoDialog.message = {
      summary: this.translateService.instant('specificWindow.inscribe.processSuccess'),
      detail: this.translateService.instant('specificWindow.inscribe.processSuccessMessage') + messageInfo
    };
    Global.infoDialog.dialogType = InfoDialogType.INFO;
    Global.infoDialog.showInfoDialog();
    const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      if (this.closeModalEmitter.observers) {
        this.closeModalEmitter.emit();
      }
      confirm.unsubscribe();
    });
  }
  ngAfterViewInit() {
    // if (!this.dataStore) {
    //   this.dataStore = this.store.newSpecificWindowData(this.formId);
    // }
    // permettre aux champs liés à la valeur d'un autre de s'adapter
    this.setDataContainersValueWithChangedStore();
    if (!this.isModal) {
      Global.workspace.linkedComponentToTabMap[this.activeTab.id] = this;
    }
  }
  notifyFromDataChange(item?: any) {
    if (item.data.columnName === 'C_Project_ID' && this.getFormStoreData('C_Project_ID')) {
      const roleContainer = this.getDatacontainer('Z_Role_Evenement_ID');
      if (roleContainer) {
        const request: DataStoreRequest = {
          windowId: null,
          parent_constraint: '',
          compiereRequest: {
            startRow: 0,
            endRow: 0,
            tableName:
              // '(select z_role_evenement_id as value from z_role_evenement where c_projecttype_id =(select c_projecttype_id from c_project where c_project_id=' +
              // this.getFormStoreData('C_Project_ID').id +
              // ")  and ROWNUM<2 and isactive='Y' and ad_client_id = " +
              // (this.getFormStoreData('AD_Client_ID') ? this.getFormStoreData('AD_Client_ID').id : -1) +
              // ' order by sequence asc)'
              "(SELECT ( SELECT z_role_evenement_id FROM z_role_evenement WHERE ( lower(pt.name) = lower('ACTION') AND value = 'PARA' ) OR ( lower(pt.name) = lower('CAMPS') AND value = 'PARC' ) OR ( lower(pt.name) = lower('FORMATION') AND value = 'PARF' ) ) AS value FROM c_projecttype   pt INNER JOIN c_project       p ON pt.c_projecttype_id = p.c_projecttype_id WHERE p.c_project_id =" +
              this.getFormStoreData('C_Project_ID').id +
              ')'
          }
        };
        this.subscriptions.push(
          this.store.getDataGrid(request).subscribe((response) => {
            if (response.data.length > 0) {
              const value = response.data[0]['VALUE'];
              if (value) {
                roleContainer.updateStore(value);
              }
            }
          })
        );
      }
    } else if (item.data.isLaunchSearchGrid) {
      this.refreshGrids(this.dataStore, false, item.data);
    }
  }
}
export interface POEvenement {
  LockProject: string;
  Z_Ouvert_B: string;
  Z_Ouvert_CF: string;
  Z_Ouvert_E: string;
  Z_Ouvert_L: string;
  Z_Ouvert_P: string;
  Z_Ouvert_R: string;
  Z_Ouvert_U: string;
  M_Warehouse_ID: string;
  DateContract: string;
  Processed: string;
  DateFinish: string;
  Session_Status: string;
}
export interface ConditionMessage {
  title: string;
  text: string;
}
