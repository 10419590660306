import {
  Component,
  //#region custo
  ElementRef,
  //#endregion custo
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  //#region custo
  Renderer2,
  //#endregion custo
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { ApiService } from '@compiere-ws/services/api/api.service';
import { CalendarUiComponent } from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
//#region custo
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
//#endregion custo
import { AccountSelectorComponent } from '@login-page/components/account-selector/account-selector.component';
import { HomePageService } from '@login-page/controllers/home-page.service';
import { UserAccount } from '@login-page/models/user-account.';
//#region custo
import { TranslateService } from '@ngx-translate/core';
//#endregion custo
import { environment } from 'environments/environment';
//#region custo
import * as moment from 'moment';

//#endregion custo
@Component({
  selector: 'iu-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePageComponent implements OnInit, OnDestroy {
  //#region copyright
  officialApizWebSiteUrl = `${environment.webSiteUrl}`;
  isCusto = environment.CUST_version !== '{CUST_version}';
  version =
    this.isCusto
      ? `Desk ${environment.CUST_version} (v${environment.STD_version})`
      : `v${environment.STD_version}`;
  logoSrc = `assets/themes/iupics/img/logo-apiz.svg`;
  //logoSrc = `assets/themes/iupics/img/logo-LesScouts_Vertical_Couleur_140x196.png`;
  isDefaultLogo = this.logoSrc.endsWith('logo-apiz.svg') ?? true;
  //#endregion copyright

  login: string;
  accounts: UserAccount[];
  password: string;
  isRememberUser = true;
  defaultLanguage: CompiereLanguage;
  subscriptions: any[] = [];
  url: string;
  @ViewChild('accountsRegistered')
  accountsRegistered: AccountSelectorComponent;
  @Output()
  validateLoginEmitter: EventEmitter<any> = new EventEmitter();
  isViewAllComponent = false;
  successMessage: IupicsMessage;
  //#region custo
  /*request new pwd properties */
  displayModalGetPassword = false;
  @ViewChild('inputName', { static: false })
  inputName: InputTextUiComponent;
  @ViewChild('inputSurName', { static: false })
  inputSurName: InputTextUiComponent;
  @ViewChild('inputEmail', { static: false })
  inputEmail: InputTextUiComponent;
  @ViewChild('inputCalendar', { static: false })
  inputCalendar: CalendarUiComponent;
  missingFieldMessage = '';
  clearMessageDelay: NodeJS.Timeout;
  /*request new pwd properties end*/
  @ViewChild('inputConditions', { static: false })
  inputConditions: ElementRef;
  @ViewChild('inputCondContainer', { static: false })
  inputCondContainer: ElementRef;
  globalEnv = Global;
  displayModalCond = false;
  displayModalAccessForbidden = false;
  browserNotSupported = false;
  @ViewChild('inputLogin', { static: false }) 
  set input(element: ElementRef<HTMLInputElement>) {
    if(element) {
      element.nativeElement.focus();
    }
 }
  //#endregion custo
  constructor(
    //#region custo
    private renderer: Renderer2,
    private http: ApiService,
    private translateService: TranslateService,
    //#endregion custo
    private controller: HomePageService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: IupicsCookieService
  ) {}

  ngOnInit() {
    // Get possible url
    this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.
      this.url = params['redirect'] || '/workspace';

      if (
        this.cookieService.checkLS(LocalStorageIupics.access_token) &&
        this.cookieService.checkLS(LocalStorageIupics.crypto_key)
      ) {
        this.router.navigateByUrl(this.url);
      }
    });
    this.validateLoginEmitter.subscribe(() => {
      //#region custo
      if (this.inputConditions && this.inputConditions.nativeElement && this.inputConditions.nativeElement.checked) {
        Global.conditionsGeneralesAccepted = true;
      }
      //#endregion custo
      this.router.navigateByUrl(this.url);
    });
    // Generate Crypto Key
    this.controller.generateCryptoKey();
    // Generate Device ID
    this.controller.generateDeviceId();
    // Init Application language and select prefered language
    this.controller.initLanguages();
    // Get account registered on navigator
    this.accounts = this.controller.getAccountsSaved();
    //#region custo
    if (Global.conditionsGenerales && Global.conditionsGenerales.length > 0) {
      this.showModalCond();
    }
    if (!Global.okCadreUnite) {
      Global.okCadreUnite = true;
      this.showModalAccessForbidden();
    }
    //#endregion custo
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  confirmLogin(event: MouseEvent | KeyboardEvent) {
    event.preventDefault();
    //#region custo
    let condCheck = false;
    if (Global.conditionsGeneralesUserIdentifier) {
      if (this.accounts) {
        condCheck = Global.conditionsGeneralesUserIdentifier === this.accountsRegistered.defaultAccount.login;
      } else {
        condCheck = Global.conditionsGeneralesUserIdentifier === this.login;
      }
    }
    if (
      condCheck &&
      Global.conditionsGenerales &&
      Global.conditionsGenerales.length > 0 &&
      this.inputConditions &&
      this.inputConditions.nativeElement &&
      !this.inputConditions.nativeElement.checked
    ) {
      if (this.inputCondContainer) {
        this.renderer.addClass(this.inputCondContainer.nativeElement, 'changed');
        setTimeout(() => {
          this.renderer.removeClass(this.inputCondContainer.nativeElement, 'changed');
        }, 500);
      }
    } else {
      //#endregion custo
      if (this.accounts) {
        this.controller.verifySemiAuthentification(
          this.accountsRegistered.defaultAccount,
          this.password,
          this.validateLoginEmitter
        );
      } else {
        this.controller.verifyFullAuthentification(this.login, this.password, this.validateLoginEmitter, this.isRememberUser);
      }
    }
  }

  cleanAccounts() {
    this.accounts = undefined;
    this.controller.errorMessage = undefined;
    this.login = undefined;
    this.password = undefined;
  }

  getErrorMessage(): string {
    return this.controller.errorMessage;
  }

  viewAllComponent() {
    this.isViewAllComponent = !this.isViewAllComponent;
  }
  //#region custo
  /*request new pwd methods*/
  showModalGetPassword() {
    this.displayModalGetPassword = true;
  }
  sendRequest() {
    if (this.checkMandatoryFields()) {
      const request = new ResetPasswordRequest();
      request.name = this.inputName.fieldValue;
      request.surname = this.inputSurName.fieldValue;
      request.email = this.inputEmail.fieldValue;

      let birthDateFormated =
        moment(this.inputCalendar.fieldValue).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
        '' +
        moment(this.inputCalendar.fieldValue)
          .format('YYYY-MM-DDTHH:mm:ss.SSS')
          .substring(27, moment(this.inputCalendar.fieldValue).format('YYYY-MM-DDTHH:mm:ss.SSS').length);
      // birthDateFormated = birthDateFormated.substring(0, 26) + birthDateFormated.substring(27, birthDateFormated.length);
      request.birthDate = birthDateFormated;
      this.subscriptions.push(
        this.http.put<ResetPasswordResponse>(`${environment.domain + environment.resetPasswordRequestUrl}`, request).subscribe(
          (response) => {
            if (response.success) {
              this.successMessage = new IupicsMessage(null, response.message, 'success');
              this.clearMessageDelay = setTimeout(() => {
                this.closeSuccessMessage();
              }, 1500);
            }
          },
          (error) => {
            if (error) {
              this.successMessage = new IupicsMessage(null, "Echec de l'envoi du message.", 'error');
              this.clearMessageDelay = setTimeout(() => {
                this.closeSuccessMessage();
              }, 1500);
            }
          }
        )
      );
    }
  }
  closeSuccessMessage() {
    if (this.clearMessageDelay) {
      clearTimeout(this.clearMessageDelay);
    }
    this.successMessage = null;
  }
  validateInput(component: AbstractDataContainer, isEmail = false) {
    const mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      component &&
      component.fieldValue &&
      (component instanceof CalendarUiComponent || component.fieldValue.length > 0) &&
      (!isEmail || mailformat.test(component.fieldValue))
    ) {
      component.mandatoryCss = ' iu-field input-field ui-inputtext';
      return true;
    } else {
      component.mandatoryCss = ' iu-field-mandatory input-field ui-inputtext';
      return false;
    }
  }
  checkMandatoryFields() {
    let missingFieldMessage = '';
    if (!this.validateInput(this.inputName)) {
      missingFieldMessage += this.inputName.label;
    }
    if (!this.validateInput(this.inputSurName)) {
      missingFieldMessage += (missingFieldMessage.length > 0 ? ', ' : '') + this.inputSurName.label;
    }
    if (!this.validateInput(this.inputEmail, true)) {
      missingFieldMessage += (missingFieldMessage.length > 0 ? ', ' : '') + this.inputEmail.label;
    }
    if (!this.validateInput(this.inputCalendar)) {
      missingFieldMessage += (missingFieldMessage.length > 0 ? ', ' : '') + this.inputCalendar.label;
    }
    this.missingFieldMessage = missingFieldMessage;
    return missingFieldMessage === '';
  }
  /*request new pwd methods end*/
  showModalCond() {
    this.displayModalCond = true;
  }
  showModalAccessForbidden() {
    this.displayModalAccessForbidden = true;
  }
  checkCondDisplayed() {
    let isDisplayed = false;
    if (Global.conditionsGeneralesUserIdentifier) {
      if (this.accounts) {
        isDisplayed = Global.conditionsGeneralesUserIdentifier === this.accountsRegistered.defaultAccount.login;
      } else {
        isDisplayed = Global.conditionsGeneralesUserIdentifier === this.login;
      }
    }
    if (!isDisplayed) {
      Global.conditionsGeneralesUserIdentifier = null;
      Global.conditionsGeneralesAccepted = null;
      Global.conditionsGenerales = null;
    }
  }
  checkBrowser() {
    const userAgent = navigator.userAgent;
    const browsers = { chrome: /chrome/i, firefox: /firefox/i };
    this.browserNotSupported = true;
    for (let key in browsers) {
      if (browsers[key].test(userAgent)) {
        this.browserNotSupported = false;
      }
    }
  }
  //#endregion custo
}
//#region custo
export class ResetPasswordRequest {
  name: string;
  surname: string;
  email: string;
  birthDate: string;
}
export class ResetPasswordResponse {
  success: boolean;
  message: string;
}
//#endregion custo
