import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-passage-masse',
  templateUrl: './passage-masse.component.html',
  styleUrls: ['./passage-masse.component.scss']
})
export class PassageMasseComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('botleft', { read: ViewContainerRef, static: true })
  vcrBotLeft: ViewContainerRef;
  @ViewChild('botright', { read: ViewContainerRef, static: true })
  vcrBotRight: ViewContainerRef;

  processTransfertID = 0;
  processDesaffID = 0;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    processService: CompiereProcessService,
    protected store: DataStoreService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
  }

  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      if (specificWindow.items && specificWindow.items.length > 0) {
        const processDesaff = specificWindow.items.find((el) => {
          if (el.data && el.data.columnName === 'DesaffilierMembre') {
            return el;
          }
        });
        const processTransfert = specificWindow.items.find((el) => {
          if (el.data && el.data.columnName === 'TransferMembre') {
            return el;
          }
        });
        if (processTransfert && processTransfert.processId) {
          this.processTransfertID = processTransfert.processId;
        }
        if (processDesaff && processDesaff.processId) {
          this.processDesaffID = processDesaff.processId;
        }
      }
      this.customDesignArray.push(
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'AD_Org_ID',
          cssClass: 'ui-g-4'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'M_Warehouse_ID',
          cssClass: 'ui-g-4'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'Z_Fonction_ID',
          cssClass: 'ui-g-4'
        },
        {
          vcr: 'vcrMiddle',
          type: CustomDesignItemType.GRID,
          tableName: 'Passage en masse Table',
          cssClass: 'ui-g-12',
          isFitResize: true
        },
        {
          vcr: 'vcrBotLeft',
          type: CustomDesignItemType.FIELD,
          columnName: 'SectionCible',
          cssClass: 'ui-g-12 ui-md-3 ui-lg-3'
        },
        {
          vcr: 'vcrBotLeft',
          type: CustomDesignItemType.FIELD,
          columnName: 'FonctionCible',
          cssClass: 'ui-g-12 ui-md-3 ui-lg-3'
        },
        {
          vcr: 'vcrBotRight',
          type: CustomDesignItemType.FIELD,
          columnName: 'TransferMembre',
          cssClass: 'ui-g-6'
        },
        {
          vcr: 'vcrBotRight',
          type: CustomDesignItemType.FIELD,
          columnName: 'DesaffilierMembre',
          cssClass: 'ui-g-6'
        }
      );
    });
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'ui-g-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }

  notifyFromDataChange(item) {
    if (item.data.columnName === 'AD_Org_ID') {
      this.dataStore.data['M_Warehouse_ID'] = null;
      this.dataStore.data['Z_Fonction_ID'] = null;
      this.dataStore.data['SectionCible'] = '';
      this.dataStore.data['FonctionCible'] = '';
    }
    super.notifyFromDataChange(item);
  }

  onEndProcess() {
    this.dataStore.data['SectionCible'] = '';
    this.dataStore.data['FonctionCible'] = '';
    let container = this.getDatacontainer('TransferMembre');
    if (container) {
      container.isReadOnly = false;
    }
    container = this.getDatacontainer('DesaffilierMembre');
    if (container) {
      container.isReadOnly = false;
    }
    this.refreshGrids(this.dataStore, false);
  }

  processTransferMembre() {
    if (this.dataStore.data.selections[0].selection.length < 1) {
      // erreur selection
      this.messageManager.newMessage(new IupicsMessage('Erreur ', this.translateService.instant('passageMasse.EmptySelection')));
    } else if (!this.dataStore.data['SectionCible'] || !this.dataStore.data['FonctionCible']) {
      // erreur mandatory
      this.messageManager.newMessage(new IupicsMessage('Erreur ', this.translateService.instant('passageMasse.MandatoryError')));
    } else {
      this.dataStore.data['TransferMembre'] = 'N';
      const container = this.getDatacontainer('DesaffilierMembre');
      if (container) {
        container.isReadOnly = true;
      }
      this.executeProcess(this.processTransfertID);
    }
  }

  processDesaffilierMembre() {
    const container = this.getDatacontainer('TransferMembre');
    if (container) {
      container.isReadOnly = true;
    }
    this.dataStore.data['DesaffilierMembre'] = 'N';
    this.executeProcess(this.processDesaffID);
  }
}
