import { Injectable } from '@angular/core';
import { InscriptionCheckListRequest, InscriptionConstraints } from '@compiere-ws/models/waiting-list-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class WaitingListService {
  private url: string;
  private url2: string;
  constructor(private http: ApiService, private config: AppConfig) {
    this.url = this.config.getBackendResource('checkInscription');
    this.url2 = this.config.getBackendResource('checkInscriptionList');
  }
  public getInscriptionConstraints(projectId: number, waitingListId: number): Observable<InscriptionConstraints> {
    return this.http.get<InscriptionConstraints>(`${this.url}?projectId=${projectId}&waitingListId=${waitingListId}`);
  }
  public getInscriptionConstraintList(inscriptionCheckListRequest: InscriptionCheckListRequest): Observable<any> {
    return this.http.post<any>(this.url2, inscriptionCheckListRequest);
  }
}
