import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { CompiereDataGridSortModelType } from "@compiere-ws/models/compiere-data-json";
import { CompiereProcessService } from "@compiere-ws/services/compiere-process/compiere-process.service";
import { ProcessInProgressService } from "@compiere-ws/services/process-in-progress/process-in-progress.service";
import { SocketService } from "@compiere-ws/services/socket/socket.service";
import { CustomDesignItemType } from "@iupics-components/models/custom-design";
import { GridViewUiComponent } from "@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component";
import { DataStoreService } from "@iupics-manager/managers/data-store/data-store.service";
import { SecurityManagerService } from "@iupics-manager/managers/security-manager/security-manager.service";
import { UICreatorService } from "@iupics-manager/managers/ui-creator/ui-creator.service";
import { WindowFactoryService } from "@iupics-manager/managers/ui-creator/window-factory/window-factory.service";
import { DynamicComponent } from "@iupics-manager/models/dynamic-component";
import { Global } from "@iupics-manager/models/global-var";
import { IupicsTypeEvent } from "@iupics-manager/models/iupics-event";
import { TranslateService } from "@ngx-translate/core";
import { AutocompleteRendererComponent } from "../renderer/autocomplete-renderer.component";
import { ButtonRendererComponent } from "../renderer/button-renderer.component";
import { SpecificWindowUiComponent } from "../specific-window-ui/specific-window-ui.component";

@Component({
  selector: "iu-gps-unit",
  templateUrl: "./gps-unit.component.html",
  styleUrls: ["./gps-unit.component.scss"]
})
export class GpsUnitComponent extends SpecificWindowUiComponent
  implements OnInit {
  @ViewChild("filtersDistance", { read: ViewContainerRef, static: true })
  vcrFiltersDistance: ViewContainerRef;
  @ViewChild("filters", { read: ViewContainerRef, static: true })
  vcrFilters: ViewContainerRef;
  @ViewChild("tableParent", { read: ViewContainerRef, static: true })
  vcrTableParent: ViewContainerRef;
  @ViewChild("tableChild", { read: ViewContainerRef, static: true })
  vcrTableChild: ViewContainerRef;
  @ViewChild("actions", { read: ViewContainerRef, static: true })
  vcrActions: ViewContainerRef;

  frameworkComponents: any;
  currentFilters: string[];
  tableNames: string[];
  isUnitChange = false;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService,
    translateService: TranslateService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      pp,
      translateService
    );
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      autocompleteRenderer: AutocompleteRendererComponent
    };
    this.tableNames = [
      "Rechercher Unité/Section Table Gps Unit",
      "Rechercher Unité/Section Table Gps Unit Sections"
    ];
  }

  ngOnInit() {
    this.currentFilters = [];
    this.uiCreator.getSpecificWindow(this.formId).subscribe(specificWindow => {
      specificWindow.items.forEach(item => {
        switch (item.data.columnName) {
          case "Geo_Address":
            this.customDesignArray.push({
              vcr: "vcrFiltersDistance",
              type: CustomDesignItemType.FIELD,
              columnName: "Geo_Address",
              cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-4"
            });
            break;

          case "Geo_MaxDistance":
            this.customDesignArray.push({
              vcr: "vcrFiltersDistance",
              type: CustomDesignItemType.FIELD,
              columnName: "Geo_MaxDistance",
              cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-4"
            });
            break;

          case "Geo_Location_Lat":
            this.customDesignArray.push({
              vcr: "vcrFiltersDistance",
              type: CustomDesignItemType.FIELD,
              columnName: "Geo_Location_Lat",
              cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-4"
            });
            break;

          case "Geo_Location_Lng":
            this.customDesignArray.push({
              vcr: "vcrFiltersDistance",
              type: CustomDesignItemType.FIELD,
              columnName: "Geo_Location_Lng",
              cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-4"
            });
            break;

          case "AD_Org_Selection":
            this.customDesignArray.push({
              vcr: "vcrFilters",
              type: CustomDesignItemType.FIELD,
              columnName: "AD_Org_Selection",
              cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-4"
            });
            break;
          case "AD_Org_ID":
            this.customDesignArray.push({
              vcr: "vcrFilters",
              type: CustomDesignItemType.FIELD,
              columnName: "AD_Org_ID",
              cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-4"
            });
            break;
          case "Branch":
            this.customDesignArray.push({
              vcr: "vcrFilters",
              type: CustomDesignItemType.FIELD,
              columnName: "Branch",
              cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-4"
            });
            break;

          case "Reset":
            this.customDesignArray.push({
              vcr: "vcrActions",
              type: CustomDesignItemType.FIELD,
              columnName: "Reset",
              cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-4",
              events: {
                click: this.reset.bind(this)
              }
            });
            break;
          default:
            if (item.component === "GridViewUiComponent") {
              switch (item.name) {
                case this.tableNames[0]:
                  this.customDesignArray.push({
                    vcr: "vcrTableParent",
                    type: CustomDesignItemType.GRID,
                    tableName: item.name,
                    cssClass: "ui-g-12",
                    shouldSelectFirst: false,
                    frameworkComponents: this.frameworkComponents,
                    isFitResize: true,
                    rowSelection: "single",
                    suppressRowClickSelection: false
                  });

                  break;

                case this.tableNames[1]:
                  this.customDesignArray.push({
                    vcr: "vcrTableChild",
                    type: CustomDesignItemType.GRID,
                    tableName: item.name,
                    cssClass: Global.isMobileWidth() ? "ui-g-12" : "ui-g-9",
                    shouldSelectFirst: false,
                    frameworkComponents: this.frameworkComponents,
                    isFitResize: true,
                    rowSelection: "single",
                    suppressRowClickSelection: false
                  });

                  break;
              }
            }
            break;
        }
      });
      this.setExcludedFiltersToGrid(this.tableNames[0], ["Branch"]);
      this.setExcludedFiltersToGrid(this.tableNames[1], [
        "Geo_MaxDistance",
        "Geo_Location_Lat",
        "Geo_Location_Lng"
      ]);
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: "SpecificWindowUiComponent",
        cssClass: "ui-g-12",
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };

      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }

  notifyFromDataChange(item?: any) {
    if (item.data["columnName"] === "Geo_Address") {
      this.isUnitChange = false;
      this.dataStore.data["Branch"] = "";
      this.dataStore.data["AD_Org_Selection"] = "";
      this.dataStore.data["AD_Org_ID"] = "";
      const showTable = this.gridViews.find(
        grid =>
          (<GridViewUiComponent>grid).data["tableName"] === this.tableNames[1]
      );
      this.refreshGrids(this.dataStore, true, null, showTable.tabId);
    }
    if (
      item.data["columnName"] === "AD_Org_Selection" &&
      this.dataStore.data["AD_Org_Selection"]
    ) {
      this.isUnitChange = true;
      // const checkLocalisationRequest: DataStoreRequest = {
      //   windowId: null,
      //   parent_constraint: '',
      //   compiereRequest: {
      //     startRow: 0,
      //     endRow: 0,
      //     tableName: 'GPS_UNIT_V',
      //     filterModel: {
      //       AD_ORG_ID: {
      //         filterType: CompiereDataGridFilterType.SET,
      //         values: [this.dataStore.data['AD_Org_Selection'].id],
      //         operators: [OperatorFilterType.EQUALS]
      //       }
      //     }
      //   }
      // };
      // this.store.getDataGrid(checkLocalisationRequest).subscribe(res => {
      // if (res && res.data && res.data.length > 0) {
      this.dataStore.data["AD_Org_ID"] = this.dataStore.data[
        "AD_Org_Selection"
      ];
      // this.dataStore.data['Geo_Location_Lat'] = res.data[0]['GEO_LOCATION_LAT'];
      // this.dataStore.data['Geo_Location_Lng'] = res.data[0]['GEO_LOCATION_LNG'];
      // this.currentFilters = ['Geo_Location_Lat', 'Geo_Location_Lng', 'Geo_MaxDistance'];
      this.currentFilters = ["AD_Org_ID"];
      this.refreshTable(this.tableNames[0]);
      this.refreshTable(this.tableNames[1]);
      this.clearAllGridsSelection();
      // }
      // });
    } else if (
      item.data["columnName"] === "Branch" &&
      this.dataStore.data["AD_Org_Selection"]
    ) {
      this.dataStore.data["AD_Org_ID"] = this.dataStore.data[
        "AD_Org_Selection"
      ];
      this.currentFilters = ["AD_Org_ID", "Branch"];
      this.refreshTable(this.tableNames[1]);
    } else if (
      (item.data["columnName"] === "Geo_Address" ||
        (item.data["columnName"] === "Geo_MaxDistance" &&
          this.dataStore.data["Geo_Address"] != null &&
          this.dataStore.data["Geo_Address"] !== "")) &&
      (this.dataStore.data["AD_Org_ID"] == null ||
        this.dataStore.data["AD_Org_ID"] === "" ||
        this.dataStore.data["AD_Org_ID"] === "0")
    ) {
      this.isUnitChange = false;
      const geocode = new google.maps.Geocoder();
      geocode.geocode(
        { address: this.dataStore.data["Geo_Address"] },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.dataStore.data[
              "Geo_Location_Lat"
            ] = results[0].geometry.location.lat();
            this.dataStore.data[
              "Geo_Location_Lng"
            ] = results[0].geometry.location.lng();
            if (this.dataStore.dataChange) {
              const dataModified = {
                Geo_Location_Lat: results[0].geometry.location.lat(),
                Geo_Location_Lng: results[0].geometry.location.lng()
              };
              this.dataStore.dataChange.emit({
                dataModified: dataModified,
                bypassValidation: false,
                isFromCallout: true
              });
            }
            this.refreshTable(this.tableNames[0]);
          } else {
            this.dataStore.data["Geo_Location_Lat"] = 0;
            this.dataStore.data["Geo_Location_Lng"] = 0;
            if (this.dataStore.dataChange) {
              const dataModified = {
                Geo_Location_Lat: 0,
                Geo_Location_Lng: 0
              };
              this.dataStore.dataChange.emit({
                dataModified: dataModified,
                bypassValidation: false,
                isFromCallout: true
              });
            }
            this.clearAllGridsSelection();
            this.refreshGrids(this.dataStore, true);
          }
        }
      );
    } else if (
      item.data["columnName"] === "Geo_MaxDistance" &&
      this.dataStore.data["AD_Org_Selection"]
    ) {
      this.isUnitChange = false;
      this.currentFilters = [
        "Geo_Location_Lat",
        "Geo_Location_Lng",
        "Geo_MaxDistance"
      ];
      this.refreshTable(this.tableNames[0]);
      this.clearAllGridsSelection();
    }
  }

  notifyFromRowSelected(rowSelected: any) {
    if (rowSelected.length > 0 && "AD_ORG_VALUE" in rowSelected[0]) {
      this.dataStore.data["AD_Org_ID"] = {
        id: rowSelected[0]["AD_Org_ID"],
        displayValue: rowSelected[0]["AD_ORG_VALUE"]
      };
      if (this.dataStore.dataChange) {
        this.currentFilters = ["AD_Org_ID", "Branch"];
        this.setDatacontainerValue(
          "AD_Org_ID",
          this.dataStore.data["AD_Org_ID"]
        );
      }
      const showTable = this.gridViews.find(
        grid =>
          (<GridViewUiComponent>grid).data["tableName"] === this.tableNames[1]
      );
      this.refreshGrids(this.dataStore, false, null, showTable.tabId);
    } else if (rowSelected.length > 0 && "M_Warehouse_ID" in rowSelected[0]) {
      Global.workspace.openTargetSearch({
        cat: { id: 139 },
        source: { id: "M_Warehouse_ID," + rowSelected[0]["M_Warehouse_ID"] }
      });
    } else if (
      this.dataStore.data.selections[0].selection.length === 0 &&
      !this.isUnitChange
    ) {
      const showTable = this.gridViews.find(
        grid =>
          (<GridViewUiComponent>grid).data["tableName"] === this.tableNames[1]
      );
      this.refreshGrids(this.dataStore, true, null, showTable.tabId);
    }
  }

  refreshTable(tableName: string) {
    const showTable = this.gridViews.find(
      grid => (<GridViewUiComponent>grid).data["tableName"] === tableName
    );
    if (showTable) {
      const filter = showTable.filter || { filterModel: {}, sortModel: [] };
      if (
        filter.sortModel &&
        !filter.sortModel.find(sm => sm.colId === "Geo_Distance")
      ) {
        filter.sortModel.push({
          colId: "Geo_Distance",
          sort: CompiereDataGridSortModelType.ASC
        });
        showTable.updateFilterEmitter.emit(filter);
      }
      this.refreshGrids(this.dataStore, false, null, showTable.tabId);
    }
  }

  reset() {
    this.dataStore.data["Geo_Address"] = "";
    this.dataStore.data["Geo_Location_Lat"] = 0;
    this.dataStore.data["Geo_Location_Lng"] = 0;
    this.dataStore.data["Geo_MaxDistance"] = 10;
    this.dataStore.data["AD_Org_Selection"] = "";
    this.dataStore.data["AD_Org_ID"] = "";
    this.dataStore.data["Branch"] = "";
    this.dataStore.data[this.tableNames[0]] = [];
    this.dataStore.data[this.tableNames[1]] = [];
    this.dataContainers.forEach(datacontainer => {
      datacontainer.setNewData(this.dataStore);
    });
    this.clearAllGridsSelection();
    this.refreshGrids(this.dataStore, true);
  }
}
