<p-toolbar *ngIf="!isMobile">
  <div class="ui-right">
    <!-- #region custo-->
    <!-- <button
      pButton
      tooltipPosition="left"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.email' | translate }}"
      type="button"
      class="ui-menu-bar-button"
      icon="icon-ico-mail-ok"
      *ngIf="mailBtnCondition"
      (mouseup)="emailEvent && $event.button === 0 ? emailEvent.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.email"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-email"
    ></button>
    <button
      pButton
      tooltipPosition="left"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.joinFiles' | translate }}"
      type="button"
      class="ui-menu-bar-button"
      icon="icon-ico-attach3x"
      *ngIf="joinFileBtnCondition"
      (mouseup)="joinFilesEvent && $event.button === 0 ? joinFilesEvent.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.join"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-attachFile"
    >
      <span *ngIf="nbUploadedFiles !== undefined && nbUploadedFiles > 0" class="iupics-badge iupics-badge-count">{{
        nbUploadedFiles > 9 ? '9+' : nbUploadedFiles
      }}</span>
    </button> -->
    <!-- #endregion custo-->
    <button
      pButton
      tooltipPosition="left"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.export' | translate }}"
      type="button"
      class="ui-menu-bar-button"
      icon="icon-ico-export-ok"
      [disabled]="!exportBtnCondition"
      (mouseup)="exportDataEvent && $event.button === 0 ? exportDataEvent.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.export"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-export"
    ></button>
    <button
      pButton
      tooltipPosition="left"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.print' | translate }}"
      type="button"
      class="ui-menu-bar-button"
      icon="icon-ico-print"
      [disabled]="!printBtnCondition"
      (mouseup)="printEvent && $event.button === 0 ? printEvent.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.print"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-print"
    ></button>
    <button
      pButton
      type="button"
      icon="icon-ico-close"
      class="ui-menu-bar-button"
      (mouseup)="$event.button === 0 ? closeEvent.emit($event) : ''"
      pTooltip="{{ 'menuBarDetails.close' | translate }}"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.close"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-closeBlade"
    ></button>
  </div>
  <div class="ui-left">
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.zoom' | translate }}"
      type="button"
      class="ui-menu-bar-button"
      icon="icon-ico-table"
      *ngIf="isZoomTarget"
      (mouseup)="changeGridViewVisibility && $event.button === 0 ? changeGridViewVisibility.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.zoom"
      [iuKeybinding-focusTab]="activeTabID"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.add' | translate }}"
      class="ui-menu-bar-button"
      type="button"
      icon="icon-ico-add-2"
      [disabled]="!newBtnCondition"
      (mouseup)="newEvent && $event.button === 0 ? newEvent.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.new"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-newRecord"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.save' | translate }}"
      type="button"
      class="ui-menu-bar-button"
      icon="icon-ico-save3x"
      [disabled]="!saveBtnCondition"
      (mouseup)="saveEvent && $event.button === 0 ? saveEvent.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.save"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-saveRecord"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.refresh' | translate }}"
      type="button"
      class="ui-menu-bar-button"
      icon="icon-ico-refresh3x"
      [disabled]="!refreshBtnCondition"
      (mouseup)="refreshEvent && $event.button === 0 ? refreshEvent.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.refresh"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-refresh"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.delete' | translate }}"
      type="button"
      class="ui-menu-bar-button"
      icon="icon-ico-delete"
      [disabled]="!deleteBtnCondition"
      (mouseup)="deleteEvent && $event.button === 0 ? deleteEvent.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.delete"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-delete"
    ></button>
    <button
      pButton
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.copy' | translate }}"
      type="button"
      class="ui-menu-bar-button"
      icon="icon-ico-copy"
      [disabled]="!copyBtnCondition"
      (mouseup)="copyEvent && $event.button === 0 ? copyEvent.emit($event) : ''"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      iuKeybinding-code="menu-bar-detail.copy"
      [iuKeybinding-focusTab]="activeTabID"
      data-cy="button-copy"
    ></button>
  </div>
  <div class="ui-center">
    <span
    *ngIf="!isZoomEditView && !isZoomTarget && arrowsDisplayed"
    (mouseup)="$event.button === 0 ? changeGridElementEvent.emit(-1) : ''"
      class="arrow back-arrow icon-ico-back"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-shift]="false"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      [iuKeybinding-code]="'menu-bar-detail.back'"
      [iuKeybinding-focusTab]="activeTabID"
    ></span>
    <span
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ viewRecordChangeLogLabel }}"
      class="tabTitle"
      data-cy="show-changelog"
      (mouseup)="$event.button === 0 ? viewRecordChangeLog.emit($event) : ''"
      >{{ viewRecordChangeLogLabel | slice: 0:35 }}</span
    ><span class="newSymbol">{{ this.status === 'sync' ? '' : ' *' }}</span>
    <span
    *ngIf="!isZoomEditView && !isZoomTarget && arrowsDisplayed"
    (mouseup)="$event.button === 0 ? changeGridElementEvent.emit(1) : ''"
      class="arrow next-arrow icon-ico-next"
      iuKeybinding
      iuKeybinding-event-mode="mouseup"
      [iuKeybinding-shift]="false"
      [iuKeybinding-active]="activateKeybind && !isSplitView"
      [iuKeybinding-code]="'menu-bar-detail.next'"
      [iuKeybinding-focusTab]="activeTabID"
    ></span>
  </div>
</p-toolbar>

<p-toolbar *ngIf="isMobile">
  <div class="ui-right">
    <button
      pButton
      data-cy="button-closeBlade"
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="menuBarDetails.close | translate"
      type="button"
      icon="icon-ico-close"
      class="ui-menu-bar-button"
      (mouseup)="closeEvent.emit($event)"
    ></button>
  </div>
  <div class="ui-left">
    <button
      pButton
      data-cy="menuBar-showButtons-btn"
      tooltipPosition="top"
      showDelay="250"
      life="3"
      pTooltip="{{ 'menuBarDetails.showButtons' | translate }}"
      type="button"
      icon="icon-ico-menu-bars"
      class="ui-menu-bar-button"
      [attr.data-cy-toggle-state]="showMoreAction ? 'opened' : 'closed'"
      (mouseup)="showMoreActionEmitter.emit($event)"
    ></button>
  </div>
  <div class="ui-center">
    <span
      tooltipPosition="top"
      life="3"
      pTooltip="{{ viewRecordChangeLogLabel }}"
      (mouseup)="viewRecordChangeLog.emit($event)"
      class="tabTitle"
      data-cy="show-changelog"
      >{{ viewRecordChangeLogLabel }}</span
    ><span class="newSymbol">{{ this.status === 'sync' ? '' : ' *' }}</span>
  </div>
</p-toolbar>

<div class="more-action" *ngIf="showMoreAction">
  <button
    pButton
    type="button"
    class="ui-menu-bar-button"
    icon="icon-ico-table"
    *ngIf="isZoomTarget"
    (mouseup)="changeGridViewVisibility ? changeGridViewVisibility.emit($event) : ''"
  ></button>
  <button
    pButton
    class="ui-menu-bar-button"
    type="button"
    icon="icon-ico-add-2"
    [disabled]="!newBtnCondition"
    (mouseup)="newEvent ? newEvent.emit($event) : ''"
    data-cy="button-newRecord"
  ></button>
  <button
    pButton
    type="button"
    class="ui-menu-bar-button"
    icon="icon-ico-save3x"
    [disabled]="!saveBtnCondition"
    (mouseup)="saveEvent ? saveEvent.emit($event) : ''"
    data-cy="button-saveRecord"
  ></button>
  <button
    pButton
    type="button"
    class="ui-menu-bar-button"
    icon="icon-ico-refresh3x"
    [disabled]="!refreshBtnCondition"
    (mouseup)="refreshEvent ? refreshEvent.emit($event) : ''"
    data-cy="button-refresh"
  ></button>
  <button
    pButton
    type="button"
    class="ui-menu-bar-button"
    icon="icon-ico-delete"
    [disabled]="!deleteBtnCondition"
    (mouseup)="deleteEvent ? deleteEvent.emit($event) : ''"
    data-cy="button-delete"
  ></button>
  <button
    pButton
    type="button"
    class="ui-menu-bar-button"
    icon="icon-ico-copy"
    [disabled]="!copyBtnCondition"
    (mouseup)="copyEvent ? copyEvent.emit($event) : ''"
    data-cy="button-copy"
  ></button>
  <button
    pButton
    type="button"
    class="ui-menu-bar-button"
    icon="icon-ico-mail-ok"
    [disabled]="!mailBtnCondition"
    (mouseup)="emailEvent ? emailEvent.emit($event) : ''"
    data-cy="button-email"
  ></button>
  <button
    pButton
    type="button"
    class="ui-menu-bar-button"
    icon="icon-ico-attach3x"
    [disabled]="!joinFileBtnCondition"
    (mouseup)="joinFilesEvent ? joinFilesEvent.emit($event) : ''"
    data-cy="button-attachFile"
  >
    <span *ngIf="nbUploadedFiles !== undefined && nbUploadedFiles > 0" class="iupics-badge iupics-badge-count">{{
      nbUploadedFiles > 9 ? '9+' : nbUploadedFiles
    }}</span>
  </button>
  <button
    pButton
    type="button"
    class="ui-menu-bar-button"
    icon="icon-ico-export-ok"
    [disabled]="!exportBtnCondition"
    (mouseup)="exportDataEvent ? exportDataEvent.emit($event) : ''"
    data-cy="button-export"
  ></button>
  <button
    pButton
    type="button"
    class="ui-menu-bar-button"
    icon="icon-ico-print"
    [disabled]="!printBtnCondition"
    (mouseup)="printEvent ? printEvent.emit($event) : ''"
    data-cy="button-print"
  ></button>
  <button pButton type="button" class="ui-menu-bar-button" icon="icon-ico-close" (mouseup)="closeEvent.emit($event)"></button>
</div>
