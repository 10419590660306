import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { WaitingListService } from '@compiere-ws/services/waiting-list/waiting-list.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { InfoDialogComponent } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import { AutocompleteRendererComponent } from '../renderer/autocomplete-renderer.component';

@Component({
  selector: 'iu-inscribe-waiting-list',
  templateUrl: './inscribe-waiting-list.component.html',
  styleUrls: ['./inscribe-waiting-list.component.scss']
})
export class InscribeWaitingListComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('topAdvancedSearch', { read: ViewContainerRef, static: true })
  vcrTopAdvanced: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;
  @ViewChild('topProject', { read: ViewContainerRef, static: true })
  vcrTopProject: ViewContainerRef;
  @ViewChild('topButton', { read: ViewContainerRef, static: true })
  vcrTopButton: ViewContainerRef;
  @ViewChild(InfoDialogComponent, { static: true })
  infoDialog: InfoDialogComponent;
  selectedRows: any[] = [];
  hiddenColumns = ['C_Project_ID'];
  frameworkComponents: any;
  processId = 0;

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    private uiCreatorService: UICreatorService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    private waitingListService: WaitingListService,
    pp: ProcessInProgressService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
    this.isModal = false;
    this.frameworkComponents = {
      autocompleteRenderer: AutocompleteRendererComponent
    };
  }

  ngOnInit() {
    this.uiCreatorService.getSpecificWindow(this.formId).subscribe(specificWindow => {
      specificWindow.items.forEach(item => {
        if (item !== undefined) {
          if (item.component === 'GridViewUiComponent') {
            item.data.hasCheckbox = true;
            const editorColumns = [];
            const colAction = item.data.columnsTableHeader.find(function(element) {
              return element.field === 'Action';
            });
            if (colAction) {
              const actionDetail = item.data.columnsDetails.get('Action');
              editorColumns.push('Action');
              colAction.editable = false;
              colAction.cellRenderer = 'autocompleteRenderer';
              colAction.cellRendererParams = {
                onClick: this.onBtnClick1.bind(this),
                onSearch: this.onSearch.bind(this),
                closePanel: this.closePanel.bind(this),
                label: '',
                templates: {
                  items: null,
                  columnName: 'Action',
                  urlList: actionDetail.field.urlList,
                  urlSearch: actionDetail.field.urlSearch,
                  isSearch: true,
                  columnId: actionDetail.field.field.AD_Column_ID,
                  fieldTypeForm: true
                }
              };
            }
            this.customDesignArray.push({
              vcr: 'vcrBot',
              type: CustomDesignItemType.GRID,
              tableName: item.name,
              cssClass: item.cssClass,
              shouldSelectFirst: false,
              hiddenColumns: this.hiddenColumns,
              frameworkComponents: this.frameworkComponents,
              isFitResize: true
            });
          } else if (item.component === 'ButtonUiComponent') {
            this.processId = item.processId;
            this.customDesignArray.push({
              vcr: 'vcrTopButton',
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: 'ui-g-12 ui-md-3 ui-lg-3'
            });
          } else if (item.data.columnName === 'C_Project_ID' || item.data.columnName === 'advancedSearch') {
            this.customDesignArray.push({
              vcr: 'vcrTop',
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: 'ui-g-12 ui-md-3 ui-lg-3'
            });
          } else if (item.data.columnName === 'XX_Project_ID') {
            this.customDesignArray.push({
              vcr: 'vcrTopProject',
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: 'ui-g-12 ui-md-3 ui-lg-3'
            });
          } else {
            this.customDesignArray.push({
              vcr: 'vcrTopAdvanced',
              type: CustomDesignItemType.FIELD,
              columnName: item.data.columnName,
              cssClass: 'ui-g-12 ui-md-3 ui-lg-3'
            });
          }
        }
      });

      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'ui-g-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };

      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.gridViews[0]) {
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.gridOptions.singleClickEdit = true;
    }
  }
  notifyFromDataChange(item) {
    super.notifyFromDataChange(item);
  }

  checkBeforeProcessing() {
    const selectedRows = new Array();
    const selectedNodes = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows();
    this.dataStore.data.LaunchInscription = 'Y';
    if (this.dataStore.data.selections[0].selection.length !== 0) {
      this.dataStore.data.selections[0].selection.splice(0, this.dataStore.data.selections[0].selection.length);
    }
    if (selectedNodes.length > 0) {
      selectedNodes.forEach(node => {
        selectedRows.push(node);
      });
      this.dataStore.data.selections[0].selection = selectedRows;
    }
    if (this.dataStore.data.XX_Project_ID === null) {
      this.messageManager.newMessage(new IupicsMessage(this.translateService.instant('inscribeWaitingList.event'), 'error'));
    } else if (this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().length === 0) {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('inscribeWaitingList.rowSelected'), 'error')
      );
    } else {
      const inscriptionRow = [];
      selectedRows.forEach(selectedrow => {
        if (selectedrow.Action === 'B') {
          inscriptionRow.push(selectedrow);
        }
      });
      if (inscriptionRow.length > 0) {
        this.inscribeParticipant(inscriptionRow);
        return false;
      } else {
        return true;
      }
    }
  }

  inscribeParticipant(rows: any) {
    if (rows.length > 0 && this.dataStore.data.LaunchInscription === 'Y') {
      this.subscriptions.push(
        this.waitingListService
          .getInscriptionConstraints(this.dataStore.data.XX_Project_ID.id, rows[0].Z_Waiting_List_ID)
          .subscribe(data => {
            const constraints = data.constraintChecks;
            this.dataStore.data.hasError = data.hasError;
            this.dataStore.data.hasGeneralError = data.hasGeneralError;
            this.dataStore.data.forceAffiliation = data.forceAffiliation;

            constraints.sort(function(a, b) {
              return a.order - b.order;
            });
            this.checkPopup(data, constraints, rows);
          })
      );
    }

    if (rows.length === 0) {
      this.executeProcess(this.processId);
    }
  }
  checkPopup(checkPopups: any, checkConstraints: any, rows: any) {
    if (checkConstraints.length > 0 && checkConstraints[0]) {
      if (checkConstraints[0].isConfirm) {
        setTimeout(() => {
          this.openInfoDialog(checkConstraints[0].popupTitle, checkConstraints[0].popupMessage);
          const confirm = Global.infoDialog.confirm.subscribe(event => {
            checkConstraints.shift();
            this.checkPopup(checkPopups, checkConstraints, rows);
            cancel.unsubscribe();
            confirm.unsubscribe();
          });
          const cancel = Global.infoDialog.cancel.subscribe(event => {
            this.closeInfoDialog();
            cancel.unsubscribe();
            confirm.unsubscribe();
          });
        }, 0);
      } else {
        this.messageManager.newMessage(
          new IupicsMessage(checkConstraints[0].popupTitle + ' : ' + checkConstraints[0].popupMessage, 'error')
        );
        checkConstraints.shift();
        this.checkPopup(checkPopups, checkConstraints, rows);
      }
    }
    if (
      checkConstraints.length === 0 &&
      (checkPopups.stopInscription || (!checkPopups.forceInscription && !checkPopups.forceAffiliation))
    ) {
      this.dataStore.data.LaunchInscription = 'N';
    }
    if (checkConstraints.length === 0) {
      rows.shift();
      this.inscribeParticipant(rows);
    }
  }

  openInfoDialog(summary: any, detail: any) {
    Global.infoDialog = this.infoDialog;
    Global.infoDialog.message = {
      summary: summary,
      detail: detail
    };
    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
  }

  closeInfoDialog() {
    this.dataStore.data.checkInscription = 'N';
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().forEach(SelectedRow => {
      SelectedRow.Action = null;
    });
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.refreshCells();
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.deselectAll();
    this.dataStore.data.selections[0].selection.splice(0, this.dataStore.data.selections[0].selection.length);
  }

  onBtnClick1(e) {}

  onSearch(e) {
    this.gridViews[0].overlayVisible = e.overlayVisible;
    this.gridViews[0].label = e.label;
    this.gridViews[0].isLabelDisplay = e.isLabelDisplay;
    this.gridViews[0].scrollHeight = e.scrollHeight;
    this.gridViews[0].suggestions = e.suggestions;
    this.gridViews[0].field = e.field;
    this.gridViews[0].itemTemplate = e.itemTemplate;
    this.gridViews[0].noResults = e.noResults;
    this.gridViews[0].emptyMessage = e.emptyMessage;
    this.gridViews[0].setPosition(e.top, e.left, e.width);
    this.gridViews[0].linkAutocompleteRenderer = e.linkAutocomplete;
  }
  closePanel() {
    this.gridViews[0].overlayVisible = false;
  }
}
