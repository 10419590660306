import { AfterViewInit, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { CompiereDataFieldType, DataStore, DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { IupicsComponentType, IupicsContextMenuComponent } from '@web-desktop/models/iupics-context-menu';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DynamicComponent } from './dynamic-component';
import { Global } from './global-var';

export abstract class AbstractDataContainer
  extends AbstractDynamicComponent
  implements OnInit, OnDestroy, IupicsContextMenuComponent, AfterViewInit {
  @Input()
  tooltip: string;
  @Input()
  label: string;
  @Input()
  isReadOnly: boolean;
  @Input()
  isStandalone = false;
  @Input()
  displayImage = false;
  @Input()
  isContextMenuDisabled = false;
  @Input()
  locale: string;
  @Output()
  fieldValueModified: EventEmitter<DataStore> = new EventEmitter();
  @Output()
  afterViewInitEmitter: EventEmitter<DataStore> = new EventEmitter();
  @Input()
  isLabelDisplay = true;
  @Input() isInUniversalFilter = false;
  displayCss: string;
  mandatoryCss: string;
  backgroundColorCss = 'transparent';
  isDateField = false;
  isSwitchField = false;
  isAutocompleteField = false;
  isMoneyField = false;
  isImageField = false;
  isAddressField = false;
  currency = '';
  componentType = null; // IupicsComponentType.FIELDCALLOUT;
  showValuePrefPanel = false;
  private _dataStored: DataStore;
  get dataStored(): DataStore {
    return this._dataStored;
  }
  set dataStored(dataStored: DataStore) {
    this._dataStored = dataStored;
    if (dataStored) {
      if (this.newRecordKey !== dataStored.key) {
        this.newRecordKey = dataStored.key;
        this.isSetDefaultValue = false;
      }
    }
    /*mettre à jour le datastore */
  }
  dataChanged$;
  dataConflicts: any;
  initContextFieldSub: any;
  isSetDefaultValue = false;
  newRecordKey;
  defaultValue;
  logicContextField: LogicContext = new LogicContext();
  isContextMenu = false;
  isZoom = false;
  isAccordion = false;
  itemData: DynamicComponent;
  hasConflict = false;
  conflictedData: any;
  fieldType: CompiereDataFieldType = CompiereDataFieldType.FIELD;
  editViewParent: EditViewUiComponent;

  cache: { currency: any } = {
    currency: {}
  };

  //#region custo
  camp_ete_first_tab_id: number;
  //#endregion custo
  private dv$ = new Subject<any>();
  protected afterDVInit$ = new Subject<any>();
  isForInfoWindow = false;
  changeFieldValueSub: Subscription;
  constructor(
    public elementRef: ElementRef,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public store: DataStoreService,
    public uiCreatorService: UICreatorService,
    protected renderer: Renderer2,
    protected po: PoService
  ) {
    super();
  }

  ngOnInit() {
    //#region custo
    this.camp_ete_first_tab_id = Global.camp_ete_first_tab_id;
    //#endregion custo
    /*Permet de notifier au parent qu'il a un nouveau datacontainer en tant qu'enfant et de récupérer */
    if (this.DOMParentComponent) {
      let parent = this.DOMParentComponent;
      while (parent && !(parent instanceof EditViewUiComponent)) {
        parent = parent.DOMParentComponent;
      }
      if (parent instanceof EditViewUiComponent) {
        this.editViewParent = parent;
      }
    }
    if (this.parentTab) {
      this.setNewData(this.parentTab.dataStored);
      this.parentTab.addDataContainerToEditTab(this);
    }
    if (this.parentProcess) {
      this.container = this.parentProcess;

      this.setNewData(this.parentProcess.dataStore);

      this.parentProcess.addDataContainerToEditTab(this);
    }
    if (this.data && this.data.label) {
      this.label = this.data.label;
    }
    if (this.data && this.data.description) {
      this.tooltip = this.data.description
        .trim()
        .replace(/^\bnull\b/i, '')
        .trim();
    }
    if (this.data && this.data.isLabelDisplay !== undefined && this.data.isLabelDisplay !== null) {
      this.isLabelDisplay = this.data.isLabelDisplay;
    }
    // set old parsed
    this.initContextField();
    // && !this.data.IsParam
    if (this.data && this.data.IsParam && this.itemData && this.itemData.formId) {
      this.componentType = IupicsComponentType.FIELDFORM;
    } else if (this.data && (!this.isAutocompleteField || !this.data.detailZoom)) {
      if (
        this.connectorService.getIupicsUserAccount() &&
        (this.connectorService.getIupicsUserAccount().current_role.calloutLevel === 'None' ||
          this.connectorService.getIupicsUserAccount().current_role.calloutLevel === '')
      ) {
        this.componentType = null;
        if (this.isAddressField) {
          this.componentType = IupicsComponentType.ADDRESSFIELD;
        }
      } else {
        if (this.isAddressField) {
          this.componentType = IupicsComponentType.ADDRESSFIELD;
        }
      }
    } else {
      if (
        this.connectorService.getIupicsUserAccount() &&
        (this.connectorService.getIupicsUserAccount().current_role.calloutLevel === 'None' ||
          this.connectorService.getIupicsUserAccount().current_role.calloutLevel === '')
      ) {
        this.componentType = IupicsComponentType.FIELDZOOM;
      } else {
        // this.componentType = IupicsComponentType.FIELD;
        this.componentType = IupicsComponentType.FIELDZOOM;
      }
    }

    // Alimentation de la locale pour les champs
    if (!this.locale) {
      this.locale = this.connectorService.getIupicsDefaultLanguage().iso_code.replace(/_/g, '-');
    } else {
      this.locale = this.locale.replace(/_/g, '-');
    }
  }

  ngOnDestroy() {
    if (this.dataChanged$ && this.dataStored) {
      this.dataChanged$.unsubscribe();

      this.dataConflicts.unsubscribe();

      this.initContextFieldSub.unsubscribe();
    }
  }

  onMouseDown(event: MouseEvent) {
    if (event.buttons === 2) {
      this.isContextMenu = true;
    }
    event.stopPropagation();
  }

  onContextMenu(event: MouseEvent) {
    if (!this.isContextMenuDisabled) {
      if (!this.isZoom) {
        if (this.componentType) {
          this.cmService.showContextMenu(this, this.handleEvent.bind(this), event);
        }
      }
    }
    event.preventDefault();
  }

  handleEvent(event: any) {
    switch (event.item.id) {
      case 'valuePreference':
        this.showValuePreferencePanel();
        break;
      case 'zoom':
        this.zoomAcross();
        break;
      case 'refresh':
        this.search(event, true);
        break;
      case 'new':
        this.showFormPanel(this.itemData['formId']);
        break;
    }
  }
  search(event, forceRefresh = false) {}
  zoomAcross() {}
  showFormPanel(formId) {}
  showValuePreferencePanel() {
    this.showValuePrefPanel = true;
  }
  isFieldDisplay(dataStored: DataStore, changedColumns?: any): Observable<boolean> {
    if (this.data.DisplayLogic) {
      const dataMapToTest = this.getCurrentContext(dataStored, false);
      const hasContextVariable = this.hasContextLogicVariable(LogicContextProperty.DISPLAY);
      let hasContextChanged = true;
      if (hasContextVariable && changedColumns) {
        hasContextChanged = this.verifyContextLogic(Object.keys(changedColumns), LogicContextProperty.DISPLAY);
      }
      if (hasContextChanged || this.getCurrentLogicContextValue(LogicContextProperty.DISPLAY) === undefined) {
        if (this.data.DisplayLogic && this.data.DisplayLogic.trim().toLowerCase().startsWith('@sql=')) {
          return this.uiCreatorService
            .getDBSelect(
              LogicEvaluator.replaceVariables(
                this.data.DisplayLogic.slice(5),
                this.connectorService.getIupicsUserContext(),
                this.getCurrentContext(dataStored, false)
              ),
              [],
              []
            )
            .pipe(
              map((data) => {
                return data.length > 0;
              })
            );
        } else {
          return of(LogicEvaluator.evaluateLogic(dataMapToTest, this.data.DisplayLogic));
        }
      } else {
        return of(this.getCurrentLogicContextValue(LogicContextProperty.DISPLAY));
      }
    } else {
      return of(true);
    }
  }

  isFieldReadOnly(dataStored: DataStore, changedColumns: any): boolean {
    if (dataStored) {
      //#region custo
      if (this.readonlyCustoCampEte(dataStored)) {
        return true;
      }
      //#endregion custo
      // * utilisé pour debug les problèmes en read-only
      if (
        (this.parentProcess === undefined || this.parentProcess === null) &&
        (this.container === undefined || this.container.formId === undefined)
      ) {
        // * quand ce n'est pas un process
        if (
          this.data.columnName === 'Posted' ||
          (this.data.columnName === 'Record_ID' && this.data.componentName === 'ButtonUiComponent')
        ) {
          return false;
        }
        if (
          this.data.isAlwaysUpdatable !== undefined &&
          this.data.isAlwaysUpdatable !== null &&
          this.data.isAlwaysUpdatable === true
        ) {
          return false;
        }
        if (
          this.data.columnName === 'Processing' ||
          this.data.columnName === 'DocAction' ||
          this.data.columnName === 'GenerateTo'
        ) {
          return false;
        }
        // Chez les scouts une fenetre avec processed true est readonly
        const fullCtx = this.getCurrentContext(dataStored, false);
        if (!this.isAccordion && fullCtx.Processed && fullCtx.Processed === 'Y') {
          return true;
        }
        if (this.data.IsReadOnly !== undefined && this.data.IsReadOnly !== null && this.data.IsReadOnly === true) {
          return this.data.IsReadOnly;
        }

        if (!this.canUpdate(dataStored)) {
          return true;
        }
        if (this.data.ReadOnlyLogic) {
          const hasContextVariable = this.hasContextLogicVariable(LogicContextProperty.READONLY);
          let hasContextChanged = true;
          if (hasContextVariable && changedColumns) {
            hasContextChanged = this.verifyContextLogic(Object.keys(changedColumns), LogicContextProperty.READONLY);
          }
          if (hasContextChanged || this.getCurrentLogicContextValue(LogicContextProperty.READONLY) === undefined) {
            if (this.data.ReadOnlyLogic && this.data.ReadOnlyLogic.trim().toLowerCase().startsWith('@sql=')) {
              this.uiCreatorService
                .getDBSelect(
                  LogicEvaluator.replaceVariables(
                    this.data.ReadOnlyLogic.slice(5),
                    this.connectorService.getIupicsUserContext(),
                    this.getCurrentContext(dataStored, false)
                  ),
                  [],
                  []
                )
                .subscribe((data) => {
                  if (data.length > 0) {
                    this.isReadOnly = true;
                  } else {
                    this.isReadOnly = false;
                  }
                });
            } else if (
              this.data.ReadOnlyLogic &&
              LogicEvaluator.evaluateLogic(this.getCurrentContext(dataStored, false), this.data.ReadOnlyLogic)
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            this.isReadOnly = this.getCurrentLogicContextValue(LogicContextProperty.READONLY);
            return this.isReadOnly;
          }
        }
        if (this.data.columnName === 'IsActive') {
          return false;
        }

        if (
          dataStored.data.IsActive !== undefined &&
          dataStored.data.IsActive !== null &&
          dataStored.data.IsActive === 'N' &&
          !this.isAccordion
        ) {
          return true;
        }
      } else if (
        this.data.ReadOnlyLogic &&
        LogicEvaluator.evaluateLogic(this.getCurrentContext(dataStored, false), this.data.ReadOnlyLogic)
      ) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  canUpdate(dataStore: DataStore) {
    const dataStored = dataStore ? dataStore : this.dataStored;
    let retValue = true;
    const userLevel = this.connectorService.getIupicsUserContext()['#User_Level'] as string;
    let clientID = -1;
    let orgID = -1;
    if (dataStored.data && dataStored.data['AD_Client_ID'] !== undefined && dataStored.data['AD_Client_ID'] !== null) {
      // if (dataStored.data['AD_Client_ID'] === null) {
      //   return false;
      // } else
      if (dataStored.data['AD_Client_ID'].id !== undefined && dataStored.data['AD_Client_ID'].id !== null) {
        clientID = isNaN(parseInt(dataStored.data['AD_Client_ID'].id, 10))
          ? -1
          : parseInt(dataStored.data['AD_Client_ID'].id, 10);
      } else {
        clientID = isNaN(parseInt(dataStored.data['AD_Client_ID'], 10)) ? -1 : parseInt(dataStored.data['AD_Client_ID'], 10);
      }
    }
    if (dataStored.data && dataStored.data['AD_Org_ID'] !== undefined && dataStored.data['AD_Org_ID'] !== null) {
      if (dataStored.data['AD_Org_ID'].id !== undefined && dataStored.data['AD_Org_ID'].id !== null) {
        orgID = isNaN(parseInt(dataStored.data['AD_Org_ID'].id, 10)) ? -1 : parseInt(dataStored.data['AD_Org_ID'].id, 10);
      } else {
        orgID = isNaN(parseInt(dataStored.data['AD_Org_ID'], 10)) ? -1 : parseInt(dataStored.data['AD_Org_ID'], 10);
      }
    }
    if (clientID === -1 || orgID === -1) {
      return true;
    }
    if (clientID === 0 && orgID === 0 && userLevel.indexOf('S') === -1) {
      retValue = false;
    } else if (clientID !== 0 && orgID === 0 && userLevel.indexOf('C') === -1) {
      retValue = false;
    } else if (clientID !== 0 && orgID !== 0 && userLevel.indexOf('O') === -1) {
      retValue = false;
    }
    return retValue;
  }

  readOnlyNumberComponent() {}
  _parseFloat(value: string) {}

  setFieldMandatory() {
    if ((this.fieldValue || this.fieldValue === 0) && this.fieldValue !== '') {
      this.mandatoryCss = ' iu-field input-field ui-inputtext';
    } else {
      if (this.data && this.data.mandatoryLogic) {
        this.data.isMandatory = LogicEvaluator.evaluateLogic(
          this.getCurrentContext(this.dataStored, false),
          this.data.mandatoryLogic
        );
      }
      if (this.data && this.data.isMandatory) {
        this.mandatoryCss = ' iu-field-mandatory input-field ui-inputtext';
      } else {
        this.mandatoryCss = ' iu-field input-field ui-inputtext';
      }
    }
    if (
      this.DOMParentComponent !== undefined &&
      (this.DOMParentComponent.DOMParentComponent !== undefined &&
        (<any>this.DOMParentComponent.DOMParentComponent).isAccordion) === true
    ) {
      (<any>this.DOMParentComponent.DOMParentComponent).setMandatory();
    }
  }

  getfieldValue() {
    return this.fieldValue;
  }

  dataChange(value: any) {
    if (this.isStandalone) {
      this.fieldValueModified.emit(value);
      this.setFieldMandatory();
    } else if (this.data) {
      this.updateStore(value);
      this.fieldValueModified.emit(this.dataStored);
      const calloutStack: string[] = [];
      if (this.data.urlCallouts && value !== undefined && value !== null) {
        let newValue = value;
        if (value.id !== undefined && value.id !== null) {
          newValue = value.id;
        }
        if (this.isDateField) {
          if (value) {
            const timeVal = moment(value);
            if (this.data.needTime === false) {
              timeVal.set('hour', 0);
              timeVal.set('minute', 0);
              timeVal.set('second', 0);
              timeVal.set('millisecond', 0);
            }
            newValue = timeVal.format('YYYY-MM-DDTHH:mm:ss.SSS');
            newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
          }
        }
        // alimenter le pending callout au changement d'un champ
        this.store.calloutData(
          this.data.urlCallouts,
          {
            columnName: this.data.columnName,
            newValue: newValue,
            windowCtx: cloneDeep(this.getCurrentContext()),
            calloutStack: calloutStack
          },
          this.dataStored,
          this
        );
      }
    }
  }

  calloutChange(value: any) {
    this.dataChange(value);
  }
  /**
   * Modifie la visibilité du champ
   * @param dataStored
   */
  updateDisplay(dataStored: DataStore, changedColumns?: any) {
    const datastore = dataStored ? dataStored : this.dataStored;
    this.isFieldDisplay(datastore, changedColumns).subscribe((displayed) => {
      if (displayed) {
        this.renderer.removeClass(this.elementRef.nativeElement, 'dataHidden');
        this.displayCss = 'inline';
      } else {
        this.renderer.addClass(this.elementRef.nativeElement, 'dataHidden');
        this.displayCss = 'none';
      }
      // trigger for the accordion
      if (
        this.DOMParentComponent !== undefined &&
        (this.DOMParentComponent.DOMParentComponent !== undefined &&
          (<any>this.DOMParentComponent.DOMParentComponent).isAccordion) === true
      ) {
        (<any>this.DOMParentComponent.DOMParentComponent).updateDisplay(datastore);
      }
    });
  }
  /**
   * Modifie la readonly du champ
   * @param dataStored
   * @param changedColumns les informations des champs modifiés
   */
  updateReadOnly(dataStored: DataStore, changedColumns?: any) {
    const datastore = dataStored ? dataStored : this.dataStored;
    // Check if field is Read Only
    if (this.isFieldReadOnly(datastore, changedColumns)) {
      this.isReadOnly = true;
      if (this.data && this.data.numberType) {
        this.readOnlyNumberComponent();
      }
    } else {
      this.isReadOnly = false;
    }

    // trigger for the accordion
    if (
      this.DOMParentComponent !== undefined &&
      (this.DOMParentComponent.DOMParentComponent !== undefined &&
        (<any>this.DOMParentComponent.DOMParentComponent).isAccordion) === true
    ) {
      (<any>this.DOMParentComponent.DOMParentComponent).updateReadOnly(datastore);
    }
  }
  /**
   * check si le champ doit être affiché ou readonly
   * @param dataStored
   */
  checkReadAndDisplay(dataStored: DataStore) {
    this.updateDisplay(dataStored);
    this.updateReadOnly(dataStored);
  }
  setNewData(dataStored: DataStore) {
    const currentRecordId = this.dataStored && this.dataStored.key ? this.dataStored.key.recordId : null;
    const newRecordId = dataStored && dataStored.key ? dataStored.key.recordId : null;
    const recordChanged = currentRecordId !== newRecordId;
    let changeDone = false;
    if (this.dataStored) {
      this.dataChanged$.unsubscribe();
      this.dataConflicts.unsubscribe();
    }
    this.dataStored = dataStored;
    if (this.dataStored) {
      dataStored.addSubscriber(this);
      this.dataChanged$ = dataStored.dataChange.subscribe(
        (dataChange: { dataModified: any; bypassValidation: boolean; calloutStack: any }) => {
          const dataChanged = dataChange.dataModified;
          // * changement du symbole de la currency
          if (
            this.isMoneyField &&
            dataChange.dataModified.hasOwnProperty('C_Currency_ID') &&
            dataChange.dataModified.C_Currency_ID
          ) {
            if (this.cache.currency.hasOwnProperty(dataChange.dataModified.C_Currency_ID.id)) {
              this.currency = this.cache.currency[dataChange.dataModified.C_Currency_ID.id]['CurSymbol'];
            } else {
              Global.getCurrencySymbol(
                dataChange.dataModified.C_Currency_ID.id,
                this.store,
                this.connectorService.getIupicsDefaultLanguage().iso_code
              ).subscribe((res: any) => {
                if (res) {
                  this.currency = res['CurSymbol'];
                  this.cache.currency[dataChange.dataModified.C_Currency_ID.id] = res;
                }
              });
            }
          }

          const calloutStack: string[] = [];
          const bypassValidation = dataChange.bypassValidation !== undefined ? dataChange.bypassValidation : false;
          if (dataChanged[this.data.columnName] !== undefined) {
            this.changeFieldValue(dataStored, true, calloutStack);
            changeDone = true;
            this.hasConflict = false;
          }
          if (this.logicContextField && this.logicContextField.validation && !bypassValidation) {
            if (this.verifyContextLogic(Object.keys(dataChanged), LogicContextProperty.VALIDATION)) {
              const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
              const id = this.dataStored.data[this.data.columnName]
                ? this.dataStored.data[this.data.columnName].id
                  ? isNaN(parseInt(this.dataStored.data[this.data.columnName].id, 10))
                    ? null
                    : this.dataStored.data[this.data.columnName].id
                  : isNaN(parseInt(this.dataStored.data[this.data.columnName], 10))
                  ? null
                  : this.dataStored.data[this.data.columnName]
                : null;
              if (id !== null) {
                this.subscriptions.push(
                  this.store
                    .getAutocompleteDataById(
                      this.fieldType,
                      entityId,
                      id,
                      LogicEvaluator.replaceVariables(
                        this.data.validationCode,
                        this.connectorService.getIupicsUserContext(),
                        this.getCurrentContext()
                      )
                    )
                    .subscribe((dataWs) => {
                      if (dataWs.length <= 0) {
                        const dataForUpdate = {};
                        dataForUpdate[this.data.columnName] = null;
                        this.store.syncDataChanges(this.dataStored, dataForUpdate, true);
                      }
                    })
                );
              }
            }
          }
          this.updateUI(dataChanged);
        }
      );
      this.dataConflicts = dataStored.dataConflict.subscribe((dataConflicted) => {
        this.hasConflict = dataConflicted.dataConflict[this.data.columnName] !== undefined;
        this.conflictedData = dataConflicted.dataConflict[this.data.columnName];
      });
      this.initContextFieldSub = dataStored.initContextField.subscribe(() => {
        this.initContextField();
      });

      if (
        recordChanged &&
        this.dataStored.status === DataStoreStatus.NEWRECORD &&
        !this.dataStored.isCopied &&
        !this.isSetDefaultValue
      ) {
        const sub = this.dv$
          .pipe(
            switchMap((defaultValue) => {
              this.defaultValue = defaultValue;
              if (this.isAutocompleteField) {
                if (this.defaultValue && typeof this.defaultValue !== 'object') {
                  let findMatch = false;
                  if (this.data.items || (this.data.urlList && !changeDone)) {
                    const id =
                      this.defaultValue === undefined || this.defaultValue === null || isNaN(parseInt(this.defaultValue, 10))
                        ? this.defaultValue
                        : parseInt(this.defaultValue, 10);
                    if (this.data.items) {
                      this.data.items.forEach((el) => {
                        if (el.id === id || parseInt(el.id, 10) === id) {
                          this.defaultValue = el;
                          findMatch = true;
                        }
                      });
                    }
                    if (!findMatch) {
                      this.defaultValue = null;
                      if (!findMatch && this.data.urlList && (!this.data.items || this.data.items.length === 25)) {
                        const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
                        return this.store
                          .getAutocompleteDataById(
                            this.fieldType,
                            entityId,
                            id,
                            LogicEvaluator.replaceVariables(
                              this.data.validationCode,
                              this.connectorService.getIupicsUserContext(),
                              this.getCurrentContext()
                            )
                          )
                          .pipe(
                            map((dataWs) => {
                              // TODO dans MLocatorLookup Record_ID non pris en compte car le MLookupInfo n'a pas l'info du RecordId au moment ou il initialise le m_result
                              if (dataWs.length > 0 && dataWs.find((data) => data.id === id || parseInt(data.id, 10) === id)) {
                                const recordFound = dataWs.find((data) => data.id === id || parseInt(data.id, 10) === id);
                                this.defaultValue = recordFound;
                                findMatch = true;
                              } else {
                                this.defaultValue = null;
                                findMatch = true;
                              }
                              return this.defaultValue;
                            })
                          );
                      }
                    }
                  }
                }
              }
              return of(this.defaultValue);
            })
          )
          .subscribe((defaultValue) => {
            if (defaultValue !== undefined && defaultValue !== null) {
              this.defaultValue = defaultValue;
              if (this.isDateField) {
                let newDate;
                const regexp = new RegExp('[0-9]{4}[-][0-9]{2}[-][0-9]{2}');
                if (regexp.test(this.defaultValue)) {
                  newDate = new Date(this.defaultValue);
                } else {
                  newDate = new Date();
                  newDate.setTime(parseInt(this.defaultValue, 0));
                }
                this.defaultValue = newDate;
              } else if (this.data && this.data.numberType) {
                //TODO pq les nombres sont sous formes x,xx et pas x.xx
                //Conversion string to number...
                const defaultValueNumber =
                  defaultValue && defaultValue.replace ? parseFloat(defaultValue.replace(',', '.')) : defaultValue;
                if (isNaN(defaultValueNumber) || defaultValueNumber === null) {
                  this.defaultValue = null;
                } else {
                  this.defaultValue = defaultValueNumber;
                }
              }
            } else {
              if (this.isSwitchField) {
                this.defaultValue = 'N';
              }
              if (this.data.numberType && this.data.IsDisplayed) {
                this.defaultValue = 0;
              }
            }

            if (this.defaultValue !== undefined && this.defaultValue !== null) {
              // on ne set les valeurs par défaut ssi il n'y a pas de valeur via url dans le process
              if (!(this.parentProcess && this.parentProcess.paramsMap.get(this.data.columnName))) {
                // this.updateStore(this.defaultValue);
                if (dataStored) {
                  if (this.isDateField) {
                    if (this.defaultValue) {
                      this.defaultValue = moment(this.defaultValue).format('YYYY-MM-DDTHH:mm:ss.SSS');
                      this.defaultValue =
                        this.defaultValue.substring(0, 26) + this.defaultValue.substring(27, this.defaultValue.length);
                    }
                  }
                  dataStored.data[this.data.columnName] = this.defaultValue;
                }
              }
            }

            this.isSetDefaultValue = true;
            if (this.data.urlCallouts && this.defaultValue !== undefined && this.defaultValue !== null) {
              this.calloutDefaultValue();
            }
            // on set les valeurs qui sont spécifiées dans l'url
            if (this.parentProcess && this.parentProcess.paramsMap.get(this.data.columnName)) {
              // ? code dupliqué #1
              if (this.isDateField) {
                const dateValue = new Date();
                dateValue.setTime(this.parentProcess.paramsMap.get(this.data.columnName));
                this.updateStore(dateValue);
              } else {
                this.updateStore(this.parentProcess.paramsMap.get(this.data.columnName));
              }
              if (dataStored) {
                if (this.isDateField) {
                  const dateValue = new Date();
                  dateValue.setTime(this.parentProcess.paramsMap.get(this.data.columnName));
                  this.defaultValue = moment(dateValue).format('YYYY-MM-DDTHH:mm:ss.SSS');
                  this.defaultValue =
                    this.defaultValue.substring(0, 26) + this.defaultValue.substring(27, this.defaultValue.length);
                  dataStored.data[this.data.columnName] = this.defaultValue;
                }
              }
            } else {
              this.changeFieldValue(dataStored, false, [], true);
            }

            changeDone = true;
            this.afterDVInit$.next();
            sub.unsubscribe();
          });
        this.parseDefaultValue();
      } else {
        // on set les valeurs qui sont spécifiées dans l'url
        if (this.parentProcess && this.parentProcess.paramsMap.get(this.data.columnName)) {
          // ? code dupliqué #1
          this.updateStore(this.parentProcess.paramsMap.get(this.data.columnName));
        }
        this.changeFieldValue(dataStored);
        changeDone = true;
      }
    } else {
      if (this.data && this.data.numberType) {
        this.fieldValue = 0;
      }
      this.fieldValue = null;
    }
    this.updateUI();
  }

  // On parse la defaultValue et on la renvoie de manière asynchrone
  private parseDefaultValue() {
    // const dv$ = new Subject<any>();
    if (this.data.defaultValue) {
      // si defaultValue on la traite
      if (this.data.defaultValue.trim().toLowerCase().startsWith('@sql=')) {
        this.uiCreatorService
          .getDBSelect(
            LogicEvaluator.replaceVariables(
              this.data.defaultValue.slice(5),
              this.connectorService.getIupicsUserContext(),
              this.getCurrentContext()
            ),
            [],
            []
          )
          .subscribe((data) => {
            if (data.length > 0) {
              this.dv$.next(data[0][Object.keys(data[0])[0]]);
              // this.updateStore(data[0][Object.keys(data[0])[0]]);
            }
          });
      } else {
        const dvs = this.data.defaultValue.split(';');
        let ok = false;
        let dv: any;
        for (let i = 0; i < dvs.length && !ok; i++) {
          let dvLogic = dvs[i];
          if (!this.isDateField && this.data.defaultValue.indexOf('@#Date@') >= 0) {
            moment.locale(this.locale);
            dvLogic = dvLogic.replace(/@#Date@/g, moment(this.connectorService.getIupicsUserContext()['#Date']).format('L'));
          }
          dv = LogicEvaluator.replaceVariables(dvLogic, this.connectorService.getIupicsUserContext(), this.getCurrentContext());
          if (dv !== undefined && dv !== null) {
            ok = true;
          }
        }
        this.dv$.next(dv);
      }
    } else if (this.container && this.container.windowType !== IupicsMenuType.FORM) {
      // si on trouve des valeurs dans le contexte, on considere qu'on a des defaultValue
      const findMatch = this.checkContext();
      if (findMatch !== undefined && findMatch !== null) {
        this.dv$.next(findMatch);
      } else {
        this.dv$.next(undefined);
      }
    } else if (
      this.container &&
      //this.container.windowType === IupicsMenuType.FORM &&
      this.container.activeTab &&
      this.container.activeTab.ctx
    ) {
      // si on trouve des valeurs dans le contexte passé dans la notif
      const findMatch = this.container.activeTab.ctx[this.data.columnName];
      if (findMatch !== undefined && findMatch !== null) {
        this.dv$.next(findMatch);
      } else {
        this.dv$.next(undefined);
      }
    }
    // return dv$;
  }

  // méthode qui déclenche les callout des default value
  private calloutDefaultValue() {
    let newValue = this.defaultValue;
    if (this.defaultValue.id !== undefined && this.defaultValue.id !== null) {
      newValue = this.defaultValue.id;
    }
    if (this.isDateField) {
      if (this.defaultValue) {
        newValue = moment(this.defaultValue).format('YYYY-MM-DDTHH:mm:ss.SSS');
        newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
      }
    }
    this.store.calloutData(
      this.data.urlCallouts,
      {
        columnName: this.data.columnName,
        newValue: newValue,
        windowCtx: cloneDeep(this.getCurrentContext())
      },
      this.dataStored,
      this
    );
  }
  checkContext(): any {
    let findMatch: any;

    const columnName = this.data.columnName;
    const windowId = this.dataStored ? this.dataStored.key.windowId : -1;
    const context = Object.assign(this.connectorService.getIupicsUserContext());
    const keys = Object.keys(context);
    if (keys.find((k) => k === 'P' + windowId + '|' + columnName)) {
      findMatch = context[keys.find((k) => k === 'P' + windowId + '|' + columnName)];
    } else if (keys.find((k) => k === 'P|' + columnName)) {
      findMatch = context[keys.find((k) => k === 'P|' + columnName)];
    } else if (keys.find((k) => k === '$' + columnName)) {
      findMatch = context[keys.find((k) => k === '$' + columnName)];
    } else if (keys.find((k) => k === '#' + columnName)) {
      findMatch = context[keys.find((k) => k === '#' + columnName)];
    }
    return findMatch;
  }

  /**
   * C'est la méthode de merge par champs
   * @param event MouseEvent
   * @param chosenData Les données choisies lors du conflit
   */
  chooseData(event: MouseEvent, chosenData: any, isLocalChoose: boolean) {
    event.preventDefault();
    event.stopPropagation();
    this.hasConflict = false;
    const tempData = {};
    tempData[this.data.columnName] = chosenData;
    this.store.syncDataChanges(this.dataStored, tempData);
    if (isLocalChoose) {
      this.store.copyRemoteWindowDataToOldStore(this.dataStored.key, this.data.columnName);
    } else {
      this.store.copyWindowDataToOldStore(this.dataStored);
    }
    delete this.editViewParent.conflictsResult.dataConflict[this.data.columnName];
  }

  changeFieldValue(
    dataStored: DataStore,
    fromOtherChange: boolean = false,
    calloutStack: string[] = [],
    fromDefault: boolean = false
  ) {
    let shouldCallWs = true;
    // Change field value
    if (this.isDateField) {
      const tmpValue = dataStored.data[this.data.columnName];
      if (tmpValue) {
        if (tmpValue instanceof Date) {
          this.fieldValue = tmpValue;
        } else {
          this.fieldValue = moment(tmpValue).toDate();
        }
      } else {
        this.fieldValue = null;
      }
    } else {
      if (
        this.isAutocompleteField &&
        dataStored.data[this.data.columnName] !== null &&
        dataStored.data[this.data.columnName] !== undefined &&
        typeof dataStored.data[this.data.columnName] === 'object' &&
        (this.fieldValue === dataStored.data[this.data.columnName].id ||
          (this.fieldValue && this.fieldValue.id === dataStored.data[this.data.columnName].id))
      ) {
        shouldCallWs = false;
      }
      this.fieldValue = dataStored.data[this.data.columnName];
      if (this.isAutocompleteField && (this.fieldValue === null || this.fieldValue === undefined)) {
        // car la value doit être null pour afficher un champ vide
        this.fieldValue = null;
        shouldCallWs = false;
      }
    }
    if (this.isSwitchField) {
      if (!this.fieldValue || this.fieldValue === null) {
        this.fieldValue = 'N';
      }
    }
    if (this.data && this.data.numberType && typeof this.fieldValue !== 'number') {
      //TODO pq les nombres sont sous formes x,xx et pas x.xx
      //Conversion string to number...
      const defaultValueNumber =
        this.fieldValue && this.fieldValue.replace ? parseFloat(this.fieldValue.replace(',', '.')) : this.fieldValue;
      this.fieldValue = this._parseFloat(defaultValueNumber);
    }
    if (this.isAutocompleteField) {
      if (typeof this.fieldValue !== 'object') {
        if (this.changeFieldValueSub && this.changeFieldValueSub.unsubscribe) {
          this.changeFieldValueSub.unsubscribe();
        }
        let id;
        id = isNaN(parseInt(this.fieldValue, 10)) ? this.fieldValue : parseInt(this.fieldValue, 10);
        if (
          this.data.items &&
          this.data.items.length > 0 &&
          this.data.items.find((item) => item.id === id || parseInt(item.id, 10) === id) !== undefined
        ) {
          const foundItem = this.data.items.find((item) => item.id === id || parseInt(item.id, 10) === id);
          this.updateStore(foundItem, calloutStack);
        } else {
          if (shouldCallWs) {
            const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;

            const validation = LogicEvaluator.replaceVariables(
              this.data.validationCode,
              this.connectorService.getIupicsUserContext(),
              this.getCurrentContext()
            );
            this.changeFieldValueSub = this.store
              .getAutocompleteDataById(
                this.fieldType,
                entityId,
                id,
                fromOtherChange ? validation : undefined // Change for redmine #112652
              )
              .subscribe((dataWs) => {
                if (dataWs.length > 0 && dataWs.find((data) => data.id === id || parseInt(data.id, 10) === id)) {
                  const recordFound = dataWs.find((data) => data.id === id || parseInt(data.id, 10) === id);
                  this.updateStore(recordFound, calloutStack);
                } else {
                  this.updateStore(null, calloutStack);
                }
              });
          }
        }
      } else if (fromDefault) {
        this.updateStore(this.fieldValue, calloutStack);
      }
    }
    if ((this.isAddressField || this.isAutocompleteField) && this.isZoom) {
      this.refreshZoomInfo();
    }
    if (this.isMoneyField) {
      const currency_id =
        dataStored.data['C_Currency_ID'] !== undefined && dataStored.data['C_Currency_ID'] !== null
          ? dataStored.data['C_Currency_ID'] instanceof Object
            ? dataStored.data['C_Currency_ID'].id
            : dataStored.data['C_Currency_ID']
          : this.connectorService.getIupicsUserContext()['$C_Currency_ID'];
      if (currency_id) {
        if (this.cache.currency.hasOwnProperty(currency_id)) {
          this.currency = this.cache.currency[currency_id]['CurSymbol'];
        } else {
          Global.getCurrencySymbol(currency_id, this.store, this.connectorService.getIupicsDefaultLanguage().iso_code).subscribe(
            (res) => {
              if (res) {
                this.currency = res['CurSymbol'];
                this.cache.currency[currency_id] = res;
              }
            }
          );
        }
      }

      // this.isMoneyField = false;
    }

    if (this.isImageField && this.fieldValue) {
      this.displayImage = true;
    }
    // Modifie l'url si le parent est un process
    if (this.parentProcess) {
      this.notifyUrlChange();
    }
    this.updateCurrentContext();
    if (this.isStandalone) {
      this.updateUI();
    }
  }

  resetAutocompleteFromZoom(id: any) {
    const idFormated = isNaN(parseInt(id, 10)) ? id : parseInt(id, 10);
    const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
    if (idFormated === null) {
      this.dataChange(null);
    } else {
      this.store
        .getAutocompleteDataById(
          this.fieldType,
          entityId,
          idFormated,
          LogicEvaluator.replaceVariables(
            this.data.validationCode,
            this.connectorService.getIupicsUserContext(),
            this.getCurrentContext()
          )
        )
        .subscribe((dataWs) => {
          if (dataWs.length > 0 && dataWs.find((data) => data.id === idFormated || parseInt(data.id, 10) === idFormated)) {
            const recordFound = dataWs.find((data) => data.id === idFormated || parseInt(data.id, 10) === idFormated);
            this.dataChange(recordFound);
          }
        });
    }
  }

  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
  refreshZoomInfo() {}

  updateStore(value: any, calloutStack: string[] = []) {
    const dataModified = {};
    let newValue = value;
    if (this.isDateField) {
      if (value) {
        newValue = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS');
        newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
      }
    }
    if (this.data.columnName === 'AD_Org_ID' && value !== undefined && value !== null) {
      const id = value.id ? value.id : parseInt(value);
      if (this.connectorService.getIupicsUserContext()['#AD_Org_ID'] !== id) {
        this.connectorService.getIupicsUserContext()['#AD_Org_ID'] = id;
        this.connectorService.getIupicsUserContext()['#AD_Org_Name'] = '' + value.displayValue;
        this.connectorService.updateRemoteCtx();
      }
    }
    dataModified[this.data.columnName] = newValue;
    if (this.dataStored) {
      this.store.syncDataChanges(this.dataStored, dataModified, true, false, calloutStack);
      // UpdateStepper
      if (this.editViewParent && (this.data.columnName === 'DocStatus' || this.data.columnName === 'DocAction')) {
        if (this.dataStored.data['DocStatus'] && this.dataStored.data['DocAction']) {
          this.editViewParent.changingStepper.next(this.dataStored);
        }
      }
    }
  }
  /**
   * récupération du contexte complet du composant (fonctionne avec un système de cache, si on veut rebuild,il faut utiliser forceRefresh)
   * @param dataStore nouveau datastore à prendre en compte
   * @param overrideChild indiqué si on override les valeurs de l'enfant par celui du parent (true)
   * @param forceRefresh indique si on veut forcer la reconstruction du contexte (false)
   */
  getCurrentContext(dataStore?: DataStore, overrideChild = true, forceRefresh = false) {
    let editViewParent: any;
    if (this.parentTab && this.parentTab.editViewParent) {
      editViewParent = this.parentTab.editViewParent;
    } else if (this.editViewParent) {
      editViewParent = this.editViewParent;
    }
    /*ajout du tabId */
    let dataStored = dataStore ? dataStore : this.dataStored;
    if (overrideChild === false && !forceRefresh && dataStored && dataStored.currentContext) {
      return dataStored.currentContext;
    }
    if (dataStored && dataStored.data) {
      const newStore = {
        ...dataStored,
        data: {
          ...dataStored.data,
          Parent_Tab_ID: this.tabId ? this.tabId : this.parentTab ? this.parentTab.tabId : null,
          //#region custo
          Parent_Window_ID: dataStored ? (dataStored.key ? dataStored.key.windowId : null) : null
          //#endregion custo
        }
      } as DataStore;
      Object.setPrototypeOf(newStore, Object.getPrototypeOf(dataStored));
      dataStored = newStore;
    }

    // Suppression apiz_dataResult inutile dans le contexte
    if (dataStored && dataStored.data && dataStored.data.hasOwnProperty('apiz_dataResult')) {
      delete dataStored.data.apiz_dataResult;
    }
    if (dataStored && dataStored.data && dataStored.data.hasOwnProperty('apiz_ctxChanged')) {
      delete dataStored.data['apiz_ctxChanged'];
    }

    // dans le cas d'un parent de type EditView
    if (editViewParent || !this.container || !this.container.getCurrentContext) {
      if (this.dataStored) {
        this.dataStored.currentContext = EditViewUtils.getCurrentContext(
          editViewParent,
          dataStored,
          this.connectorService.getIupicsUserContext(),
          overrideChild
        );
        return this.dataStored.currentContext;
      }
    }
    // dans le cas d'un parent de type Process ou Form
    if (this.container && this.container.getCurrentContext && !this.isInUniversalFilter) {
      if (this.dataStored) {
        this.dataStored.currentContext = EditViewUtils.getCurrentContext(
          editViewParent,
          dataStored,
          this.connectorService.getIupicsUserContext(),
          overrideChild
        );
        return this.dataStored.currentContext;
      }
    }
    // dans le cas d'un parent de type Process ou Form
    //#region Custo
    if (this.container && this.container.getCurrentContext() && !this.isInUniversalFilter) {
      if (this.dataStored) {
        this.dataStored.currentContext = this.container.getCurrentContext();
        return this.dataStored.currentContext;
      } else {
        return this.container.getCurrentContext();
      }
    }
    //#endregionCusto
  }

  ngAfterViewInit(): void {
    this.afterViewInitEmitter.emit();
  }
  updateCurrentContext() {
    if (this.dataStored && this.dataStored.currentContext) {
      this.dataStored.currentContext[this.data.columnName] = this.dataStored.data[this.data.columnName]
        ? this.dataStored.data[this.data.columnName].id
          ? this.dataStored.data[this.data.columnName].id
          : this.dataStored.data[this.data.columnName]
        : this.dataStored.data[this.data.columnName];
    }
  }
  /**
   * Initialise le contexte et les variables dynamiques pour chaque logique (readonly,display,mandatory,validation)
   */
  initContextField() {
    this.updateLogicContext(LogicContextProperty.VALIDATION);
    this.updateLogicContext(LogicContextProperty.READONLY);
    this.updateLogicContext(LogicContextProperty.MANDATORY);
    this.updateLogicContext(LogicContextProperty.DISPLAY);
  }
  /**
   * Permet de mettre à jour la readonly,display et la mandatory du datacontainer
   * @param dataChanged objet contenant les noms de colonnes modifiées et leur valeur associée
   */
  updateUI(dataChanged: any = undefined) {
    this.updateDisplay(this.dataStored, dataChanged);
    this.setFieldMandatory();
    this.updateReadOnly(this.dataStored, dataChanged);
  }
  /**
   * vérifie pour une logique choisie si cell-ci contient des variables dynamiques(@@)
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   */
  hasContextLogicVariable(property: LogicContextProperty) {
    return this.logicContextField[property] ? (Object.keys(this.logicContextField[property]).length > 0 ? true : false) : false;
  }
  /**
   * Vérifie si il y'a eu des changements sur les variables contenues dans la logique
   * @param columns noms des colonnes modifiés
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   */
  verifyContextLogic(columns: string[], property: LogicContextProperty) {
    let changed = false;
    if (this.logicContextField[property]) {
      columns.forEach((column) => {
        // check changement des variables de la validation
        if (
          (!property !== this.logicContextField.validation || this.dataStored.data[this.data.columnName]) &&
          this.logicContextField[property].hasOwnProperty(column)
        ) {
          if (this.logicContextField[property][column] instanceof Object && this.dataStored.data[column] instanceof Object) {
            changed = this.logicContextField[property][column].id != this.dataStored.data[column].id;
          } else {
            changed =
              this.logicContextField[property][column] !=
              (this.dataStored.data[column] ? this.dataStored.data[column].id : this.dataStored.data[column]);
          }
          if (changed) {
            return;
          }
        }
      });
      if (changed) {
        this.updateLogicContext(property);
      }
    }
    return changed;
  }
  /**
   * Initialise le contexte et les variables dynamiques de la logique choisie si elle existe
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   */
  updateLogicContext(property: LogicContextProperty) {
    if (this.data) {
      let logic = null;
      switch (property) {
        case LogicContextProperty.DISPLAY:
          logic = this.data.DisplayLogic;
          break;
        case LogicContextProperty.READONLY:
          logic = this.data.ReadOnlyLogic;
          break;
        case LogicContextProperty.MANDATORY:
          logic = this.data.mandatoryLogic;
          break;
        case LogicContextProperty.VALIDATION:
          logic = this.data.validationCode;
          break;
        default:
          break;
      }
      if (logic) {
        this.logicContextField[property] = LogicEvaluator.getContextField(
          logic,
          this.dataStored ? this.connectorService.getIupicsUserContext() : null,
          this.dataStored ? this.getCurrentContext() : null
        );
      }
    }
  }
  /**
   * Mets à jour le logic context avec la dernière valeur calculée ou reçue.
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   * @param value any
   */
  updateCurrentLogicContextValue(property: LogicContextProperty, value: any) {
    if (this.logicContextField[property]) {
      this.logicContextField[property][this.data.columnName + 'Value'] = value;
    }
  }
  /**
   * Récupère la dernière valeur calculée ou reçue dans le logic context.
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   */
  getCurrentLogicContextValue(property: LogicContextProperty) {
    if (this.logicContextField[property] && this.logicContextField[property][this.data.columnName + 'Value'] !== undefined) {
      return this.logicContextField[property][this.data.columnName + 'Value'];
    } else {
      return undefined;
    }
  }
  //#region custo
  readonlyCustoCampEte(dataStored: DataStore) {
    const fullCtx = this.getCurrentContext(dataStored, false);
    if (!this.isAccordion && dataStored && dataStored.key && dataStored.key.tabId == this.camp_ete_first_tab_id) {
      const exceptionsTxt = Global.ad_message_text.get('SCOUTS_CAMP_ETE_RO_EXCEP_COLUMNS');
      const exceptions = exceptionsTxt ? exceptionsTxt.split(',') : [];
      const roleExceptionsTxt = Global.ad_message_text.get('SCOUTS_CAMP_ETE_RO_EXCEP_COLUMNS_ROLE');
      const roleExceptions = roleExceptionsTxt ? roleExceptionsTxt.split(',') : [];
      const tabExceptionsTxt = Global.ad_message_text.get('SCOUTS_HAS_NO_SUMMER_CAMP_ETE_RO_EXCEPTION_TABS');
      const tabExceptions = tabExceptionsTxt ? tabExceptionsTxt.split(',') : [];
      if (
        fullCtx['IsValidate'] != null &&
        fullCtx['IsValidate'] === 'Y' &&
        !exceptions.includes(this.data.columnName) &&
        !roleExceptions.includes(fullCtx['#AD_Role_ID'] + '')
      ) {
        return true;
      } else if (
        fullCtx['IsSectionHasNoCampThisSummer'] != null &&
        fullCtx['IsSectionHasNoCampThisSummer'] === 'Y' &&
        !tabExceptions.includes(fullCtx['Parent_Tab_ID']) && //23737
        this.data.columnName != null &&
        this.data.columnName !== 'IsSectionHasNoCampThisSummer' &&
        this.data.columnName !== 'SaveAndQuit' &&
        this.data.columnName !== 'Quit'
      ) {
        return true;
      }
    }
    return false;
  }
  //#endregion custo
}

export enum LogicContextProperty {
  MANDATORY = 'mandatory',
  VALIDATION = 'validation',
  READONLY = 'readonly',
  DISPLAY = 'display'
}
export class LogicContext {
  mandatory: any;
  readonly: any;
  display: any;
  validation: any;
}
export class AbstractDataContainerCallout {
  dataContainers: AbstractDataContainer[];
  constructor(dataContainers: AbstractDataContainer[]) {
    this.dataContainers = dataContainers;
  }

  getField(columnName: string): AbstractDataContainer {
    for (let i = 0; i < this.dataContainers.length; i++) {
      if (this.dataContainers[i].data.columnName === columnName) {
        return this.dataContainers[i];
      }
    }
    return null;
  }
}
