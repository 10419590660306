import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import { CompiereProcessService } from "@compiere-ws/services/compiere-process/compiere-process.service";
import { ProcessInProgressService } from "@compiere-ws/services/process-in-progress/process-in-progress.service";
import { SocketService } from "@compiere-ws/services/socket/socket.service";
import { AutocompleteUiComponent } from "@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component";
import { ButtonUiComponent } from "@iupics-components/standard/fields/button-ui/button-ui.component";
import { GridViewUiComponent } from "@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component";
import { DataStoreService } from "@iupics-manager/managers/data-store/data-store.service";
import { MessageManagerService } from "@iupics-manager/managers/message/message-manager.service";
import { SecurityManagerService } from "@iupics-manager/managers/security-manager/security-manager.service";
import { UICreatorService } from "@iupics-manager/managers/ui-creator/ui-creator.service";
import { WindowFactoryService } from "@iupics-manager/managers/ui-creator/window-factory/window-factory.service";
import { TranslateService } from "@ngx-translate/core";
import { ProcessUiComponent } from "../process-ui/process-ui.component";
import { SpecificWindowUiComponent } from "../specific-window-ui/specific-window-ui.component";

@Component({
  selector: "iu-event-search",
  templateUrl: "./event-search.component.html",
  styleUrls: ["./event-search.component.scss"]
})
export class EventSearchComponent extends SpecificWindowUiComponent
  implements OnInit {
  SumSelectedRows = 0;
  processUiComponent: ProcessUiComponent;
  ButtonUiComponent: ButtonUiComponent;
  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      pp,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Implementation de la sélection d'une cellule
   * @param gridView grid sur laquelle s'est fait la sélection
   */
  notifySelect(gridView: GridViewUiComponent) {
    // const focusedCell = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getFocusedCell();
    // const agGridApi = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid
    //   .api;
    // let selectedRowByCell = null;

    // if (focusedCell) {
    //   agGridApi.deselectAll();
    //   selectedRowByCell = agGridApi.getDisplayedRowAtIndex(
    //     focusedCell.rowIndex
    //   );
    //   selectedRowByCell.setSelected(true);
    // }

    // if (
    //   selectedRowByCell &&
    //   selectedRowByCell.data &&
    //   selectedRowByCell.data.C_Project_ID
    // ) {
    //   this.selectEvent(selectedRowByCell.data.C_Project_ID);
    //   this.closeModalEmitter.emit();
    // }
  }

  selectEvent(p_C_Project_ID: number) {
    this.dataStore.data.selections[0].selection = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows();
    const columnName = this.sourceComponent.data.columnName;

    if (columnName && p_C_Project_ID) {
      const event = { data: { Data_UUID: columnName + "," + p_C_Project_ID } };
      (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(
        event
      );
    }
  }
}
