import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { InputLocationUiComponent } from '@iupics-components/standard/fields/input-location-ui/input-location-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
import { UnitManagementComponent } from '../unit-management/unit-management.component';

@Component({
  selector: 'iu-create-partner',
  templateUrl: './create-partner.component.html',
  styleUrls: ['./create-partner.component.scss']
})
export class CreatePartnerComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;

  listMandatoryField = [];
  displayDoublonPanel = false;
  selectDisabled = true;
  listDoublon = [];
  processId;
  isCadreUnite;
  CBPselected;
  resultModal;
  labelSelect = 'createBPartner.btnSelect';
  labelCreate = 'createBPartner.btnCreate';
  labelClose = 'createBPartner.btnCloseCU';
  additionalParams = {};
  contacts: Contact[];
  isMandatoryContactDetails = false;

  constructor(
    public elementRef: ElementRef,
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    private messageManager: MessageManagerService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    pp: ProcessInProgressService
  ) {
    super(windowFactory, resolver, uiCreator, store, processService, socketService, connectorService, pp, translateService);
  }

  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((it) => {
        if (it.data.isMandatory) {
          this.listMandatoryField.push(it.data.columnName);
        }
      });
      this.customDesignArray.push(
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'AD_Org_ID',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'Name',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'FirstName',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'Z_Fonction_ID',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'M_Warehouse_ID',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'Sexe',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'Date_Naiss',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'Tel_tiers',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'GSM_tiers',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'Email_tiers',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'M_PriceList_ID',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'C_Location_ID',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrTop',
          type: CustomDesignItemType.FIELD,
          columnName: 'Z_Numero_Registre_National',
          cssClass: 'ui-g-12'
        },
        {
          vcr: 'vcrBot',
          type: CustomDesignItemType.FIELD,
          columnName: 'valider',
          cssClass: 'ui-g-12'
        }
      );
    });
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'ui-g-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
    this.isCadreUnite = this.connectorService.getIupicsUserAccount().current_role.role_id === 1000026;
    if (this.sourceComponentData && this.sourceComponentData['columnName'] === 'unitManagement') {
      this.dataStore.data['readOnlyFonction'] = 'Y';
      this.dataStore.data['Z_Fonction_ID'] = 1000625;
    }
    if (this.sourceComponentData && this.sourceComponentData['columnName'] === 'copy') {
      const parentData = (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data;
      this.additionalParams['CBPcopy'] = parentData['C_BPartner_ID'];
      this.dataStore.data['AD_Org_ID'] = parentData['AD_Org_ID'] ? parentData['AD_Org_ID'].id : null;
      // this.dataStore.data['C_Location_ID'] = parentData['C_Location_ID'];
      this.dataStore.data['Email_tiers'] = parentData['Email_tiers'];
      this.dataStore.data['GSM_tiers'] = parentData['GSM_tiers'];
      this.dataStore.data['M_PriceList_ID'] = parentData['M_PriceList_ID'] ? parentData['M_PriceList_ID'].id : null;
      this.dataStore.data['Name'] = parentData['Name'];
      this.dataStore.data['Sexe'] = parentData['Sexe'];
      this.dataStore.data['Tel_tiers'] = parentData['Tel_tiers'];
      if (this.isCadreUnite) {
        const locationRequest: DataStoreRequest = {
          windowId: null,
          parent_constraint: '',
          compiereRequest: {
            startRow: 0,
            endRow: 0,
            tableName: 'C_BPartner_Location',
            filterModel: {
              C_BPARTNER_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [parentData['C_BPartner_ID']],
                operators: [OperatorFilterType.EQUALS]
              },
              ISACTIVE: {
                filterType: CompiereDataGridFilterType.SET,
                values: ['Y'],
                operators: [OperatorFilterType.EQUALS]
              },
              ADRESSE_TYPE: {
                filterType: CompiereDataGridFilterType.SET,
                values: ['DO'],
                operators: [OperatorFilterType.EQUALS]
              }
            }
          }
        };
        this.store.getDataGrid(locationRequest).subscribe((res) => {
          if (res && res.data && res.data.length > 0 && res.data[0]['C_LOCATION_ID']) {
            this.dataStore.data['C_Location_ID'] = {
              id: res.data[0]['C_LOCATION_ID'],
              displayValue: res.data[0]['NAME']
            };
            // this.dataStore.data['C_Location_ID'] = res.data[0]['C_LOCATION_ID'];
            const myLocationContainer = this.dataContainers.find((el) => {
              return el.data.columnName === 'C_Location_ID';
            });
            if (myLocationContainer !== null && myLocationContainer !== undefined) {
              (<InputLocationUiComponent>myLocationContainer).changeFieldValue(this.dataStore);
            }
          }
        });
        const contactRequest: DataStoreRequest = {
          windowId: null,
          parent_constraint: '',
          compiereRequest: {
            startRow: 0,
            endRow: 0,
            tableName: 'Z_CONTACT_USER',
            filterModel: {
              C_BPARTNER_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [parentData['C_BPartner_ID']],
                operators: [OperatorFilterType.EQUALS]
              }
            }
          }
        };

        this.store.getDataGrid(contactRequest).subscribe((cont) => {
          if (cont && cont.data && cont.data.length > 0) {
            this.contacts = [];
            cont.data.forEach((contact) => {
              const newCont = {
                Name: contact['NAME'],
                id: contact['AD_USER_ID'],
                isSelected: 'Y'
              };
              this.contacts.push(newCont);
            });
          }
        });
      }
      // Champs qui ne doivent pas être pré-rempli : prénom - date de naissance - section - fonction
      // this.dataStore.data['Z_Fonction_ID'] = parentData['Z_Fonction_ID'] ? parentData['Z_Fonction_ID'].id : null;
      // this.dataStore.data['FirstName'] = parentData['Name2'];
      // this.dataStore.data['Date_Naiss'] = parentData['Date_Naiss'];
      // this.dataStore.data['M_Warehouse_ID'] = parentData['M_Warehouse_ID'] ? parentData['M_Warehouse_ID'].id : null;
    }
    if (this.isCadreUnite) {
      this.isCadreUnite = true;
      this.labelSelect = 'createBPartner.btnSelectCU';
      this.labelCreate = 'createBPartner.btnCreateCU';
    }
    this.mandatoryFields = [];
    this.dataContainers.forEach((datacontainer) => {
      datacontainer.dataChange(this.dataStore.data[datacontainer.data.columnName]);
    });
  }

  notifyFromDataChange(item: any) {
    if (this.getFormStoreData('Tel_tiers') === null) {
      this.setFormStoreData('Tel_tiers', '+32');
    }
    if (this.getFormStoreData('GSM_tiers') === null) {
      this.setFormStoreData('GSM_tiers', '+32');
    }
    if (item.data.columnName === 'Z_Fonction_ID' && this.dataStore.data['Z_Fonction_ID']) {
      const functionRequest: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: 'Z_Fonction',
          filterModel: {
            Z_Fonction_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [this.dataStore.data['Z_Fonction_ID'].id],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };

      this.store.getDataGrid(functionRequest).subscribe((result) => {
        if (result && result.data && result.data.length > 0) {
          if (result.data[0]['IS_MANDATORY_CONTACT_DETAILS'] === 'Y') {
            this.isMandatoryContactDetails = true;
            this.updateMandatoryFields(true);
          } else {
            this.isMandatoryContactDetails = false;
            this.updateMandatoryFields(false);
          }
        }
      });
    } else if (!this.dataStore.data['Z_Fonction_ID']) {
      this.isMandatoryContactDetails = false;
      this.updateMandatoryFields(false);
    }
  }

  processvalider(item) {
    this.processId = item.processId;
    if (
      this.dataStore.data['Z_Fonction_ID'] &&
      this.isMandatoryContactDetails === true &&
      (!this.dataStore.data['AD_Org_ID'] ||
        !this.dataStore.data['Sexe'] ||
        this.dataStore.data['FirstName'] === null ||
        String(this.dataStore.data['FirstName']).trim() === '' ||
        this.dataStore.data['Name'] === null ||
        String(this.dataStore.data['Name']).trim() === '' ||
        !this.dataStore.data['M_PriceList_ID'] ||
        !this.dataStore.data['Date_Naiss'] ||
        !this.dataStore.data['Tel_tiers'] ||
        !this.dataStore.data['Email_tiers'] ||
        !this.dataStore.data['C_Location_ID'])
    ) {
      this.messageManager.newMessage(new IupicsMessage('Error', this.translateService.instant('createBPartner.error3')));
      return;
    } else if (
      this.dataStore.data['Z_Fonction_ID'] &&
      this.isMandatoryContactDetails === false &&
      (!this.dataStore.data['AD_Org_ID'] ||
        !this.dataStore.data['Sexe'] ||
        this.dataStore.data['FirstName'] === null ||
        String(this.dataStore.data['FirstName']).trim() === '' ||
        this.dataStore.data['Name'] === null ||
        String(this.dataStore.data['Name']).trim() === '' ||
        !this.dataStore.data['M_PriceList_ID'] ||
        !this.dataStore.data['Date_Naiss'])
    ) {
      this.messageManager.newMessage(new IupicsMessage('Error', this.translateService.instant('createBPartner.error1')));
      return;
    } else if (
      !this.dataStore.data['AD_Org_ID'] ||
      !this.dataStore.data['Sexe'] ||
      this.dataStore.data['FirstName'] === null ||
      String(this.dataStore.data['FirstName']).trim() === '' ||
      this.dataStore.data['Name'] === null ||
      String(this.dataStore.data['Name']).trim() === '' ||
      !this.dataStore.data['M_PriceList_ID'] ||
      !this.dataStore.data['Date_Naiss'] ||
      (this.dataStore.data['readOnlyFonction'] !== 'Y' &&
        (!this.dataStore.data['M_Warehouse_ID'] || !this.dataStore.data['Z_Fonction_ID']))
    ) {
      // ne pas exécuter le processus;
      this.messageManager.newMessage(new IupicsMessage('Error', this.translateService.instant('createBPartner.error1')));
      return;
    }

    if (this.dataStore.data['Date_Naiss'] !== null) {
      const today = new Date();
      const myDate = moment(this.dataStore.data['Date_Naiss']).subtract(99, 'years').toDate();
      const date_naiss = moment(this.dataStore.data['Date_Naiss']).toDate();
      if (date_naiss < myDate || date_naiss > today) {
        this.messageManager.newMessage(new IupicsMessage(this.translateService.instant('createBPartner.error2'), 'error'));
        return;
      }
    }

    if (this.dataStore.data['Z_Numero_Registre_National']) {
      const numRegistreNational = this.dataStore.data['Z_Numero_Registre_National'];
      if (numRegistreNational.trim().length === 11) {
        for (let i = 0; i < numRegistreNational.trim().length; i++) {
          if (!Number(numRegistreNational.charAt(i)) && numRegistreNational.charAt(i) !== '0') {
            this.messageManager.newMessage(
              new IupicsMessage(this.translateService.instant('createBPartner.invalidNationalRegistryNumber'), 'error')
            );
            return;
          }
        }
        if (Number(numRegistreNational)) {
          const yy = numRegistreNational.substring(0, 2);
          const currentYear = new Date().getFullYear().toString().substr(-2);
          const c = yy < currentYear ? 2 : 0;
          const bb = numRegistreNational.substring(9, 11);
          let value = parseInt(numRegistreNational.substring(0, 9), 10);
          if (c === 2) {
            value = 2000000000 + value;
          }
          const numberOfChecks = 97 - (value % 97);
          if (numberOfChecks !== parseInt(bb, 10)) {
            this.messageManager.newMessage(
              new IupicsMessage(this.translateService.instant('createBPartner.invalidNationalRegistryNumber'), 'error')
            );
            return;
          }
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(this.translateService.instant('createBPartner.invalidNationalRegistryNumber'), 'error')
          );
          return;
        }
      } else {
        this.messageManager.newMessage(
          new IupicsMessage(this.translateService.instant('createBPartner.invalidNationalRegistryNumber'), 'error')
        );
        return;
      }
    }

    if (this.isMandatoryContactDetails !== true) {
      let message = '';
      if (!this.dataStore.data['Date_Naiss']) {
        message += this.translateService.instant('createBPartner.warning1');
      }
      if (!this.dataStore.data['C_Location_ID']) {
        message += this.translateService.instant('createBPartner.warning2');
      }
      if (!this.dataStore.data['Z_Fonction_ID']) {
        message += this.translateService.instant('createBPartner.warning3');
      } else {
        message += this.translateService.instant('createBPartner.confirm');
      }
      Global.infoDialog.message = {
        summary: 'Confirmation',
        detail: message
      };
      Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
      Global.infoDialog.showInfoDialog();
      const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
        // FAIS TON TRAITEMENT ICI
        confirm.unsubscribe();
        cancel.unsubscribe();

        this.checkBeforeExecuteProcess();
      });
      const cancel = Global.infoDialog.cancel.subscribe((e) => {
        // ANNULATION
        confirm.unsubscribe();
        cancel.unsubscribe();
      });
    } else {
      this.checkBeforeExecuteProcess();
    }
  }
  onClickCancel() {
    this.parentComponent.updateModalDisplay({
      key: 'displayFormUI',
      value: false
    });
  }

  checkBeforeExecuteProcess() {
    let date_naiss = null;
    if (this.dataStore.data['Date_Naiss']) {
      date_naiss = "to_date('" + moment(this.dataStore.data['Date_Naiss']).format('YYYY-MM-DD') + "','YYYY-MM-DD')";
      //  date_naiss = moment(this.dataStore.data['Date_Naiss']).format('YYYY-MM-DD');
    }
    const tablename =
      //127102
      //"table(y_get_doubloninsameorg('" +
      "table(y_get_doubloninsameorg2('" +
      //127102
      this.dataStore.data['Name'].replace('\'', '\'\'')+ //Redmine #139264
      "','" +
      this.dataStore.data['FirstName'].replace('\'', '\'\'') + //Redmine #139264
      "'," +
      date_naiss +
      ",'" +
      this.dataStore.data['Sexe'].id +
      "'," +
      this.dataStore.data['AD_Org_ID'].id +
      ',35))';

    const checkDoublon: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName: tablename
      }
    };

    this.store.getDataGrid(checkDoublon).subscribe((res) => {
      if (res && res.data && res.data.length > 0) {
        this.listDoublon = [];
        res.data.forEach((dbl) => {
          const formattedValue = moment(dbl['DATE_NAISS']).format('L').slice(0, 10);
          this.listDoublon.push({
            name: dbl['NAME'],
            name2: dbl['NAME2'],
            ddn: formattedValue,
            id: dbl['C_BPARTNER_ID'],
            isSelected: false
          });
        });
        this.displayDoublonPanel = true;
      } else {
        // this.parentComponent.updateModalDisplay({
        //   key: 'displayFormUI',
        //   value: false
        // });
        if (this.isCadreUnite && this.contacts) {
          this.additionalParams['contacts'] = this.contacts
            .filter((cont) => cont.isSelected === 'Y')
            .map((c) => {
              return {
                id: c.id
              };
            });
        }
        this.executeProcess(this.processId, this.additionalParams);
        if (this.parentComponent instanceof UnitManagementComponent) {
          this.parentComponent.notifyFromRowSelected(this.dataStore.data);
        }
      }
    });
  }

  notifyFromLogs(log) {
    if (log.startsWith('Membre:')) {
      const partnerID = log.substring(8);
      if (partnerID && partnerID.length > 0 && !isNaN(partnerID)) {
        if (this.parentComponent.tabId === 1000303) {
          this.parentComponent.container.DOMChildrenComponent[0].forceOpenEditView('C_BPartner_ID,' + partnerID);
          this.displayDoublonPanel = false;
          this.parentComponent.updateModalDisplay({
            key: 'displayFormUI',
            value: false
          });
        } else {
          Global.workspace.openTargetSearch({
            cat: { id: 1000111 },
            source: { id: 'C_BPartner_ID,' + partnerID }
          });
        }
      }
      this.parentComponent.updateModalDisplay({
        key: 'displayFormUI',
        value: false
      });
    }
  }

  onEndProcess(ping: ProcessPingInfo = null) {
    // this.refreshGrids(this.dataStore, false);
    this.parentComponent.updateModalDisplay({
      key: 'displayFormUI',
      value: false
    });
  }

  openSelected() {
    if (this.isCadreUnite) {
      this.additionalParams['resultModal'] = 1;
      this.additionalParams['CBPselected'] = this.CBPselected;
      this.displayDoublonPanel = false;
      // this.parentComponent.updateModalDisplay({
      //   key: 'displayFormUI',
      //   value: false
      // });
      this.executeProcess(this.processId, this.additionalParams);
    } else {
      if (this.parentComponent.tabId === 1000303) {
        this.parentComponent.container.DOMChildrenComponent[0].forceOpenEditView('C_BPartner_ID,' + this.CBPselected);
        this.displayDoublonPanel = false;
        this.parentComponent.updateModalDisplay({
          key: 'displayFormUI',
          value: false
        });
      } else {
        Global.workspace.openTargetSearch({
          cat: { id: 1000111 },
          source: { id: 'C_BPartner_ID,' + this.CBPselected }
        });
      }
    }
  }

  closeFonction() {
    if (this.isCadreUnite) {
      this.additionalParams['resultModal'] = 2;
      this.additionalParams['CBPselected'] = this.CBPselected;
      this.displayDoublonPanel = false;
      this.parentComponent.updateModalDisplay({
        key: 'displayFormUI',
        value: false
      });
      this.executeProcess(this.processId, this.additionalParams);
    }
  }

  createNew() {
    this.additionalParams['resultModal'] = 3;
    this.displayDoublonPanel = false;
    this.parentComponent.updateModalDisplay({
      key: 'displayFormUI',
      value: false
    });
    this.executeProcess(this.processId, this.additionalParams);
  }

  changeSelected(double) {
    this.listDoublon.forEach((db) => {
      db.isSelected = false;
    });
    this.selectDisabled = false;
    double.isSelected = true;
    this.CBPselected = double.id;
  }

  changeContactSelected(value, index) {
    this.contacts[index].isSelected = value;
  }
  checkBeforeProcessing() {
    if (this.getFormStoreData('Tel_tiers') === '+32') {
      this.setFormStoreData('Tel_tiers', null);
    }
    const missingFieldLabels = this.getMissingMantoryField();
    if (missingFieldLabels.length > 0) {
      this.checkAndShowMandatory();
      if (this.getFormStoreData('Tel_tiers') === null) {
        this.setFormStoreData('Tel_tiers', '+32');
      }
      return false;
    } else {
      if (this.getFormStoreData('GSM_tiers') === '+32') {
        this.setFormStoreData('GSM_tiers', null);
      }
      return true;
    }
  }
  updateMandatoryFields(isMandatory: boolean) {
    this.dataContainers.forEach((datacontainer) => {
      if (
        datacontainer &&
        datacontainer.data &&
        (datacontainer.data.columnName === 'Tel_tiers' ||
          datacontainer.data.columnName === 'Email_tiers' ||
          datacontainer.data.columnName === 'C_Location_ID')
      ) {
        datacontainer.data.isMandatory = isMandatory;
        datacontainer.updateStore(this.dataStore.data[datacontainer.data.columnName]);
      }
    });
  }
}

interface Contact {
  id: number;
  Name: String;
  isSelected: String;
}
