<div class="iu-specific-window iu-specific-window-container">
    <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%' , height: '620px' }">
    <div class="ui-g-12">
      <ng-template #top></ng-template>
    </div>
  </p-scrollPanel>
</div>