<p-toast position="center" [key]="key" [modal]="false">
  <ng-template let-message pTemplate="message">
    <div class="infoDialogContent">
      <i *ngIf="dialogType === 'confirm' || dialogType === 'confirmyesno'" class="fa fa-exclamation-triangle fa-3x"></i>
      <i *ngIf="dialogType === 'info'" class="fa fa-info-circle fa-3x"></i>
      <i *ngIf="dialogType === 'error'" class="fa fa-times-circle fa-3x"></i>
      <h3>{{ message.summary }}</h3>
      <p [innerHTML]="message.detail"></p>
    </div>
    <div class="ui-g ui-fluid">
      <div *ngIf="dialogType === 'confirm'" class="ui-g-6">
        <button
          type="button"
          pButton
          (click)="cancelDialog()"
          label="{{ 'infodialog.cancel' | translate }}"
          class="ui-button-secondary"
        ></button>
      </div>
      <div *ngIf="dialogType === 'confirm'" class="ui-g-6">
        <button
          #confirm
          type="button"
          pButton
          (click)="confirmDialog()"
          label="{{ 'infodialog.ok' | translate }}"
          class="ui-button primary"
        ></button>
      </div>
      <div *ngIf="dialogType === 'confirmyesno'" class="ui-g-6">
        <button
          type="button"
          pButton
          (click)="cancelDialog()"
          label="{{ 'infodialog.no' | translate }}"
          class="ui-button-secondary"
        ></button>
      </div>
      <div *ngIf="dialogType === 'confirmyesno'" class="ui-g-6">
        <button
          #confirmYesNo
          type="button"
          pButton
          (click)="confirmDialog()"
          label="{{ 'infodialog.yes' | translate }}"
          class="ui-button primary"
        ></button>
      </div>
      <div *ngIf="dialogType !== 'confirm' && dialogType !== 'confirmyesno'" class="ui-g-12">
        <button
          #confirmOK
          type="button"
          pButton
          (click)="confirmDialog()"
          label="{{ 'infodialog.ok' | translate }}"
          class="ui-button primary"
        ></button>
      </div>
    </div>
  </ng-template>
</p-toast>
<!--
  <iu-prime-dialog-ui #dialogConfirmation styleClass="iu-info-dialog" [classDialog]="true" [(visible)]="display"
  [draggable]="false" [modal]="true" [responsive]="true" [closable]=false [resizable]="true" [minWidth]="540" [width]="540"
  [minHeight]="150" [positionTop]="140">
-->
<!--
  <p-header>
    {{ title }}
  </p-header>

  <div class="ui-g" style=" width: 100%; height: 96.3%; vertical-align: middle;">
    <div class="ui-row">
      <div class="ui-g-1">
        <i *ngIf="dialogType==='error'" style="color :red;" class="fa fa-times-circle fa-3x" aria-hidden="true"></i>
        <i *ngIf="dialogType==='info'" style="color :blue;" class="fa fa-info-circle fa-3x" aria-hidden="true"></i>
        <i *ngIf="dialogType==='confirm'" style="color :green;" class="fa fa-question-circle fa-3x" aria-hidden="true"></i>
      </div>
      <div class="ui-g-11" style="margin-top: 10px;">
        <span style="font-size: 1.3em; word-break: break-word;; overflow: hidden;
      text-overflow: ellipsis;">{{message}}</span>
      </div>
    </div>
    <div class="ui-row" style="width: 100%; border-top: 1px solid #D5D5D5; text-align:right; padding-top: 10px; font-size: 1.5em;">
      <div *ngIf="dialogType!=='confirm' && dialogType!=='confirmyesno'" style="display:inline;">
        <button pButton label="{{ 'infodialog.ok' | translate }}" style="width: 95px; margin-right: 5px;" (click)="confirmDialog()"></button>
      </div>
      <div *ngIf="dialogType==='confirm'" style="display:inline;">
        <button pButton label="{{ 'infodialog.ok' | translate }}" style="width: 95px; margin-right: 5px;" (click)="confirmDialog()"></button>
      </div>
      <div *ngIf="dialogType==='confirm'" style="display:inline;">
        <button pButton label="{{ 'infodialog.cancel' | translate }}" style="width: 95px; margin-right: 5px;" (click)="cancelDialog()"></button>
      </div>
      <div *ngIf="dialogType==='confirmyesno'" style="display:inline;">
        <button pButton label="{{ 'infodialog.yes' | translate }}" style="width: 95px; margin-right: 5px;" (click)="confirmDialog()"></button>
      </div>
      <div *ngIf="dialogType==='confirmyesno'" style="display:inline;">
        <button pButton label="{{ 'infodialog.no' | translate }}" style="width: 95px; margin-right: 5px;" (click)="cancelDialog()"></button>
      </div>
    </div>
  </div>
-->
<!-- </iu-prime-dialog-ui> -->
